import animation from "@/assets/animation.json";
import PriceInput from "@/components/shared/PriceInput";
import { CardContent, CardDescription, CardTitle } from "@/components/ui/card";
import Lottie from "lottie-react";

import type { TurnoverDto } from "@repos/ecom-dtos";

export default function RenderContentTurnover() {
	return (
		<>
			<CardContent className="ml-3 mr-3 mt-6 flex flex-col items-start justify-center pt-0 text-center lg:py-[7%] lg:text-left">
				<CardTitle className="w-full text-lg font-bold leading-6  text-gray-800 md:text-xl lg:w-[75%] lg:text-2xl lg:leading-10">
					Indiquez votre chiffre d&apos;affaires annuel
				</CardTitle>
				<CardDescription className="mt-3 text-xs text-black md:text-sm lg:mt-2 lg:text-base ">
					Ces informations sont essentielles pour évaluer votre éligibilité et
					personnaliser l&apos;offre qui vous sera proposée.
				</CardDescription>
				<div className="mt-8 flex h-full w-full flex-1 flex-col justify-center lg:hidden">
					<Lottie animationData={animation} className="h-32 md:h-56" />
				</div>
				<div className="mt-10 flex w-full items-center justify-center xl:mt-20">
					<PriceInput<TurnoverDto>
						placeholder="10 000 €"
						label="Chiffre d'affaires (€)"
						name="turnover"
					/>
				</div>
			</CardContent>
		</>
	);
}

<p className="ml-3 w-[50%] text-2xl font-bold text-gray-800">
	Indiquez votre numéro SIREN
</p>;
