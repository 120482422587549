import { useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { formatNumber } from "@/utils/utilsDigital";
import type { Control} from "react-hook-form";
import { useFormContext, useWatch } from "react-hook-form";

import type { FullGuaranteeResponseDto } from "@repos/ecom-dtos";

import SelectInput from "../shared/SelectInput";

interface FormValues {
	basicPricingSelect: string;
}

interface MobileCardBasicGuaranteeServiceProps {
	displayValue: number[];
	handleValueOnSlide?: (value: number[]) => void;
	colorValueNoTselected: string;
	guarantee: FullGuaranteeResponseDto;
}

export const DEFAULT_BASIC_PRICING = "200000";

export default function MobileCardBasicGuaranteeService({
	displayValue,
	handleValueOnSlide,
	colorValueNoTselected,
	guarantee,
}: MobileCardBasicGuaranteeServiceProps) {
	const optionsValues = guarantee.ceilings.map((ceiling) => ({
		value: ceiling.ceilingValue.toString(),
		label: `${formatNumber(ceiling.ceilingValue)} €`,
	}));

	const { setValue, control } = useFormContext<FormValues>();

	useEffect(() => {
		const valueToSet = displayValue[0]?.toString() ?? DEFAULT_BASIC_PRICING;
		setValue("basicPricingSelect", valueToSet);
	}, [setValue, displayValue]);

	const selectedValue = useWatch({
		control: control as Control<FormValues>,
		name: "basicPricingSelect",
	});

	useEffect(() => {
		if (selectedValue && handleValueOnSlide) {
			handleValueOnSlide([parseInt(selectedValue, 10)]);
		}
	}, [selectedValue]);

	return (
		<Card
			className="relative m-2 w-full overflow-hidden border-none bg-white shadow-none lg:hidden"
			style={{
				backgroundColor: `hsl(var(--${colorValueNoTselected}))`,
			}}
		>
			<CardHeader className="flex flex-row items-center gap-2 border-b-[0.05rem] border-[#E7E7E7] px-9 py-4">
				<img src={guarantee.activeIconUrl} className="mr-2" />
				<CardTitle className="w-full text-base font-bold text-blackMobile">
					{guarantee.label}
				</CardTitle>
			</CardHeader>
			<CardContent className="relative flex w-full flex-col items-center gap-4 px-0">
				<div className="flex w-full items-center justify-between gap-4 px-4 pt-5">
					<p className="w-6/12 text-xs font-semibold text-blackMobile">
						Limite de garantie (LOI)
					</p>
					<div className="w-6/12">
						<SelectInput
							name="basicPricingSelect"
							placeholder=""
							label=""
							enableSelect={true}
							labelClassName="hidden"
							selectClassName="h-16 bg-white pl-6 text-blackMobile font-semibold border-[#E0E0E0] border py-3 h-13 shadow-none"
							options={optionsValues}
						/>
					</div>
				</div>
				<div className="flex w-full items-center justify-between gap-4 px-4">
					<p className="w-6/12 text-xs font-semibold text-blackMobile">
						Franchise
					</p>
					<div className="w-6/12">
						<div className="relative flex flex-[0.2] justify-center rounded-full border border-blue-500 bg-white px-3 py-3">
							<div className="text-sm font-bold text-black">
								{guarantee.franchiseValue} €
							</div>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	);
}
