import { cn } from "@/utils";
import { calculateMonthlyPriceIncludingTax } from "@/utils/calculateMonthlyPriceIncludingTax";
import { Info } from "lucide-react";

import type {
	CeillingOptionsType,
	FullGuaranteeResponseDto,
} from "@repos/ecom-dtos";

import { CheckBoxWithLabel } from "../shared/CheckBoxWithLabel";
import SelectInput from "../shared/SelectInput";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../ui/card";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

interface Option {
	value: string;
	label: string;
}

interface MobileOptionnalGuaranteeProps {
	guarantee: FullGuaranteeResponseDto;
	priceGuarantee: number;
	showAddButtons: Record<string, boolean | undefined>;
	colorValueNoTselected: string;
	colorValueSelected: string;
	basicGuarantees: string[] | undefined;
	handleCheckBoxClick: (index: number, price: number, optionId: string) => void;
	optionsCeilingCheckboxChecked: CeillingOptionsType[];
	handleCheckboxToggle: (isChecked: boolean) => void;
	selectInputName: string;
	isSelectDisabled: boolean;

	optionsValues: Option[];
	selectedValue: string;
}

const MobileOptionnalGuarantee = ({
	guarantee,
	priceGuarantee,
	showAddButtons,
	colorValueNoTselected,
	colorValueSelected,
	basicGuarantees,
	handleCheckBoxClick,
	optionsCeilingCheckboxChecked,
	handleCheckboxToggle,
	selectInputName,
	optionsValues,
	isSelectDisabled,
}: MobileOptionnalGuaranteeProps) => {
	const cardBorderColor = showAddButtons[guarantee.label]
		? "transparent"
		: `hsl(var(--${colorValueSelected}))`;
	const cardBorderWidth = showAddButtons[guarantee.label] ? "4px" : "4px";

	return (
		<>
			<Card
				className={cn(
					"relative m-2 mb-5 w-full border border-[#E0E0E0] bg-white px-0 shadow-none lg:hidden",
					cardBorderColor,
				)}
				style={{
					borderColor: cardBorderColor,
					borderWidth: cardBorderWidth,
					backgroundColor:
						guarantee.type === "CYBER"
							? "#FFFFFF"
							: `hsl(var(--${colorValueNoTselected}))`,
				}}
			>
				<CardHeader className="flex flex-row items-center justify-between border-b-[0.05rem] border-[#E7E7E7]">
					<div className="flex flex-row items-center">
						<img src={guarantee.inactiveIconUrl} className="mr-2" />
						<CardTitle className="w-full text-xs font-bold text-blackMobile">
							{guarantee.label}
						</CardTitle>
					</div>
					<div className="flex w-80 flex-row items-center justify-end">
						<div className="text-xs font-bold text-primary xl:text-md">
							+{priceGuarantee.toFixed(2)} €/mois
						</div>
						<div className="w-auto">
							<CheckBoxWithLabel
								onClickCheckBox={handleCheckboxToggle}
								isChecked={!showAddButtons[guarantee.label]}
								label=""
								checkedClass="text-primary !border-blue-500"
								inputRadioGroup="size-5 rounded-md bg-transparent border-[#A5A5A5] !w-auto"
								checkedBgClass={`${colorValueSelected}`}
							/>
						</div>
					</div>
				</CardHeader>

				<CardContent className="relative flex w-full flex-col items-center gap-4 px-0">
					<div className="flex w-full items-center justify-between gap-4 px-4 pt-5">
						<p className="w-6/12 text-xs font-semibold text-blackMobile">
							Limite de garantie (LOI)
						</p>
						<div className="w-6/12">
							<SelectInput
								name={selectInputName}
								placeholder=""
								label=""
								enableSelect={isSelectDisabled}
								labelClassName="hidden"
								selectClassName={`h-16 bg-white pl-3 text-blackMobile font-semibold py-3 h-13 shadow-none disabled:opacity-1 disabled:border-blue-500 ${
									guarantee.type === "CYBER"
										? "border border-blue-500"
										: "border border-[#E0E0E0]"
								}`}
								options={optionsValues}
							/>
						</div>
					</div>
					<div className="flex w-full items-center justify-between gap-4 px-4">
						<p className="w-6/12 text-xs font-semibold text-blackMobile">
							Franchise
						</p>
						<div className="w-6/12">
							<div className="relative flex flex-[0.2] justify-center rounded-full border border-blue-500 bg-white px-0 py-3">
								<div className="text-sm font-bold text-black">
									{guarantee.franchiseValue} €
								</div>
							</div>
						</div>
					</div>
				</CardContent>
				{guarantee.type === "CYBER" && (
					<p className="mb-6 px-4 text-sm font-semibold text-blue-500">
						Les sous-garanties du Cyber :
					</p>
				)}
				{guarantee.details.length > 0 && (
					<CardFooter className="mt-3 flex flex-col space-y-2.5 lg:hidden">
						<>
							{basicGuarantees &&
								basicGuarantees.map((option) => (
									<div
										className="flex w-full cursor-not-allowed flex-row items-center justify-between rounded-full border border-[#a5a5a540] p-3.5 pl-0"
										style={{
											backgroundColor: `hsl(var(--${colorValueNoTselected}))`,
										}}
										key={option}
									>
										<CheckBoxWithLabel
											key={option}
											label={option}
											inputRadioGroup="size-4 cursor-not-allowed hidden"
											onClickCheckBox={() => {}}
											isChecked={true}
											labelClassNames="cursor-not-allowed font-medium"
										/>
										<div className="mt-0 text-end text-sm font-medium text-blue-500">
											Inclus
										</div>
									</div>
								))}
							<div className="h-3" />
							{guarantee.ceilings.map((ceiling) =>
								ceiling.options?.map((option, index) => {
									const priceMonthly = calculateMonthlyPriceIncludingTax(
										option.priceBeforeTax,
										option.taxValue,
									);
									const optionsId = option.id;

									const isChecked = optionsCeilingCheckboxChecked.some(
										(opt) => opt.optionId === option.id,
									);
									const disabled = showAddButtons[guarantee.label];

									const isLastOption =
										index === (ceiling.options || []).length - 1;

									return (
										<div
											key={index}
											className="relative flex h-16 w-full flex-row items-center justify-between gap-5 rounded-full border border-[#a5a5a540] p-4"
											style={{
												backgroundColor: isChecked
													? `hsl(var(--${colorValueNoTselected}))`
													: "white",
											}}
										>
											<CheckBoxWithLabel
												label={option.label}
												inputRadioGroup="hidden"
												onClickCheckBox={() => {
													if (!disabled) {
														handleCheckBoxClick(index, priceMonthly, optionsId);
													}
												}}
												isChecked={isChecked}
												disabledOptions={disabled}
												id={option.id}
												labelClassNames="absolute left-2 size-full cursor-pointer top-0 bottom-0 flex items-center font-medium"
											/>
											{!isLastOption ? (
												<div className="mt-0 w-full text-end text-sm font-medium text-blue-500">
													{`${priceMonthly} €/mois`}
												</div>
											) : (
												<Popover>
													<PopoverTrigger>
														<Info className="relative z-50 size-4 w-auto cursor-pointer text-[#0F6DD4]" />
													</PopoverTrigger>
													<PopoverContent
														side="top"
														className="relative bottom-1 rounded-xl border-none bg-[#0F6DD4] px-6 text-sm font-bold text-white shadow-none"
													>
														{`${priceMonthly} €/mois`}
														<div className="absolute -bottom-1 left-1/2 size-3 -translate-x-1/2 rotate-45 transform border-t border-[#0F6DD4] bg-[#0F6DD4]" />
													</PopoverContent>
												</Popover>
											)}
										</div>
									);
								}),
							)}
						</>
					</CardFooter>
				)}
			</Card>
		</>
	);
};

export default MobileOptionnalGuarantee;
