import type { ClassValue } from "clsx";
import * as React from "react";
import { Card, CardFooter } from "@/components/ui/card";
import { cn } from "@/utils";

import { Button } from "../ui/button";

enum NavigationAction {
	Next = "Next",
	Back = "Back",
}

interface CardComponentProps {
	children: React.ReactNode;
	className?: ClassValue;
	onButtonClick?: (str: NavigationAction) => void;
	displayFooter?: boolean;
	hideBackButton?: boolean;
	hideSubmitButton?: boolean;
	handleBack?: () => void;
}

export default function CustomCard({
	onButtonClick,
	children,
	displayFooter,
	hideBackButton,
	hideSubmitButton,
	className,
	handleBack,
}: CardComponentProps) {
	return (
		<Card
			className={cn(
				"relative mt-2 flex h-full w-[88vw] max-w-[88vw]  flex-col gap-6 rounded-none border-0 bg-white shadow-none lg:w-[40vw] lg:max-w-[40vw] lg:rounded-xl lg:shadow-custom",
				className,
			)}
		>
			{children}
			{displayFooter ? (
				<CardFooter className="hidden w-[100%] items-center justify-between self-center lg:flex">
					{!hideBackButton ? (
						<Button type="button" onClick={handleBack} variant={"retour"}>
							Retour
						</Button>
					) : (
						<div></div>
					)}
					{!hideSubmitButton && (
						<Button
							type={onButtonClick ? "button" : "submit"}
							onClick={() =>
								onButtonClick && onButtonClick(NavigationAction.Next)
							}
							variant="suivant"
							className="rounded-full bg-blue-600 px-8 py-3 text-white hover:bg-blue-700"
						>
							Suivant
						</Button>
					)}
				</CardFooter>
			) : null}
		</Card>
	);
}
