import { z } from "zod";

export const AddressApiSchema = z.object({
	features: z.array(
		z.object({
			mainAddress: z.string(),
			postalCode: z.string(),
			cityCode: z.string(),
			longitude: z.number(),
			latitude: z.number(),
			city: z.string(),
			country: z.string(),
		}),
	),
});
export type AddressApiSchemaType = z.infer<typeof AddressApiSchema>;

export const CitiesApiSchema = z.object({
	features: z.array(
		z.object({
			city: z.string(),
		}),
	),
});
