import { Card, CardContent, CardHeader } from "@/components/ui/card";
import checkIcon from "@/pages/10-StatementPage/assets/check.svg";

import type { StatementsResponseDto } from "@repos/ecom-dtos";

interface StatementsNoticeProps {
	data: StatementsResponseDto;
}

const StatementsNotice = ({ data }: StatementsNoticeProps) => {
	const statements = data.statements;

	return (
		<Card className="my-3 w-full border-none bg-transparent px-0 shadow-none lg:bg-white lg:px-8 lg:shadow-sm">
			<CardHeader className="hidden flex-row items-center justify-start px-0 py-5 lg:flex lg:border-b-[0.06rem] lg:border-gray-300 lg:px-8">
				<img src={checkIcon} className="mr-3 size-7" alt="Check Icon" />
				<p className="text-lg font-semibold text-black">Je déclare</p>
			</CardHeader>
			<CardContent className="py-0 lg:py-6">
				<ul className="list-disc space-y-2 pl-0 lg:pl-16">
					{statements.map((item, index) => (
						<li key={`state-${index}`} className="text-sm">
							{item}
						</li>
					))}
				</ul>
			</CardContent>
		</Card>
	);
};

export default StatementsNotice;
