import { useState } from "react";

import { addYears, getMaxSelectableDate, getToday } from "../utils/dateUtlis";

interface UseDatePickerLogicProps {
	type?: "effective" | "birth";
	defaultDate?: Date;
}

function calculateInitialYear(type: "effective" | "birth", defaultDate?: Date) {
	const today = getToday();

	if (defaultDate) {
		return defaultDate.getFullYear();
	}

	if (type === "effective") {
		return getMaxSelectableDate(90).getFullYear();
	}

	return addYears(today, -18).getFullYear();
}

export function useDatePickerLogic({
	type = "birth",
	defaultDate,
}: UseDatePickerLogicProps) {
	const today = getToday();

	const initialYear = calculateInitialYear(type, defaultDate);

	const initialMonth = defaultDate ? defaultDate.getMonth() : today.getMonth();

	const [selectedYear, setSelectedYear] = useState(initialYear);

	const [selectedMonth, setSelectedMonth] = useState(initialMonth);

	const [isDisplayedPopoverContent, setIsDisplayedPopoverContent] =
		useState(false);

	const minSelectableDate = type === "effective" ? today : addYears(today, -18);

	const maxSelectableDate =
		type === "effective" ? getMaxSelectableDate(90) : today;

	const handleYearChange = (year: number) => {
		setSelectedYear(year);
	};

	const handleMonthChange = (month: number) => {
		setSelectedMonth(month);
	};

	return {
		selectedYear,
		selectedMonth,
		isDisplayedPopoverContent,
		setIsDisplayedPopoverContent,
		minSelectableDate,
		maxSelectableDate,
		handleYearChange,
		handleMonthChange,
	};
}
