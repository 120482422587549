import { ContactArrowIndicator } from "@/components/shared/ContactArrowIndicator";

import congrasPageIcon from "./assets/congrasPageIcon.svg";
import insuredSuccessIcon from "./assets/insuredSuccessIcon.svg";
import successMobileIcon from "./assets/successMobileIcon.svg";

export const SubscriptionSuccessfullyPage = () => {
	return (
		<>
			<div className="my-8 flex size-full flex-col-reverse items-center justify-center gap-8 px-10 text-center md:my-0 md:flex-row md:px-16 md:text-left">
				<div className="flex w-full flex-col justify-start md:w-6/12">
					<h1 className="mb-2 text-2xl font-bold text-blue-500 md:text-5xl md:text-[#FFDD85]">
						Félicitations !
					</h1>
					<p className="text-lg font-bold leading-7 text-black md:text-4xl md:leading-10">
						Vous avez complété votre <br />
						souscription avec succès
					</p>
					<div className="mt-6 flex justify-start gap-6 md:mt-8">
						<img
							src={insuredSuccessIcon}
							className="hidden max-w-60 md:block"
						/>
						<img
							src={successMobileIcon}
							className="mx-auto block max-w-60 md:hidden"
						/>
					</div>
				</div>
				<div className="hidden w-1/12 lg:flex" />
				<div className="hidden w-full text-right md:flex md:w-5/12">
					<img
						src={congrasPageIcon}
						className="mx-auto max-w-60 md:ml-auto md:mr-0"
					/>
				</div>
			</div>
			<ContactArrowIndicator />
		</>
	);
};
