import { create } from "zustand";
import { persist } from "zustand/middleware";

// Type of store
interface TurnoverState {
	turnover: number | null;
	setTurnover: (step: number | null) => void;
	reset: () => void;
}

// Create store
const useTurnoverStore = create<TurnoverState>()(
	persist(
		(set) => ({
			turnover: null,
			setTurnover: (turnover: number | null) => set({ turnover }),
			reset: () => set({ turnover: null }),
		}),
		{
			name: "turnover-storage",
			getStorage: () => localStorage,
		},
	),
);

export default useTurnoverStore;
