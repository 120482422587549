import type { SubmitHandler } from "react-hook-form";
import CustomCard from "@/components/CardComponent/CustomCard";
import { MobileFooter } from "@/components/MobileComponents/MobileFooter";
import { useToast } from "@/components/ui/use-toast";
import { useCustomNavigate } from "@/hooks/customNavigate";
import { useResetStores } from "@/hooks/useResetStore";
import { LeftHalfCustomSubscriptionContent } from "@/pages/2-CustomSubscriptionPage/components/LeftHalfCustomSubscriptionContent";
import useCompanyStore from "@/stores/useCompanyStore";
import useCustomSubscriptionStore, {
	SubscriberTypeEnum,
} from "@/stores/useCustomSubscriptionStore";
import useProspectStore from "@/stores/useProspectStore";
import useTurnoverStore from "@/stores/useTurnoverStore";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import type { ProspectDto } from "@repos/ecom-dtos";
import { prospectSchema } from "@repos/ecom-dtos";

import { NotFoundPage } from "../NotFoundPage";
import CustomSubscriptionContentCard from "./components/CustomSubscriptionContentCard";
import SuccessSentEmailContentCard from "./components/SuccessSentEmailContentCard";

export const CustomSubscriptionPage = () => {
	const navigate = useCustomNavigate();
	const { toast } = useToast();
	const resetStores = useResetStores();
	const { subscriberType } = useCustomSubscriptionStore((state) => ({
		subscriberType: state.subscriberType,
	}));
	const { prospect, setProspect } = useProspectStore((state) => ({
		prospect: state.prospect,
		setProspect: state.setProspect,
	}));
	const { company } = useCompanyStore((state) => ({
		company: state.company,
	}));
	const { turnover } = useTurnoverStore((state) => ({
		turnover: state.turnover,
	}));

	//TODO: the form must be defined in the parent component
	const methods = useForm<ProspectDto>({
		defaultValues: {
			email: prospect?.email || "",
			phoneNumber: prospect?.phoneNumber || "",
			activity: prospect?.activity || undefined,
		},
		resolver: zodResolver(prospectSchema),
		mode: "onChange",
	});

	//TODO: the 2 mutation functions below must be defined in the parent component
	const subscriptionMailingActivityMutation =
		trpc.mailing.sendCustomSubscriptionActivityEmails.useMutation({
			onError: () => {
				toast({
					description:
						"Une erreur interne est survenue, veuillez réessayer plus tard.",
					duration: 2000,
					variant: "destructive",
					style: {
						color: "white",
					},
				});
			},
		});

	const subscriptionMailingTurnoverMutation =
		trpc.mailing.sendCustomSubscriptionTurnoverEmails.useMutation({
			onError: () => {
				toast({
					description:
						"Une erreur interne est survenue, veuillez réessayer plus tard.",
					duration: 2000,
					variant: "destructive",
					style: {
						color: "white",
					},
				});
			},
		});
	//TODO: the 2 functions below must passed as props, and the functions must be defined in the parent component
	const handleSendEmailOfActivity = async ({
		activity,
		companyName,
		email,
		phoneNumber,
	}: {
		activity: string;
		companyName: string;
		email: string;
		phoneNumber: string;
	}) => {
		await subscriptionMailingActivityMutation.mutateAsync({
			activity: activity,
			companyName: companyName,
			email: email,
			phoneNumber: phoneNumber,
		});
	};

	const handleSendEmailOfTurnover = async ({
		turnover,
		phoneNumber,
		email,
	}: {
		turnover: number;
		phoneNumber: string;
		email: string;
	}) => {
		await subscriptionMailingTurnoverMutation.mutateAsync({
			turnover: turnover,
			email: email,
			phoneNumber: phoneNumber,
		});
	};

	const onSubmit: SubmitHandler<ProspectDto> = async (data) => {
		const updatedProspect: ProspectDto = {
			...prospect,
			email: data.email,
			phoneNumber: data.phoneNumber,
			activity: data.activity || undefined,
		};

		setProspect(updatedProspect);
		if (subscriberType === SubscriberTypeEnum.LowTurnover) {
			if (!company) {
				navigate("COMPANY_ACTIVITY");
			}

			await handleSendEmailOfActivity({
				activity: data.activity || "",
				companyName: company!.name,
				email: data.email,
				phoneNumber: data.phoneNumber,
			});
		} else if (subscriberType === SubscriberTypeEnum.HighTurnover) {
			if (!turnover) {
				navigate("TURNOVER");
			}

			await handleSendEmailOfTurnover({
				turnover: turnover!,
				phoneNumber: data.phoneNumber,
				email: data.email,
			});
		}
	};

	const handleFormSubmit = () => {
		methods.handleSubmit(onSubmit)();
	};

	const handleNavigateHome = () => {
		resetStores();
		navigate("TURNOVER");
	};

	const handleBack = () => {
		if (subscriberType === SubscriberTypeEnum.LowTurnover) {
			navigate("COMPANY_ACTIVITY");
		} else if (subscriberType === SubscriberTypeEnum.HighTurnover) {
			navigate("TURNOVER");
		}
	};

	const isFormSubmitted =
		subscriptionMailingTurnoverMutation.isSuccess ||
		subscriptionMailingActivityMutation.isSuccess;

	if (!subscriberType) {
		return <NotFoundPage />;
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="flex w-screen flex-1 flex-row items-center">
					<div className="hidden h-full w-full flex-1 flex-col lg:flex ">
						<LeftHalfCustomSubscriptionContent
							isFormSubmitted={isFormSubmitted}
						/>
					</div>
					<div className="mr-0 flex h-full w-full flex-1 items-center justify-center lg:mr-5">
						<CustomCard
							className={"h-auto max-h-[95vh]"}
							displayFooter={!isFormSubmitted}
							handleBack={handleBack}
						>
							{!isFormSubmitted ? (
								<CustomSubscriptionContentCard />
							) : (
								<SuccessSentEmailContentCard />
							)}
						</CustomCard>
					</div>
				</div>
				<MobileFooter
					label={!isFormSubmitted ? "Envoyer" : "Page d'accueil"}
					onButtonClick={
						!isFormSubmitted ? handleFormSubmit : handleNavigateHome
					}
				/>
			</form>
		</FormProvider>
	);
};
