import * as React from "react";

import ItemStepper from "./ItemStepper";
import ProgressStepper from "./ProgressStepper";

interface Step {
	value: number;
	classNameBg: string;
	classNameTxt: string;
	label: string;
}

interface CustomStepperProps {
	step: Step;
	currentStep: number;
	index: number;
}

export default function CustomStepper({
	step,
	index,
	currentStep,
}: CustomStepperProps) {
	return (
		<React.Fragment>
			<ItemStepper step={step} />
			<ProgressStepper index={index} currentStep={currentStep} />
		</React.Fragment>
	);
}
