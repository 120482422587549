import { GuaranteeCeilingType } from "@prisma/client";
import { z } from "zod";

export const BasicGuaranteeResponseSchema = z.object({
	type: z.nativeEnum(GuaranteeCeilingType),
	label: z.string(),
	details: z.array(z.string()),
	activeIconUrl: z.string(),
	inactiveIconUrl: z.string(),
	order: z.number(),
});

export const CeilingOptionSchema = z.object({
	id: z.string(),
	label: z.string(),
	priceBeforeTax: z.number(),
	taxValue: z.number(),
});
export type CeilingOptionDto = z.infer<typeof CeilingOptionSchema>;

export const CeilingSchema = z.object({
	id: z.string(),
	ceilingValue: z.number(),
	priceBeforeTax: z.number(),
	taxValue: z.number(),
	terrorismFunds: z.number(),
	label: z.string().optional(),
	options: z.array(CeilingOptionSchema).optional(),
	ceilingType: z.nativeEnum(GuaranteeCeilingType),
});

export const CeilingsSchema = z.array(CeilingSchema);

export const CeilingInputSchema = z.object({
	type: z.nativeEnum(GuaranteeCeilingType),
	ceilingValue: z.number(),
});

export type CeilingInputDto = z.infer<typeof CeilingInputSchema>;

export type CeilingDto = z.infer<typeof CeilingSchema>;

export const FullGuaranteeResponseSchema = z.object({
	type: z.nativeEnum(GuaranteeCeilingType),
	label: z.string(),
	details: z.array(z.string()),
	isOptional: z.boolean(),
	activeIconUrl: z.string(),
	inactiveIconUrl: z.string(),
	order: z.number(),
	franchiseValue: z.number(),
	ceilings: z.array(CeilingSchema),
});

export type FullGuaranteeResponseDto = z.infer<
	typeof FullGuaranteeResponseSchema
>;

export const FinalGuaranteeResponseSchema = z.object({
	type: z.nativeEnum(GuaranteeCeilingType),
	label: z.string(),
	details: z.array(z.string()),
	isOptional: z.boolean(),
	activeIconUrl: z.string(),
	inactiveIconUrl: z.string(),
	order: z.number(),
	franchiseValue: z.number(),
	chosenCeiling: CeilingSchema.optional(),
});

export type FinalGuaranteeResponseDto = z.infer<
	typeof FinalGuaranteeResponseSchema
>;

export type BasicGuarenteeResponseDto = z.infer<
	typeof BasicGuaranteeResponseSchema
>;

export type FullGuarenteeResponseDto = z.infer<
	typeof FullGuaranteeResponseSchema
>;

export const BasicGuarenteesResponseSchema = z.object({
	basicGuarantees: z.array(BasicGuaranteeResponseSchema),
});

export type BasicGuarenteesResponseDto = z.infer<
	typeof BasicGuarenteesResponseSchema
>;

export const FullGuarenteesResponseSchema = z.object({
	fullGuarantees: z.array(FullGuaranteeResponseSchema),
});

export type FullGuarenteesResponseDto = z.infer<
	typeof FullGuarenteesResponseSchema
>;
