import { useMonthlyPrime } from "@/hooks/useMonthlyPrice";
import usePricingStore from "@/stores/usePricingStore";
import useProgressStore from "@/stores/useProgressStore";
import { CircleArrowRight } from "lucide-react";

import { Button } from "../ui/button";

export enum NavigationAction {
	Next = "Next",
	Back = "Back",
}

interface MobileFooterProps {
	label?: string;
	onButtonClick?: (str: NavigationAction) => void;
}
export const MobileFooter = ({
	label = "Suivant",
	onButtonClick,
}: MobileFooterProps) => {
	const { monthlyPrime } = usePricingStore();
	const { currentStep } = useProgressStore();
	const { monthlyPrimeTotal } = useMonthlyPrime();

	return (
		<div className="fixed bottom-0 z-50 flex w-full items-center justify-between bg-yellow px-8 py-5 lg:hidden">
			<div className="">
				{currentStep > 1 && (
					<>
						<p className="text-xs font-light text-[#444B53]">À partir de</p>
						<div className="flex items-center gap-1">
							<p className="text-xl font-bold text-blue-500">
								{monthlyPrimeTotal
									? monthlyPrimeTotal.toFixed(2)
									: monthlyPrime?.toFixed(2)}{" "}
								€
							</p>
							<span className="mt-1 text-xs font-light text-[#444B53]">
								{" "}
								/mois
							</span>
						</div>
					</>
				)}
			</div>
			<Button
				variant={"suivant"}
				className="w-44 gap-2"
				type={onButtonClick ? "button" : "submit"}
				onClick={() => onButtonClick && onButtonClick(NavigationAction.Next)}
			>
				<span className="line-clamp-2 whitespace-normal">{label}</span>
				<CircleArrowRight className="size-4" />
			</Button>
		</div>
	);
};
