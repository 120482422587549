import { useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { trpc } from "@/utils";

import type { BasicGuarenteeResponseDto } from "@repos/ecom-dtos";

import { BasicGuaranteeDescriptionServices } from "./components/BasicGuaranteeDescriptionServices";
import { BasicGuaranteeSerivces } from "./components/BasicGuaranteeSerivces";

interface SimulationCardContentProps {
	hideFooter: (value: boolean) => void;
}

export default function SimulationCardContent({
	hideFooter,
}: SimulationCardContentProps) {
	const [selectedGuarantee, setSelectedGuarantee] =
		useState<BasicGuarenteeResponseDto | null>(null);

	const { data: guarantees, isLoading } =
		trpc.guarantees.getBasicGurantees.useQuery();

	if (isLoading) {
		return (
			<div className="flex max-h-full flex-col items-center space-y-2.5 p-6 pb-3 pl-8 pr-8 pt-0 lg:items-start lg:space-y-1.5 lg:pt-8">
				<Skeleton className="mb-8 h-[20px] max-h-[30vh] w-[70%] rounded-xl bg-gray-300 lg:w-[50%]" />

				<Skeleton className="mx-auto h-48 w-44 rounded-xl bg-gray-300 lg:h-[60px] lg:w-full" />
				<Skeleton className="h-48 w-44 rounded-xl bg-gray-300 lg:h-[60px] lg:w-full" />
				<Skeleton className="h-48 w-44 rounded-xl bg-gray-300 lg:h-[60px] lg:w-full" />
				<div className="hidden w-full justify-end lg:flex">
					<Skeleton className="mt-5 h-[60px] w-40 rounded-xl bg-gray-300" />
				</div>
			</div>
		);
	}

	if (!guarantees?.basicGuarantees) {
		return (
			<div className="mt-8 flex flex-1 flex-col gap-8 ">
				<Skeleton className="mb-14 ml-4 h-[20px] w-[30%] rounded-full bg-gray-300" />
			</div>
		);
	}

	return (
		<>
			{selectedGuarantee ? (
				<BasicGuaranteeDescriptionServices
					guarantee={selectedGuarantee}
					onSelect={() => {
						setSelectedGuarantee(null);
						hideFooter(true);
					}}
				/>
			) : (
				<BasicGuaranteeSerivces
					guarantees={guarantees.basicGuarantees}
					onGuaranteeSelect={(item) => {
						setSelectedGuarantee(item);
						hideFooter(false);
					}}
				/>
			)}
		</>
	);
}
