import arrowContact from "@/assets/arrowContact.svg";

export const ContactArrowIndicator = () => {
	return (
		<div className="fixed bottom-10 right-20 hidden items-start gap-3 lg:flex">
			<p className="text-right text-md font-normal leading-7 text-black">
				Si vous avez des questions, <br />
				n&rsquo;hésitez pas à nous contacter
			</p>
			<img src={arrowContact} />
		</div>
	);
};
