import type { SubmitHandler } from "react-hook-form";
import { useEffect } from "react";
import CustomCard from "@/components/CardComponent/CustomCard";
import { MobileFooter } from "@/components/MobileComponents/MobileFooter";
import { useToast } from "@/components/ui/use-toast";
import { useCustomNavigate } from "@/hooks/customNavigate";
import useProgressStore from "@/stores/useProgressStore";
import useSubscriberInfoStore from "@/stores/useSubscriberInfoStore";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import type { CreateSubscriberInfoRequestDto } from "@repos/ecom-dtos";
import { createSubscriberInfoRequestSchema } from "@repos/ecom-dtos";

import { LeftPageInfosSubscriber } from "./components/LeftPageInfosSubscriber";
import SubscriberInfosCardContent from "./components/SubscriberInfosCardContent";

interface SubsciberInfosPageProps {
	stepProps: number;
}

export const SubsciberInfosPage = ({ stepProps }: SubsciberInfosPageProps) => {
	const { toast } = useToast();
	const navigate = useCustomNavigate();
	const { incrementStep, setStep, setLastSubmittedStep } = useProgressStore(
		(state) => ({
			incrementStep: state.incrementStep,
			setStep: state.setStep,
			setLastSubmittedStep: state.setLastSubmittedStep,
		}),
	);

	const { setSubscriberInfo, subscriberInfo, isCheckedPolicyAndUseCases } =
		useSubscriberInfoStore((state) => ({
			subscriberInfo: state.subscriberInfo,
			setSubscriberInfo: state.setSubscriberInfo,
			isCheckedPolicyAndUseCases: state.isCheckedPolicyAndUseCases,
		}));

	const methods = useForm<CreateSubscriberInfoRequestDto>({
		defaultValues: {
			adressSubscriber: subscriberInfo?.adressSubscriber || "",
			city: subscriberInfo?.city || "",
			country: subscriberInfo?.country || "",
			postalCode: subscriberInfo?.postalCode || "",
		},
		resolver: zodResolver(createSubscriberInfoRequestSchema),
		mode: "onChange",
	});

	const onSubmit: SubmitHandler<CreateSubscriberInfoRequestDto> = (data) => {
		if (isCheckedPolicyAndUseCases) {
			incrementStep();
			navigate("EFFECTIVE_CONTRACT_DATE");
			const updatedSubscriber: CreateSubscriberInfoRequestDto = {
				...subscriberInfo,
				adressSubscriber: data.adressSubscriber,
				city: data.city,
				country: data.country,
				postalCode: data.postalCode,
			};
			setSubscriberInfo(updatedSubscriber);
			setLastSubmittedStep(stepProps);
		} else {
			toast({
				description:
					"Veuillez accepter nos politiques de confidentialité et nos conditions générales.",
				duration: 2000,
				variant: "destructive",
				style: {
					color: "white",
				},
			});
		}
	};

	const handleBack = () => {
		navigate("SUBSCRIBER_COORDS");
	};

	useEffect(() => {
		setStep(stepProps);
	}, []);

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="flex w-screen flex-1 flex-col items-center gap-5 lg:flex-row lg:gap-0">
					<div className="flex h-full w-full flex-1 flex-col items-center justify-center">
						<LeftPageInfosSubscriber />
					</div>
					<div className="mr-0 flex h-full w-full flex-1 items-center justify-center lg:mr-5">
						<CustomCard
							displayFooter={true}
							hideBackButton={false}
							handleBack={handleBack}
						>
							<SubscriberInfosCardContent />
						</CustomCard>
					</div>
				</div>
				<MobileFooter />
			</form>
		</FormProvider>
	);
};
