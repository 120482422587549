import { useCustomNavigate } from "@/hooks/customNavigate";
import anotherActivityIcon from "@/pages/5-CompanyActivityPage/assets/anotherActivityIcon.svg";
import useCustomSubscriptionStore, {
	SubscriberTypeEnum,
} from "@/stores/useCustomSubscriptionStore";

import { Badge } from "../ui/badge";

export const MobileAnotherActivity = () => {
	const navigate = useCustomNavigate();
	const { setAlertMessage, setAlertTitle, setSubscriberType } =
		useCustomSubscriptionStore((state) => ({
			setSubscriberType: state.setSubscriberType,
			setAlertMessage: state.setAlertMessage,
			setAlertTitle: state.setAlertTitle,
		}));

	const onClick = () => {
		const alertTitle = `Il semble que vous n'ayez pas trouvé votre secteur d'activité. `;
		const alertMessage =
			"Partagez quelques informations pour que nous puissions mieux répondre à vos besoins.";
		setSubscriberType(SubscriberTypeEnum.LowTurnover);
		setAlertTitle(alertTitle);
		setAlertMessage(alertMessage);
		navigate("CUSTOM_SUBSCRIPTION");
	};

	return (
		<Badge
			onClick={onClick}
			variant={"outline"}
			className="flex h-full min-w-20 flex-shrink-0 cursor-pointer flex-col text-ellipsis rounded-xl border-[1px] border-gray-300 px-5 py-4 text-center font-semibold lg:hidden"
		>
			<img
				className="mb-3 mr-0 h-full w-6 md:w-6 lg:mb-0 lg:mr-4 lg:w-6"
				src={anotherActivityIcon}
			/>
			<p>Autre activité</p>
		</Badge>
	);
};
