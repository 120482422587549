import * as React from "react";
import { Progress } from "@/components/ui/progress";
import useProgressStore from "@/stores/useProgressStore";

interface ProgressStepperProps {
	index: number;
	currentStep: number;
}

interface ProgressParams {
	minStep: number;
	maxStep: number;
}

export default function ProgressStepper({
	index,
	currentStep,
}: ProgressStepperProps) {
	const {
		lastStepOfFirstProgreesBar,
		numberOfTotalStep,
		firstStepOfFirstProgressBar,
	} = useProgressStore((state) => ({
		firstStepOfFirstProgressBar: state.firstStepOfFirstProgressBar,
		lastStepOfFirstProgreesBar: state.lastStepOfFirstProgreesBar,
		numberOfTotalStep: state.numberOfTotalStep,
	}));

	const calculateProgressSteps = React.useCallback(
		({ minStep, maxStep }: ProgressParams) => {
			const minProgress = 0;
			const maxProgress = 100;
			const nbrStep = maxStep - minStep;

			// Progress is 100%, from maxStep page
			if (currentStep >= maxStep) {
				return maxProgress;
			}
			// On minStep page, the progress is greater than 10%
			if (currentStep < minStep) {
				return minProgress;
			}

			// Calculates the progress percentage for the current step with a minimum value of 10%.
			return Math.max(
				((currentStep - minStep) / nbrStep) * (maxProgress - 10),
				10,
			);
		},
		[currentStep],
	);
	return (
		<>
			{index === 0 && (
				<Progress
					value={calculateProgressSteps({
						minStep: firstStepOfFirstProgressBar,
						maxStep: lastStepOfFirstProgreesBar,
					})}
				/>
			)}
			{index === 1 && (
				<Progress
					value={calculateProgressSteps({
						minStep: lastStepOfFirstProgreesBar,
						maxStep: numberOfTotalStep,
					})}
				/>
			)}
		</>
	);
}
