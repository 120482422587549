import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { cn } from "@/utils";

import type {
	GuaranteeInfoDto,
	OptionalGuaranteesRelatedStatementsDto,
} from "@repos/ecom-dtos";

interface CardGuaranteeStatementProps {
	data: OptionalGuaranteesRelatedStatementsDto;
	concatStorePricing: GuaranteeInfoDto[];
}
export default function CardGuaranteeStatement({
	data,
	concatStorePricing,
}: CardGuaranteeStatementProps) {
	const getNameForGuaranteeType = (type) => {
		const foundItem = concatStorePricing.find((item) => item.type === type);
		return foundItem ? foundItem.label : "";
	};
	return (
		<Card
			className={cn(
				"my-3 w-full border-none bg-transparent px-0 shadow-none lg:bg-white lg:px-8 lg:shadow-sm",
				data.guaranteeType === "CYBER"
					? "bg-transparent lg:bg-bluePastel"
					: data.guaranteeType === "TDMI"
						? "bg-transparent lg:bg-lightGreen-foreground"
						: "bg-transparent",
			)}
		>
			<CardHeader className="hidden flex-row items-center justify-start px-4 py-5 lg:flex">
				<img src={data.activeIconUrl} className="mr-3 size-7" />
				<p className="text-base font-semibold text-black">
					Pour votre garantie {getNameForGuaranteeType(data.guaranteeType)} :
				</p>
			</CardHeader>
			<CardContent className="flex flex-row items-center justify-start px-0 py-0 lg:px-4 lg:py-6">
				<ol className="ml-5 list-decimal">
					{data.statements.map((statement, index) => (
						<li key={`stat-${index}`} className="mb-2 text-sm">
							{statement}
						</li>
					))}
				</ol>
			</CardContent>
		</Card>
	);
}
