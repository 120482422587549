import { z } from "zod";

import {
	addressSchema,
	birthdaySchema,
	citySchema,
	countrySchema,
	emailSchema,
	firstNameSchema,
	genderSchema,
	lastNameSchema,
	PhoneNumberSchema,
	postalCodeSchema,
	suggestionActivitySchema,
} from "../primitives";

export const CreateSubscriberRequestSchema = z.object({
	email: z.string().email(),
	firstName: z.string(),
	lastName: z.string(),
	gendre: genderSchema,
	phoneNumber: z.string(),
	birthDate: z.string(),
	cityOfBirth: z.string(),
});

export type CreateSubscriberRequestDto = z.infer<
	typeof CreateSubscriberRequestSchema
>;

export const subsciberCoordsSchema = z.object({
	email: emailSchema,
	firstName: firstNameSchema,
	lastName: lastNameSchema,
	gendre: genderSchema,
	phoneNumber: PhoneNumberSchema,
	cityOfBirth: citySchema,
	birthDate: birthdaySchema,
});

export type SubscriberCoordsDto = z.infer<typeof subsciberCoordsSchema>;

export const createSubscriberInfoRequestSchema = z.object({
	adressSubscriber: addressSchema,
	city: citySchema,
	country: countrySchema,
	postalCode: postalCodeSchema,
});

export type CreateSubscriberInfoRequestDto = z.infer<
	typeof createSubscriberInfoRequestSchema
>;

export const prospectSchema = z.object({
	email: emailSchema,
	phoneNumber: PhoneNumberSchema,
	activity: suggestionActivitySchema.optional(),
});

export type ProspectDto = z.infer<typeof prospectSchema>;
