import { Card, CardContent, CardHeader } from "@/components/ui/card";
import unCheckIcon from "@/pages/10-StatementPage/assets/unCkeck.svg";

import type { StatementsResponseDto } from "@repos/ecom-dtos";

interface ProhibitedProductsNoticeProps {
	data: StatementsResponseDto;
}

export default function ProhibitedProductsNotice({
	data,
}: ProhibitedProductsNoticeProps) {
	const statements = data.prohibitedProducts;
	const midIndex = Math.ceil(statements.length / 2);
	const firstHalf = statements.slice(0, midIndex);
	const secondHalf = statements.slice(midIndex);
	return (
		<Card className="my-3 w-full border-none bg-transparent px-0 shadow-none lg:bg-white lg:px-8 lg:shadow-sm">
			<CardHeader className="hidden flex-row items-center justify-start px-0 py-5 lg:flex lg:border-b-[0.06rem] lg:border-gray-300 lg:px-8">
				<img src={unCheckIcon} className="mr-3 size-7" />
				<p className="text-base font-semibold text-black">
					Ne pas commercialiser les produits suivants :
				</p>
			</CardHeader>
			<CardContent className="flex flex-col items-center justify-evenly py-0 lg:flex-row lg:py-6">
				<div className="mb-2 w-full lg:w-2/5">
					<ul className="list-disc space-y-2 lg:pl-2">
						{firstHalf.map((item, index) => (
							<li key={`proh-${index}`} className="text-sm">
								{item}
							</li>
						))}
					</ul>
				</div>
				<div className="w-full lg:w-2/5">
					<ul className="list-disc space-y-2">
						{secondHalf.map((item, index) => (
							<li key={`proh-${index}`} className="text-sm">
								{item}
							</li>
						))}
					</ul>
				</div>
			</CardContent>
		</Card>
	);
}
