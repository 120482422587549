import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { cn } from "@/utils";
import { CircleAlert } from "lucide-react";
import { useFormContext } from "react-hook-form";

import { Input } from "../ui/input";

interface TextInputProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	placeholder?: string;
	enableInput?: boolean;
	labelClassName?: string;
	inputClassName?: string;
	onChange?: (value: string) => void;
	maxLength?: number;
	typeInput?: "number" | "text";
	required?: boolean;
}

export default function TextInput<T extends FieldValues>({
	label,
	placeholder,
	name,
	enableInput,
	labelClassName,
	inputClassName,
	onChange,
	maxLength,
	typeInput = "text",
	required = true,
}: TextInputProps<T>) {
	const { control } = useFormContext<T>();

	return (
		<FormField
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<>
					<FormItem className="relative w-full">
						<FormLabel
							className={cn(
								`absolute -top-2 left-4 z-20 truncate rounded-full bg-white pl-2 pr-2 text-xs text-blackMobile md:left-6 md:text-sm lg:text-primary`,
								labelClassName,
							)}
						>
							{label} {required && <span className="text-red-800">*</span>}
						</FormLabel>
						<div className="relative">
							<Input
								className={cn(inputClassName, error && "border-red-500 ")}
								disabled={!enableInput}
								{...field}
								placeholder={placeholder}
								type={typeInput}
								onChange={(e) => {
									if (onChange) {
										onChange(e.target.value.slice(0, maxLength));
									} else {
										field.onChange(e.target.value.slice(0, maxLength));
									}
								}}
								maxLength={maxLength}
							/>

							{error && (
								<div className="absolute inset-y-0 right-1 flex items-center pr-3">
									<CircleAlert className="size-5" color="red" />
								</div>
							)}
						</div>

						{error && (
							<div className="flex h-3 flex-row items-center">
								<FormMessage className="ml-1 text-xs font-normal" />
							</div>
						)}
					</FormItem>
				</>
			)}
		/>
	);
}
