"use client";

import type {
	FieldValues,
	Path,
	PathValue,
	UseControllerProps,
} from "react-hook-form";
import { useCallback, useState } from "react";
import { Button } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useDebounce } from "@/hooks/useDebounce";
import { cn, trpc } from "@/utils";
import { Check, CircleAlert } from "lucide-react";
import { Spinner } from "react-activity";
import { useFormContext } from "react-hook-form";

interface PostalCodeProperty<T extends FieldValues>
	extends UseControllerProps<T> {
	label: string;
}
interface PostalCodeProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	cityProps: PostalCodeProperty<T>;
	placeholder: string;
	inputClassName: string;
	labelClassName: string;
	enableInput?: boolean;
}

export function PostalCodeInput<T extends FieldValues>({
	name,
	label,
	placeholder,
	inputClassName,
	labelClassName,
	enableInput,
	cityProps,
}: PostalCodeProps<T>) {
	const { control, setValue, getValues } = useFormContext<T>();
	const [inputValue, setInputValue] = useState<string>(getValues(name) || "");
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const debouncedSearch = useDebounce(inputValue, 300);
	const { data: cities, isLoading } =
		trpc.address.getCitiesFromPostalCode.useQuery(
			{ postalCode: debouncedSearch },
			{
				enabled: debouncedSearch.length > 4,
			},
		);

	const handleInputChange = useCallback(
		(search: string) => {
			setInputValue(search);
			setValue(name, search as PathValue<T, Path<T>>);
		},
		[name, setValue],
	);

	return (
		<FormField
			control={control}
			name={name}
			render={({ field, fieldState: { error } }) => (
				<FormItem className="relative flex w-full flex-col">
					<FormLabel
						className={cn(
							`absolute -top-0 left-4 z-20 truncate rounded-full bg-white pl-2 pr-2 text-xs text-blackMobile md:left-6 md:text-sm md:text-primary`,
							labelClassName,
						)}
					>
						{label} <span className="text-red-800">*</span>
					</FormLabel>
					<Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
						<div className="relative">
							<PopoverTrigger asChild>
								<FormControl>
									<Button
										disabled={enableInput}
										variant="outline"
										role="combobox"
										className={cn(
											"flex h-11 w-full rounded-full border-2  border-gray-700 bg-transparent  px-3 py-1 text-center text-base font-normal shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium  placeholder:text-placeholder   xl:h-14",
											inputClassName,
											error && "border-red-500",
										)}
										onClick={() => setIsPopoverOpen((prev) => !prev)}
									>
										{field.value && field.value}
									</Button>
								</FormControl>
							</PopoverTrigger>
							{error && (
								<div className="absolute inset-y-0 right-1 flex items-center pr-3">
									<CircleAlert className="size-5" color="red" />
								</div>
							)}
						</div>
						<PopoverContent
							className="min-w-[120px] p-0 lg:min-w-[450px]"
							align="start"
						>
							<Command className="bg-white">
								<CommandInput
									placeholder={placeholder}
									value={field.value}
									onValueChange={handleInputChange}
									className="font-normal"
								/>

								<CommandList>
									<CommandEmpty>
										{isLoading && inputValue.length > 4 ? (
											<div className="flex items-center justify-center p-4">
												<Spinner className=" text-primary" />
											</div>
										) : inputValue.length <= 4 ? (
											"Veuillez saisir au moins 4 caractères."
										) : (
											"Aucune adresse trouvée."
										)}
									</CommandEmpty>
									<CommandGroup>
										{cities?.features.map((option, index) => (
											<CommandItem
												value={debouncedSearch}
												key={index}
												onSelect={() => {
													setValue(
														name,
														debouncedSearch as PathValue<T, Path<T>>,
														{ shouldValidate: true },
													);
													setValue(
														cityProps.name,
														option.city as PathValue<T, Path<T>>,
														{ shouldValidate: true },
													);
													setIsPopoverOpen(false);
												}}
												className="font-normal"
											>
												<Check
													className={cn(
														"mr-2 h-4 w-4",
														option.city === field.value
															? "opacity-100"
															: "opacity-0",
													)}
												/>
												{option.city}
											</CommandItem>
										))}
									</CommandGroup>
								</CommandList>
							</Command>
						</PopoverContent>
					</Popover>
					{error && (
						<div className="flex h-3 flex-row items-center">
							<FormMessage className="ml-1 text-xs font-normal" />
						</div>
					)}
				</FormItem>
			)}
		/>
	);
}
