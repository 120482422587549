import { Route } from "@/routes/constants";

export type Step = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export const getLastRouteForStep = (step: Step): Route => {
	const stepRoutes: Record<Step, Route> = {
		1: Route.TURNOVER,
		2: Route.SIMULATION_GUARANTEE,
		3: Route.COMPANY_SIREN,
		4: Route.COMPANY_ACTIVITY,
		5: Route.SUBSCRIBER_COORDS,
		6: Route.SUBSCRIBER_INFOS,
		7: Route.EFFECTIVE_CONTRACT_DATE,
		8: Route.SUMMARIZE_OFFER,
		9: Route.OFFER_REQUIREMENTS,
	};

	return stepRoutes[step] || Route.TURNOVER;
};
