import { Button } from "@/components/ui/button";
import { CardContent, CardDescription } from "@/components/ui/card";
import { useCustomNavigate } from "@/hooks/customNavigate";
import sentIcon from "@/pages/2-CustomSubscriptionPage/assets/sentIcon.svg";

export default function SuccessSentEmailContentCard() {
	const navigate = useCustomNavigate();
	return (
		<>
			<CardContent className="flex flex-grow items-center justify-center pt-6">
				<div className="flex w-full flex-col items-center justify-center rounded-xl bg-[#ffdd8540] px-7 py-16 lg:bg-transparent lg:px-0 lg:py-0">
					<img src={sentIcon} className="size-12 md:size-auto" />
					<CardDescription className="mb-0 mt-5 text-center text-sm text-black lg:mb-12 lg:text-lg xl:mb-14 xl:w-3/4 xl:text-base">
						L&apos;assureur vous contactera directement pour procéder à la prise
						en charge de votre police d&apos;assurance E-commerçants
					</CardDescription>
					<Button
						variant={"secondary_bg"}
						onClick={() => navigate("TURNOVER")}
						className="hidden lg:flex"
					>
						Revenir à la page d&apos;accueil
					</Button>
				</div>
			</CardContent>
		</>
	);
}
