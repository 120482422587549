import Layout from "@/layouts/Layout";
import * as Sentry from "@sentry/react";
import { BrowserRouter, useRoutes } from "react-router-dom";

import { routes as routesDefinition } from "./constants";

const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

const Routes = () => {
	const commonRoutes = [
		{
			path: "/",
			element: <Layout />,
			children: Object.values(routesDefinition),
		},
	];

	const routes = useSentryRoutes([...commonRoutes]);
	return routes;
};

export const AppRoutes = () => {
	return (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	);
};
