import { useEffect } from "react";
import { MobileAnotherActivity } from "@/components/MobileComponents/MobileAnotherActivity";
import { CardHeader } from "@/components/ui/card";
import { FormField, FormItem } from "@/components/ui/form";
import { Skeleton } from "@/components/ui/skeleton";
import { useToast } from "@/components/ui/use-toast";
import { ActivityItem } from "@/pages/5-CompanyActivityPage/components/ActivityItem";
import useActivityStore from "@/stores/useActivityStore";
import { trpc } from "@/utils";
import { useFormContext } from "react-hook-form";

export default function ActivitiesCardContent() {
	const { setActivity, setActivityLabel } = useActivityStore((state) => ({
		setActivity: state.setActivity,
		setActivityLabel: state.setActivityLabel,
	}));
	const {
		control,
		setValue,
		watch,
		formState: { errors },
	} = useFormContext();
	const selectedActivity = watch("selectedActivity");

	const { data, isLoading } = trpc.configuration.getActivityFields.useQuery();
	const { toast } = useToast();

	useEffect(() => {
		if (errors.selectedActivity) {
			toast({
				description: "Veuillez sélectionner une activité.",
				duration: 2000,
				variant: "destructive",
				style: {
					color: "white",
				},
			});
		}
	}, [errors.selectedActivity, toast]);

	return (
		<FormField
			name="selectedActivity"
			control={control}
			render={({ field }) => (
				<FormItem>
					<CardHeader className="mt-0 flex flex-grow items-center justify-start">
						<div className="grid w-full auto-rows-[1fr] grid-cols-2 flex-wrap items-center justify-around gap-2 p-1 lg:flex lg:gap-3">
							{isLoading
								? new Array(7)
										.fill("")
										.map((_, index) => (
											<Skeleton
												key={index}
												className="h-24 min-w-20 flex-shrink-0 rounded-xl bg-gray-300 lg:h-20 lg:w-[15vw] lg:rounded-full"
											/>
										))
								: data?.activities.map((activity) => {
										return (
											<ActivityItem
												key={activity.order}
												activity={activity}
												onSelect={() => {
													setValue("selectedActivity", activity.name);
													setActivity({
														selectedActivity: activity.name as
															| "Fashion"
															| "Entertainment"
															| "Furniture"
															| "Hygiene"
															| "Food"
															| "Artworks"
															| "Electronics",
													});
													setActivityLabel(activity.label);
													field.onChange(activity.name);
												}}
												selectedActivity={selectedActivity}
											/>
										);
									})}
							<MobileAnotherActivity />
						</div>
					</CardHeader>
				</FormItem>
			)}
		/>
	);
}
