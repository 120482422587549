import { z } from "zod";

export const UserResponseSchema = z.object({
	id: z.string().min(1),
	name: z.string().nullable(),
	email: z.string().email(),
	image: z.string().nullable(),
	emailVerified: z.date().nullable(),
	createdAt: z.date(),
	updatedAt: z.date(),
});
export type UserResponse = z.infer<typeof UserResponseSchema>;
