import type { ReactNode } from "react";
import type { Location } from "react-router-dom";
import { useCustomNavigate } from "@/hooks/customNavigate";
import useProgressStore from "@/stores/useProgressStore";
import { Navigate, useLocation } from "react-router-dom";

type LocationState = {
	isSafe?: boolean;
};
type Props = {
	children: ReactNode;
};

export default function ResumeProgressRedirect({ children }: Props) {
	const navigate = useCustomNavigate();
	const { lastSubmittedStep } = useProgressStore((state) => ({
		lastSubmittedStep: state.lastSubmittedStep,
	}));

	const hasResumeData = lastSubmittedStep !== null;

	const location: Location<LocationState | undefined> = useLocation();

	const isSafe = location.state?.isSafe;

	if (!isSafe) {
		if (hasResumeData) {
			return <Navigate to={"/backup-session"} />;
		} else {
			navigate("TURNOVER");
		}
	}

	return <>{children}</>;
}
