import * as React from "react";
import { cn } from "@/utils";
import * as SliderPrimitive from "@radix-ui/react-slider";

// SliderRoot Component
const SliderRoot = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
	<SliderPrimitive.Root
		ref={ref}
		className={cn(
			"relative flex w-full touch-none select-none items-center",
			className,
		)}
		{...props}
	/>
));
SliderRoot.displayName = SliderPrimitive.Root.displayName;

// SliderTrack Component
const SliderTrack = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Track>,
	React.ComponentPropsWithoutRef<typeof SliderPrimitive.Track>
>(({ className, ...props }, ref) => (
	<SliderPrimitive.Track
		ref={ref}
		className={cn(
			"relative h-1.5 w-full grow overflow-hidden rounded-full bg-primary/20",
			className,
		)}
		{...props}
	/>
));
SliderTrack.displayName = SliderPrimitive.Track.displayName;

// SliderRange Component
const SliderRange = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Range>,
	React.ComponentPropsWithoutRef<typeof SliderPrimitive.Range>
>(({ className, ...props }, ref) => (
	<SliderPrimitive.Range
		ref={ref}
		className={cn("absolute h-full bg-primary", className)}
		{...props}
	/>
));
SliderRange.displayName = SliderPrimitive.Range.displayName;

// SliderThumb Component
const SliderThumb = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Thumb>,
	React.ComponentPropsWithoutRef<typeof SliderPrimitive.Thumb>
>(({ className, ...props }, ref) => (
	<SliderPrimitive.Thumb
		ref={ref}
		className={cn(
			"relative block h-4 w-4 rounded-full border border-primary/50 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
			className,
		)}
		{...props}
	/>
));
SliderThumb.displayName = SliderPrimitive.Thumb.displayName;

export { SliderRoot, SliderTrack, SliderRange, SliderThumb };
