import CustomCardHeader from "@/components/CardComponent/CustomCardHeader";
import { Card, CardContent } from "@/components/ui/card";
import useActivityStore from "@/stores/useActivityStore";
import useCompanyStore from "@/stores/useCompanyStore";
import useSubscriberStore from "@/stores/useSubscriberCoordsStore";
import useSubscriberInfoStore from "@/stores/useSubscriberInfoStore";

import infoCompanyUserIcon from "../assets/infoCompanyUserIcon.svg";
import { RecapInfoCard } from "./RecapInfoCard";

export const InfoCompanyAndUserCard = () => {
	const company = useCompanyStore((state) => state.company);
	const activity = useActivityStore((state) => state.activityLabel);
	const subscriber = useSubscriberStore((state) => state.subscriber);
	const subscriberInfo = useSubscriberInfoStore(
		(state) => state.subscriberInfo,
	);

	return (
		<Card className="relative w-full border-none bg-transparent px-0 pt-0 shadow-none lg:bg-[#FFDD85] lg:px-24 lg:pt-8 lg:shadow-sm">
			<div className="absolute bottom-0 left-0 z-0 size-64 rounded-b-xl bg-contain bg-no-repeat lg:bg-[url('/src/pages/9-SummarizeOfferPage/assets/bgShape.svg')]" />
			<CustomCardHeader
				headerCardLabel="Informations de la société / souscripteur"
				headerCardImage={infoCompanyUserIcon}
				className="!hidden border-white border-opacity-30 lg:!flex"
			/>
			<CardContent className="relative z-10 mt-0 flex w-full flex-col gap-6 p-0 lg:mt-6 lg:flex-row lg:pb-10">
				<h2 className="text-lg font-bold text-gray-400 lg:hidden">
					Informations de la société
				</h2>
				<RecapInfoCard className="bg-[#ffd46526]">
					<h3 className="lg:text-lg: mb-4 text-sm font-semibold text-blue-500">
						{company?.name}
					</h3>
					<div className="flex flex-col space-y-2">
						<p className="text-sm font-medium">
							<span className="font-semibold">SIREN : </span>
							N°{company?.sirenNumber}
						</p>
						<p className="text-sm font-medium">
							<span className="font-semibold">Activité : </span>
							{activity}
						</p>
						<p className="text-sm font-medium">
							<span className="font-semibold">Adresse complète : </span>
							{company?.address}
						</p>
					</div>
				</RecapInfoCard>
				<h2 className="text-lg font-bold text-gray-400 lg:hidden">
					Informations du souscripteur
				</h2>
				<RecapInfoCard className="bg-[#ffe0e070]">
					<h3 className="lg:text-lg: mb-4 text-sm font-semibold text-blue-500">
						{subscriber?.firstName} {subscriber?.lastName}
					</h3>
					<div className="flex flex-col space-y-2">
						<p className="text-sm font-medium">
							<span className="font-semibold">Numéro de téléphone : </span>
							{subscriber?.phoneNumber}
						</p>
						<p className="text-sm font-medium">
							<span className="font-semibold">E-mail : </span>
							{subscriber?.email}
						</p>
						<p className="text-sm font-medium">
							<span className="font-semibold">Adresse complète : </span>
							{subscriberInfo?.adressSubscriber}
						</p>
					</div>
				</RecapInfoCard>
			</CardContent>
		</Card>
	);
};
