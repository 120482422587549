export const calculateMonthlyPriceIncludingTax = (
	beforeTax: number,
	taxValue: number,
	terrorismFunds?: number,
) => {
	if (terrorismFunds) {
		return (beforeTax + taxValue + terrorismFunds) / 12;
	} else {
		return (beforeTax + taxValue) / 12;
	}
};
