import { useEffect } from "react";
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import closeIcon from "@/pages/3-SimulationAndGuaranteesPage/assets/close.svg";
import closeBlueIcon from "@/pages/3-SimulationAndGuaranteesPage/assets/closeBlueIcon.svg";

import type { BasicGuarenteeResponseDto } from "@repos/ecom-dtos";

interface BasicGuaranteeDescriptionServicesProps {
	guarantee: BasicGuarenteeResponseDto;
	onSelect: () => void;
}

export const BasicGuaranteeDescriptionServices = ({
	guarantee,
	onSelect,
}: BasicGuaranteeDescriptionServicesProps) => {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<>
			<CardTitle className="!mb-5 text-center text-lg font-bold text-blackMobile lg:hidden">
				Nos garanties de base
			</CardTitle>
			<div className="space-y-0 rounded-xl bg-[#ffdd712b] lg:space-y-2 lg:bg-white">
				<CardHeader className="pb-4 pt-10 lg:pt-14">
					<div className="flex w-full flex-col items-center justify-between border-gray-600 pb-5 lg:flex-row lg:border-b">
						<img
							src={guarantee.activeIconUrl}
							className="mb-4 size-12 lg:hidden"
						/>
						<CardTitle className="w-[70%] text-center text-sm font-bold text-blackMobile lg:text-left lg:text-base lg:font-normal lg:text-gray-800">
							{guarantee.label}
						</CardTitle>
						{/* Desktop */}
						<img
							onClick={onSelect}
							src={closeIcon}
							className="hidden cursor-pointer lg:block"
						/>
					</div>
				</CardHeader>
				<CardContent className="mt-1 flex flex-grow flex-col pb-0 text-center lg:pb-28 lg:text-left">
					{guarantee.details.map((detail, index) => (
						<li
							key={`det-${index}`}
							className="mb-5 text-sm font-medium text-gray-800"
						>
							{detail}
						</li>
					))}
					{/* Mobile */}
					<div
						role="button"
						onClick={onSelect}
						className="mx-auto mt-14 cursor-pointer pb-10 text-center lg:hidden"
					>
						<img src={closeBlueIcon} className="mx-auto size-6" />
						<span className="text-xs text-blackMobile underline">
							Fermer la fenêtre
						</span>
					</div>
				</CardContent>
			</div>
		</>
	);
};
