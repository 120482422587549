import type { Subscriber } from "@/stores/useSubscriberCoordsStore";
import type { SubmitHandler } from "react-hook-form";
import { useEffect } from "react";
import CustomCard from "@/components/CardComponent/CustomCard";
import { MobileFooter } from "@/components/MobileComponents/MobileFooter";
import { useCustomNavigate } from "@/hooks/customNavigate";
import useProgressStore from "@/stores/useProgressStore";
import useSubscriberStore from "@/stores/useSubscriberCoordsStore";
import { convertToUtc } from "@/utils/dateUtlis";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import { subsciberCoordsSchema } from "@repos/ecom-dtos";

import { LeftPageCoordsSubscriber } from "./components/LeftPageCoordsSubscriber";
import SubscriberCoordsCardContent from "./components/SubscriberCoordsCardContent";

// enum NavigationAction {
// 	Next = "Next",
// 	Back = "Back",
// }

interface SubscriberCoordsPageProps {
	stepProps: number;
}

export const SubscriberCoordsPage = ({
	stepProps,
}: SubscriberCoordsPageProps) => {
	const navigate = useCustomNavigate();
	const { setStep, setLastSubmittedStep } = useProgressStore((state) => ({
		incrementStep: state.incrementStep,
		setStep: state.setStep,
		setLastSubmittedStep: state.setLastSubmittedStep,
	}));

	const { subscriber, setSubscriber } = useSubscriberStore((state) => ({
		subscriber: state.subscriber,
		setSubscriber: state.setSubscriber,
	}));

	const methods = useForm<Subscriber>({
		defaultValues: {
			firstName: subscriber?.firstName || "",
			lastName: subscriber?.lastName || "",
			email: subscriber?.email || "",
			gendre: subscriber?.gendre || undefined,
			phoneNumber: subscriber?.phoneNumber || "",
			cityOfBirth: subscriber?.cityOfBirth || "",
			// TODO: fix this
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore : birthDate is a string
			birthDate: subscriber ? new Date(subscriber.birthDate) : new Date(),
			// birthDate : subscriber?.birthDate || ""
		},
		resolver: zodResolver(subsciberCoordsSchema),
		mode: "onSubmit",
	});

	const onSubmit: SubmitHandler<Subscriber> = (data) => {
		navigate("SUBSCRIBER_INFOS");
		const updatedSubscriber: Subscriber = {
			...subscriber,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			gendre: data.gendre,
			phoneNumber: data.phoneNumber,
			cityOfBirth: data.cityOfBirth,
			birthDate: convertToUtc(data.birthDate),
		};
		setSubscriber(updatedSubscriber);
		setLastSubmittedStep(stepProps);
	};

	const handleBack = () => {
		navigate("COMPANY_ACTIVITY");
	};

	useEffect(() => {
		setStep(stepProps);
	}, []);

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="flex w-screen flex-1 flex-col items-center gap-5 pb-20 lg:flex-row lg:gap-0">
					<div className="flex h-full w-full flex-1 flex-col items-center justify-center">
						<LeftPageCoordsSubscriber />
					</div>
					<div className="mr-0 flex h-full w-full flex-1 items-center justify-center lg:mr-5">
						<CustomCard
							displayFooter={true}
							hideBackButton={false}
							handleBack={handleBack}
						>
							<SubscriberCoordsCardContent />
						</CustomCard>
					</div>
				</div>
				<MobileFooter />
			</form>
		</FormProvider>
	);
};
