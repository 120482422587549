import * as React from "react";
import { cn } from "@/utils";

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(
					"flex h-11 w-full rounded-full border-2  border-gray-700 bg-transparent  px-3 py-1 text-center text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-placeholder  focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 xl:h-14",
					className,
				)}
				ref={ref}
				onWheel={(e) => e.currentTarget.blur()}
				{...props}
			/>
		);
	},
);
Input.displayName = "Input";

export { Input };
