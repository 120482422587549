import { useEffect } from "react";
import CustomCard from "@/components/CardComponent/CustomCard";
import { MobileFooter } from "@/components/MobileComponents/MobileFooter";
import { useCustomNavigate } from "@/hooks/customNavigate";
import useActivityStore from "@/stores/useActivityStore";
import useProgressStore from "@/stores/useProgressStore";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import type { ActivitiesDto } from "@repos/ecom-dtos";
import { activitySchema } from "@repos/ecom-dtos";

import ActivitiesCardContent from "./components/ActivitiesCardContent";
import { LeftPageActivitiesCompany } from "./components/LeftPageActivitiesCompany";

// enum NavigationAction {
//     Next = "Next",
//     Back = "Back",
// }

interface CompanyActivityPageProps {
	stepProps: number;
}

export const CompanyActivityPage = ({
	stepProps,
}: CompanyActivityPageProps) => {
	const navigate = useCustomNavigate();
	const { incrementStep, setStep, setLastSubmittedStep } = useProgressStore(
		(state) => ({
			incrementStep: state.incrementStep,
			setStep: state.setStep,
			setLastSubmittedStep: state.setLastSubmittedStep,
		}),
	);
	const { activity } = useActivityStore((state) => ({
		activity: state.activity,
	}));

	const methods = useForm<ActivitiesDto>({
		resolver: zodResolver(activitySchema),
		defaultValues: {
			selectedActivity: activity?.selectedActivity || undefined,
		},
		mode: "onSubmit",
	});

	const onSubmit = () => {
		incrementStep();
		navigate("SUBSCRIBER_COORDS");
		setLastSubmittedStep(stepProps);
	};

	const handleBack = () => {
		navigate("COMPANY_SIREN");
	};

	useEffect(() => {
		setStep(stepProps);
	}, []);

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="flex w-screen flex-1 flex-col items-center gap-5 lg:flex-row lg:gap-0">
					<div className="flex h-full w-full flex-1 flex-col items-center justify-center">
						<LeftPageActivitiesCompany />
					</div>
					<div className="mr-0 flex h-full w-full flex-1 items-center justify-center lg:mr-5">
						<CustomCard
							displayFooter={true}
							hideBackButton={false}
							onButtonClick={() => methods.handleSubmit(onSubmit)()}
							hideSubmitButton={false}
							handleBack={handleBack}
							className="p-2 lg:p-4"
						>
							<ActivitiesCardContent />
						</CustomCard>
					</div>
				</div>
				<MobileFooter />
			</form>
		</FormProvider>
	);
};
