import InfoIcon from "@/pages/9-SummarizeOfferPage/assets/info-icon.svg";
import { calculateMonthlyPriceIncludingTax } from "@/utils/calculateMonthlyPriceIncludingTax";
import type { GuaranteeCeilingType } from "@prisma/client";

import type { CeilingOptionDto } from "@repos/ecom-dtos";

import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

interface MobileGuaranteesChosenProps {
	icon: string;
	title: string;
	price: number;
	optionsGuarantee?: CeilingOptionDto[];
	guaranteeType?: GuaranteeCeilingType;
}

export const MobileGuaranteesChosen = ({
	icon,
	title,
	price,
	optionsGuarantee,
	guaranteeType,
}: MobileGuaranteesChosenProps) => {
	const isCyberType = guaranteeType === "CYBER";

	return (
		<div className="flex h-44 flex-col rounded-xl bg-[#ffdd852b] px-3 py-2 lg:hidden">
			<div className="mb-3 flex w-full items-center justify-between">
				<div className="w-auto rounded-full bg-[#F2C4C4] px-4 py-2">
					<span className="text-xs font-bold text-blue-500">
						{price.toFixed(2)} €
					</span>
				</div>
				{isCyberType && (
					<Popover>
						<PopoverTrigger>
							<div className="cursor-pointer rounded-full bg-[#F2C4C4] p-2">
								<img src={InfoIcon} className="relative z-50 size-6 w-auto" />
							</div>
						</PopoverTrigger>
						<PopoverContent
							side="bottom"
							className="relative bottom-1 mt-3 w-80 space-y-4 rounded-xl border-none bg-[#0F6DD4] px-6 py-8 text-sm font-semibold text-white shadow-none"
						>
							{optionsGuarantee?.map((item, index) => (
								<div key={`optionsGuarantee-${index}`}>
									<div className="flex flex-row items-center justify-between">
										<p className="flex text-left text-xs text-white">
											{item.label}
										</p>
										<p className="flex w-14 justify-end text-right text-xs font-bold text-white">
											{calculateMonthlyPriceIncludingTax(
												item.priceBeforeTax,
												item.taxValue,
											)}{" "}
											€
										</p>
									</div>
								</div>
							))}
							<div className="absolute -top-5 left-1/2 size-3 -translate-x-1/2 rotate-45 transform border-t border-[#0F6DD4] bg-[#0F6DD4] " />
						</PopoverContent>
					</Popover>
				)}
			</div>
			<div className="flex flex-col items-center gap-2 text-center">
				<img className="size-8 grayscale" src={icon} alt={title} />
				<h3 className="text-xs font-semibold leading-4 text-[#444B53] md:text-sm">
					{title}
				</h3>
			</div>
		</div>
	);
};
