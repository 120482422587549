import { z } from "zod";

import { CreateCompanyRequestSchema } from "./company.dto";
import { CreateSubscriberRequestSchema } from "./subscriber.dto";

export const CreateSubscriptionRequestSchema = z.object({
	subscriber: CreateSubscriberRequestSchema,
	company: CreateCompanyRequestSchema,
});

export type CreateSubscriptionRequestDto = z.infer<
	typeof CreateSubscriptionRequestSchema
>;
