import { useEffect } from "react";
import { Button } from "@/components/ui/button";
import useProgressStore from "@/stores/useProgressStore";

interface OfferFinishPageProps {
	stepProps: number;
}

export const OfferFinishPage = ({ stepProps }: OfferFinishPageProps) => {
	const { setStep } = useProgressStore((state) => ({
		setStep: state.setStep,
	}));

	useEffect(() => {
		setStep(stepProps);
	}, []);
	return (
		<div className="flex h-screen flex-col items-center justify-center">
			<div className="flex flex-col items-center justify-center gap-4">
				<p className="text-center text-2xl font-bold">Félicitation</p>
				<Button size={"lg"} onClick={() => {}}>
					suivant
				</Button>
			</div>
		</div>
	);
};
