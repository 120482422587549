import { TurnoverPage } from "@/pages/1-TurnoverCompanyPage";
import { CustomSubscriptionPage } from "@/pages/2-CustomSubscriptionPage";
import { SimulationAndGuaranteesPage } from "@/pages/3-SimulationAndGuaranteesPage";
import { CompanySIRENPage } from "@/pages/4-CompanySIRENPage";
import { CompanyActivityPage } from "@/pages/5-CompanyActivityPage";
import { SubscriberCoordsPage } from "@/pages/6-SubscriberCoordsPage";
import { SubsciberInfosPage } from "@/pages/7-SubsciberInfosPage";
import { EffectiveContractDatePage } from "@/pages/8-EffectiveContractDatePage";
import { SummarizeOfferPage } from "@/pages/9-SummarizeOfferPage";
import { StatementPage } from "@/pages/10-StatementPage";
import { PaymentMethodPage } from "@/pages/11-PaymentMethodPage";
import { SignaturePage } from "@/pages/12-SignaturePage";
import { OfferFinishPage } from "@/pages/13-OfferFinishPage";
import { BackupSessionPage } from "@/pages/BackupSessionPage";
import ResumeProgressRedirect from "@/pages/BackupSessionPage/components/ResumeProgressRedirect";
import { NotFoundPage } from "@/pages/NotFoundPage";
import { SubscriptionSuccessfullyPage } from "@/pages/SubscriptionSuccessfullyPage";

const withResumeProgressRedirect = (Component, stepProps?) => (
	<ResumeProgressRedirect>
		<Component stepProps={stepProps} />
	</ResumeProgressRedirect>
);

export enum Route {
	TURNOVER = "TURNOVER",
	SIMULATION_GUARANTEE = "SIMULATION_GUARANTEE",
	CUSTOM_SUBSCRIPTION = "CUSTOM_SUBSCRIPTION",
	COMPANY_SIREN = "COMPANY_SIREN",
	COMPANY_ACTIVITY = "COMPANY_ACTIVITY",
	SUBSCRIBER_COORDS = "SUBSCRIBER_COORDS",
	SUBSCRIBER_INFOS = "SUBSCRIBER_INFOS",
	EFFECTIVE_CONTRACT_DATE = "EFFECTIVE_CONTRACT_DATE",
	SUMMARIZE_OFFER = "SUMMARIZE_OFFER",
	OFFER_REQUIREMENTS = "OFFER_REQUIREMENTS",
	PAYMENT_METHOD = "PAYMENT_METHOD",
	SIGNATURE = "SIGNATURE",
	OFFER_FINISH = "OFFER_FINISH",
	NOT_FOUND = "NOT_FOUND",
	SUBSCRIPTION_SUCCESSFULLY = "SUBSCRIPTION_SUCCESSFULLY",
	BACKUP_SESSION = "BACKUP_SESSION",
}

export const routes = {
	[Route.TURNOVER]: {
		path: "/",
		element: withResumeProgressRedirect(TurnoverPage, 1),
	},
	[Route.CUSTOM_SUBSCRIPTION]: {
		path: "/prise-de-contact",
		element: withResumeProgressRedirect(CustomSubscriptionPage),
	},
	[Route.SIMULATION_GUARANTEE]: {
		path: "/estimation-prime-guarantee",
		element: withResumeProgressRedirect(SimulationAndGuaranteesPage, 2),
	},
	[Route.COMPANY_SIREN]: {
		path: "/get-siren-num",
		element: withResumeProgressRedirect(CompanySIRENPage, 3),
	},
	[Route.COMPANY_ACTIVITY]: {
		path: "/choose-activity",
		element: withResumeProgressRedirect(CompanyActivityPage, 4),
	},
	[Route.SUBSCRIBER_COORDS]: {
		path: "/get-coords-subscriber",
		element: withResumeProgressRedirect(SubscriberCoordsPage, 5),
	},
	[Route.SUBSCRIBER_INFOS]: {
		path: "/get-infos-subscriber",
		element: withResumeProgressRedirect(SubsciberInfosPage, 6),
	},
	[Route.EFFECTIVE_CONTRACT_DATE]: {
		path: "/effective-contract-date",
		element: withResumeProgressRedirect(EffectiveContractDatePage, 7),
	},
	[Route.SUMMARIZE_OFFER]: {
		path: "/summary-offer",
		element: withResumeProgressRedirect(SummarizeOfferPage, 8),
	},
	[Route.OFFER_REQUIREMENTS]: {
		path: "/declaration-offer",
		element: withResumeProgressRedirect(StatementPage, 9),
	},
	[Route.PAYMENT_METHOD]: {
		path: "/payment-method",
		element: withResumeProgressRedirect(PaymentMethodPage, 10),
	},
	[Route.SIGNATURE]: {
		path: "/signature-subscriber",
		element: withResumeProgressRedirect(SignaturePage, 11),
	},
	[Route.OFFER_FINISH]: {
		path: "/finish-contract",
		element: withResumeProgressRedirect(OfferFinishPage, 12),
	},
	[Route.NOT_FOUND]: {
		path: "/not-found",
		element: <NotFoundPage />,
	},
	[Route.SUBSCRIPTION_SUCCESSFULLY]: {
		path: "/subscription-successfully",
		element: <SubscriptionSuccessfullyPage />,
	},
	[Route.BACKUP_SESSION]: {
		path: "/backup-session",
		element: <BackupSessionPage />,
	},
};
