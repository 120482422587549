import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { CreateSubscriberInfoRequestDto } from "@repos/ecom-dtos";

// Type of store
interface SubscriberInfoStore {
	subscriberInfo: CreateSubscriberInfoRequestDto | null;
	setSubscriberInfo: (
		subscriberInfo: CreateSubscriberInfoRequestDto | null,
	) => void;
	isCheckedPolicyAndUseCases: boolean;
	setIsCheckedPolicyAndUseCases: (isCheckedPolicyAndUseCases: boolean) => void;
	isCheckedUpdateAndPromotionnalOffers: boolean;
	setIsCheckedUpdateAndPromotionnalOffers: (
		isCheckedUpdateAndPromotionnalOffers: boolean,
	) => void;
	reset: () => void;
}

// Create store
const useSubscriberInfoStore = create<SubscriberInfoStore>()(
	persist(
		(set) => ({
			subscriberInfo: null,
			setSubscriberInfo: (
				subscriberInfo: CreateSubscriberInfoRequestDto | null,
			) => set({ subscriberInfo }),
			isCheckedPolicyAndUseCases: false,
			setIsCheckedPolicyAndUseCases: (isCheckedPolicyAndUseCases: boolean) =>
				set({ isCheckedPolicyAndUseCases: isCheckedPolicyAndUseCases }),
			isCheckedUpdateAndPromotionnalOffers: false,
			setIsCheckedUpdateAndPromotionnalOffers: (
				isCheckedUpdateAndPromotionnalOffers: boolean,
			) =>
				set({
					isCheckedUpdateAndPromotionnalOffers:
						isCheckedUpdateAndPromotionnalOffers,
				}),
			reset: () =>
				set({
					subscriberInfo: null,
					isCheckedPolicyAndUseCases: false,
					isCheckedUpdateAndPromotionnalOffers: false,
				}),
		}),
		{
			name: "subscriberInfo-storage",
			getStorage: () => localStorage,
		},
	),
);

export default useSubscriberInfoStore;
