import { Button } from "@/components/ui/button";
import { useCustomNavigate } from "@/hooks/customNavigate";
import scanIcon from "@/pages/4-CompanySIRENPage/assets/scanIcon.svg";
import useCustomSubscriptionStore, {
	SubscriberTypeEnum,
} from "@/stores/useCustomSubscriptionStore";

export const LeftPageActivitiesCompany = () => {
	const navigate = useCustomNavigate();
	const { setAlertMessage, setAlertTitle, setSubscriberType } =
		useCustomSubscriptionStore((state) => ({
			setSubscriberType: state.setSubscriberType,
			setAlertMessage: state.setAlertMessage,
			setAlertTitle: state.setAlertTitle,
		}));

	const handleNavigation = () => {
		const alertTitle = `Il semble que vous n'ayez pas trouvé votre secteur d'activité. `;
		const alertMessage =
			"Partagez quelques informations pour que nous puissions mieux répondre à vos besoins.";
		setSubscriberType(SubscriberTypeEnum.LowTurnover);
		setAlertTitle(alertTitle);
		setAlertMessage(alertMessage);
		navigate("CUSTOM_SUBSCRIPTION");
	};

	return (
		<div className="mt-3 flex w-full flex-col items-start pl-8 pr-8 lg:w-5/6">
			<div className="flex flex-row place-items-start items-center">
				<img src={scanIcon} className="md:w-18 lg:24 w-14" />
				<p className="ml-3 w-full text-lg font-bold text-gray-800 lg:w-[60%] lg:text-2xl">
					Indiquez votre secteur d&apos;activité
				</p>
			</div>
			<p className="mb-5 mt-2 hidden text-sm lg:flex lg:w-[70%]">
				Sélectionnez votre principale activité parmi les options suivantes
			</p>
			<Button
				onClick={handleNavigation}
				className="mt-0 hidden border border-blue-500 bg-transparent px-5 py-6 lg:mt-8 lg:flex"
				variant={"secondary_bg"}
			>
				Je ne trouve pas mon activité
			</Button>
		</div>
	);
};
