import type { ClassValue } from "clsx";
import { cn } from "@/utils";
import { CircleAlert } from "lucide-react";

interface InfoInsuranceSelectedProps {
	children: React.ReactNode;
	icon: string;
	title: string;
	className?: ClassValue;
}

export const InfoInsuranceSelected = ({
	children,
	icon,
	title,
	className,
}: InfoInsuranceSelectedProps) => {
	return (
		<div
			className={cn(
				"w-full border-black border-opacity-10 pb-0 lg:border-b lg:pb-7",
				className,
			)}
		>
			<div className="flex items-center justify-between">
				<div className="flex items-center space-x-3">
					<img src={icon} alt={title} />
					<div className="flex items-center text-xs font-medium text-[#444B53] md:text-sm lg:text-md lg:text-black">
						{title}
						<CircleAlert className="ml-2 hidden size-3 lg:flex" color="gray" />
					</div>
				</div>
				{children}
			</div>
		</div>
	);
};
