import { CalendarDatePicker } from "@/components/shared/CalendarDatePicker";
import PhoneNumberInput from "@/components/shared/PhoneNumberInput";
import TextInput from "@/components/shared/TextInput";
import { CardHeader } from "@/components/ui/card";
import { useDatePickerLogic } from "@/hooks/useDatePickerLogic";
import useSubscriberStore from "@/stores/useSubscriberCoordsStore";
import { addYears, getToday } from "@/utils/dateUtlis";

import type { SubscriberCoordsDto } from "@repos/ecom-dtos";

import GenderSubscriberItem from "./GenderSubscriberItem";

export default function SubscriberCoordsCardContent() {
	const defaultBirthDate = useSubscriberStore((state) =>
		state.subscriber ? new Date(state.subscriber.birthDate) : new Date(),
	);

	const {
		selectedYear,
		selectedMonth,
		isDisplayedPopoverContent,
		setIsDisplayedPopoverContent,
		handleYearChange,
		handleMonthChange,
	} = useDatePickerLogic({
		defaultDate: defaultBirthDate,
		type: "birth",
	});

	return (
		<CardHeader className="mt-0 flex flex-grow items-center justify-start">
			<div className="flex w-full  flex-col items-center justify-center gap-4">
				<GenderSubscriberItem<SubscriberCoordsDto> name="gendre" />
				<div className="m-2 flex w-full flex-col items-center justify-between gap-4 md:flex-row ">
					<div className="relative w-full md:w-1/2">
						<TextInput<SubscriberCoordsDto>
							name="firstName"
							label="Nom"
							placeholder=""
							inputClassName="h-16 lg:h-11"
							enableInput={true}
							labelClassName="text-xs md:text-sm text-mobileBlack lg:text-blue-500"
						/>
					</div>
					<div className="relative w-full lg:w-1/2">
						<TextInput<SubscriberCoordsDto>
							name="lastName"
							label="Prénom"
							placeholder=""
							inputClassName="h-16 lg:h-11"
							enableInput={true}
							labelClassName="text-xs md:text-sm text-mobileBlack lg:text-blue-500"
						/>
					</div>
				</div>
				<div className=" flex w-full flex-row items-center justify-between gap-4">
					<div className="relative w-1/2">
						<CalendarDatePicker<SubscriberCoordsDto>
							name="birthDate"
							label="Date de naissance"
							placeHolder="jj/mm/aaaa"
							type="birth"
							toYear={addYears(getToday(), -18).getFullYear()}
							maxSelectableDate={getToday()}
							selectedYear={selectedYear}
							selectedMonth={selectedMonth}
							onYearChange={handleYearChange}
							onMonthChange={handleMonthChange}
							labelClassName="text-xs md:text-sm left-2 md:left-6 text-mobileBlack md:text-blue-500"
							isDisplayedPopoverContent={isDisplayedPopoverContent}
							setIsDisplayedPopoverContent={setIsDisplayedPopoverContent}
							tooltipLabel="L'âge minimum requis est de 18 ans."
						/>
					</div>
					<div className="relative w-1/2">
						<TextInput<SubscriberCoordsDto>
							name="cityOfBirth"
							label="Lieu de naissance"
							placeholder=""
							enableInput={true}
							inputClassName="h-16 lg:h-11"
							labelClassName="text-xs md:text-sm left-2 md:left-6 text-mobileBlack lg:text-blue-500"
						/>
					</div>
				</div>
				<div className="relative  w-full">
					<PhoneNumberInput<SubscriberCoordsDto>
						name="phoneNumber"
						label="Numéro de téléphone"
					/>
				</div>
				<div className="relative  w-full">
					<TextInput<SubscriberCoordsDto>
						label="Adresse e-mail"
						placeholder=""
						name="email"
						enableInput={true}
						labelClassName="bg-white text-xs md:text-sm text-mobileBlack lg:text-blue-500"
						inputClassName="h-16 lg:h-11"
					/>
				</div>
			</div>
		</CardHeader>
	);
}
