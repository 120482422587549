import plusIcon from "@/pages/3-SimulationAndGuaranteesPage/assets/plus.svg";
import { CircleAlert } from "lucide-react";

import type { BasicGuarenteeResponseDto } from "@repos/ecom-dtos";

interface ItemGuaranteeProps {
	item: BasicGuarenteeResponseDto;
	onClickItem: () => void;
}

export default function ItemGuarantee({
	item,
	onClickItem,
}: ItemGuaranteeProps) {
	return (
		<div
			onClick={onClickItem}
			className="mx-auto !mb-3 flex min-h-[8vh] w-44 cursor-pointer items-center justify-center rounded-xl border-red-100 bg-list px-2 py-3 pt-8 lg:!mb-1 lg:w-full lg:pt-3"
		>
			<div className="mx-auto flex w-full flex-col items-center justify-between gap-4 px-0.5 text-center lg:flex-row lg:gap-0 lg:px-5 lg:text-left">
				<div className="flex flex-col items-center gap-3 lg:flex-row lg:gap-0">
					<img
						className="size-10 lg:mr-3 lg:size-auto"
						src={item.activeIconUrl}
					/>
					<p className="w-full text-xs font-bold leading-4 lg:w-[85%] lg:text-sm lg:font-semibold lg:leading-5 xl:text-md">
						{item.label}
					</p>
				</div>
				<>
					{/* Desktop */}
					<img src={plusIcon} className="hidden lg:flex" />
					{/* Mobile */}
					<div className="flex items-center gap-1 lg:hidden">
						<CircleAlert className="ml-2 size-3 lg:hidden" color="gray" />
						<span className="text-xs text-blackMobile underline">
							En savoir plus
						</span>
					</div>
				</>
			</div>
		</div>
	);
}
