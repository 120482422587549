import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { ActivitiesDto } from "@repos/ecom-dtos";

// Type of store
interface ActivityStore {
	activity: ActivitiesDto | null;
	activityLabel: string | null;
	setActivity: (step: ActivitiesDto | null) => void;
	setActivityLabel: (activityLabel: string | null) => void;
	reset: () => void;
}

// Create store
const useActivityStore = create<ActivityStore>()(
	persist(
		(set) => ({
			activity: null,
			setActivity: (activity: ActivitiesDto | null) => set({ activity }),
			activityLabel: null,
			setActivityLabel: (activityLabel: string | null) =>
				set({ activityLabel }),
			reset: () => set({ activity: null }),
		}),
		{
			name: "activity-storage",
			getStorage: () => localStorage,
		},
	),
);

export default useActivityStore;
