import type { Path } from "react-hook-form";
import type { NumericFormatProps } from "react-number-format";
import {
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { cn } from "@/utils";
import { CircleAlert } from "lucide-react";
import { useFormContext } from "react-hook-form";
import { NumericFormat } from "react-number-format";

interface CustomInputProps<TFormValues> extends NumericFormatProps {
	label: string;
	placeholder: string;
	name: Path<TFormValues>;
}

export default function PriceInput<
	TFormValues extends Record<string, unknown>,
>({
	label,
	placeholder,
	name,
	...numericFormatProps
}: CustomInputProps<TFormValues>) {
	const { control } = useFormContext<TFormValues>();
	return (
		<div className="relative w-full">
			<FormField
				name={name}
				control={control}
				render={({ field, fieldState: { error } }) => (
					<FormItem>
						<FormLabel className="md:bottom-19 absolute bottom-[4.4rem] left-7 z-10 rounded-lg bg-white pl-2 pr-2 text-black md:left-6 md:text-primary">
							{label} <span className="text-red-800">*</span>
						</FormLabel>
						<div className="relative">
							<NumericFormat
								className={cn(
									"h-20 w-full rounded-full border-2 border-gray-700 text-center text-base placeholder:text-placeholder",
									error && "border-red-500",
								)}
								thousandSeparator=" "
								placeholder={placeholder}
								inputMode="decimal"
								{...field}
								value={field.value as string | number | undefined}
								onChange={() => {}}
								onValueChange={(value) => {
									field.onChange(value.floatValue || 0);
								}}
								suffix=" €"
								{...numericFormatProps}
							/>
							{error && (
								<div className="absolute inset-y-0 right-1 flex items-center pr-3">
									<CircleAlert className="size-5" color="red" />
								</div>
							)}
						</div>
						{error && (
							<div className="flex h-3 flex-row items-center">
								<FormMessage className="ml-1 text-xs font-normal" />
							</div>
						)}
					</FormItem>
				)}
			/>
		</div>
	);
}
