import { format } from "date-fns";

export const formatNumber = (str: number) => {
	return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	return format(date, "dd/MM/yyyy");
};
