import { z } from "zod";

export const TurnoverEmailInputSchema = z.object({
	turnover: z.number(),
	email: z.string(),
	phoneNumber: z.string(),
});

export type TurnoverEmailInputDto = z.infer<typeof TurnoverEmailInputSchema>;

export const ActivityEmailInputSchema = z.object({
	companyName: z.string(),
	email: z.string(),
	phoneNumber: z.string(),
	activity: z.string(),
});

export type ActivityEmailInputDto = z.infer<typeof ActivityEmailInputSchema>;
