import { useEffect } from "react";
import CustomCard from "@/components/CardComponent/CustomCard";
import { useCustomNavigate } from "@/hooks/customNavigate";
import useProgressStore from "@/stores/useProgressStore";

interface SignaturePageProps {
	stepProps: number;
}

export const SignaturePage = ({ stepProps }: SignaturePageProps) => {
	const navigate = useCustomNavigate();
	const { setStep } = useProgressStore((state) => ({
		incrementStep: state.incrementStep,
		setStep: state.setStep,
	}));

	const handleBack = () => {
		navigate("PAYMENT_METHOD");
	};

	useEffect(() => {
		setStep(stepProps);
	}, []);
	return (
		<div className="flex h-full w-screen flex-1 flex-col items-center gap-5 lg:flex-row lg:gap-0">
			<div className="mt-3 flex w-full flex-col items-start pl-8 pr-8 lg:w-5/6 ">
				<h1 className="ml-3 w-full text-lg font-bold leading-6 text-gray-800 lg:w-[60%] lg:text-2xl lg:leading-8">
					Félicitations, il ne reste que la signature!
				</h1>
				<p className="ml-3 mt-6 text-sm lg:w-[70%]">
					Partagez quelques informations pour que nous puissions mieux répondre
					à vos besoins.
				</p>
			</div>
			<div className="mr-0 flex h-full w-full flex-1 items-center justify-center pr-0 lg:mr-5 lg:pr-8">
				<CustomCard
					displayFooter={true}
					hideBackButton={false}
					className={"md:w-[85vw] md:max-w-[85vw]"}
					handleBack={handleBack}
				>
					<div className="h-full"></div>
				</CustomCard>
			</div>
		</div>
	);
};
