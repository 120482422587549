// import type {
// 	CeillingOptionsType,
// } from "@/stores/usePricingStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import MobileOptionnalGuarantee from "@/components/MobileComponents/MobileOptionnalGuarantee";
import DesktopOptionnalGuarantee from "@/pages/8-EffectiveContractDatePage/components/DesktopOptionnalGuarantee";
import usePricingStore from "@/stores/usePricingStore";
import { calculateMonthlyPriceIncludingTax } from "@/utils/calculateMonthlyPriceIncludingTax";
import { formatNumber } from "@/utils/utilsDigital";
import { useFormContext, useWatch } from "react-hook-form";

import type {
	CeillingOptionsType,
	FullGuaranteeResponseDto,
	GuaranteeInfoDto,
} from "@repos/ecom-dtos";

interface CardOptionnalGuaranteeServiceProps {
	guarantee: FullGuaranteeResponseDto;
	colorValueNoTselected: string;
	colorValueSelected: string;
}

interface FormValues {
	[key: string]: string | undefined;
}

export default function CardOptionnalGuaranteeService({
	guarantee,
	colorValueNoTselected,
	colorValueSelected,
}: CardOptionnalGuaranteeServiceProps) {
	const { ceilings } = guarantee;
	const {
		addOptionalCeiling,
		optionalCeilings,
		removeOptionalPriceCeiling,
		showAddButtons,
		setShowAddButton,
		updateOptionalPrice,
		removeOptionalCeiling,
	} = usePricingStore((state) => ({
		addOptionalCeiling: state.addOptionalCeiling,
		optionalCeilings: state.optionalCeilings,
		removeOptionalPriceCeiling: state.removeOptionalPriceCeiling,
		showAddButtons: state.showAddButtons,
		setShowAddButton: state.setShowAddButton,
		updateOptionalPrice: state.updateOptionalPrice,
		optionalPrice: state.optionalPrice,
		removeOptionalCeiling: state.removeOptionalCeiling,
	}));

	const [optionsCeilingCheckboxChecked, setOptionsCeillingCheckboxChecked] =
		useState<CeillingOptionsType[]>([]);
	const [selectedCheckboxsDetails, setSelectedCheckboxsDetails] = useState<
		boolean[]
	>(new Array(guarantee.details.length).fill(false));

	const [guaranteePrice, setGuaranteePrice] = useState<number>(
		calculateMonthlyPriceIncludingTax(
			ceilings[0]?.priceBeforeTax ?? 0,
			ceilings[0]?.taxValue ?? 0,
			ceilings[0]?.terrorismFunds ?? 0,
		),
	);

	const {
		minCeilingValue,
		maxCeilingValue,
		stepOffSet: stepValue,
	} = useMemo(() => {
		let stepOffSet = 10000;
		const minCeilingValue = Math.min(
			...ceilings.map((item) => item.ceilingValue),
		);
		const maxCeilingValue = Math.max(
			...ceilings.map((item) => item.ceilingValue),
		);

		if (ceilings.some((item) => item.ceilingValue === 10000000)) {
			stepOffSet = NaN;
		} else {
			const uniqueCeilings = Array.from(
				new Set(ceilings.map((item) => item.ceilingValue)),
			);
			const result = uniqueCeilings.reduce((a, b) => {
				while (b !== 0) {
					const temp = b;
					b = a % b;
					a = temp;
				}
				return a;
			});
			stepOffSet = result;
		}

		return { minCeilingValue, maxCeilingValue, stepOffSet };
	}, [ceilings]);
	const [sliderValue, setSliderValue] = useState([minCeilingValue]);

	const allowedValues = ceilings.map((item) => item.ceilingValue);

	useEffect(() => {
		// Find the selected guarantee in the optional pricing storage
		const selectedGuarantee = optionalCeilings.find(
			(optional) => optional.label === guarantee.label,
		);

		if (selectedGuarantee) {
			// Set the slider value to the ceiling value of the selected guarantee from storage or the default minimum value
			setSliderValue([selectedGuarantee.ceilingValue]);

			// Calculate the price after taxes based on the ceiling value of the selected guarantee from storage or the default minimum value
			calculatePriceAfterTaxe(selectedGuarantee.ceilingValue);

			// Create an updated guarantee object with the information from the selected guarantee
			const updatedGuarantee: GuaranteeInfoDto = {
				label: selectedGuarantee.label,
				type: selectedGuarantee.type,
				ceilingOptions: selectedGuarantee.ceilingOptions,
				ceilingValue: selectedGuarantee.ceilingValue,
				franchise: selectedGuarantee.franchise,
				guaranteeIcon: selectedGuarantee.guaranteeIcon,
			};

			// Set this updated guarantee in the optional pricing storage
			addOptionalCeiling(updatedGuarantee);

			// Set the selected ceiling options for this guarantee
			setOptionsCeillingCheckboxChecked(selectedGuarantee.ceilingOptions);

			// If options are defined for the ceiling of the first guarantee, set the selected details based on these options
			if (guarantee.ceilings[0]?.options) {
				let newPriceUpdated = guaranteePrice;
				setSelectedCheckboxsDetails(
					guarantee.ceilings[0].options.map((option) => {
						return selectedGuarantee.ceilingOptions.some((opt) => {
							if (opt.optionId === option.id) {
								newPriceUpdated =
									calculateMonthlyPriceIncludingTax(
										option.priceBeforeTax,
										option.taxValue,
									) + newPriceUpdated;
								setGuaranteePrice(newPriceUpdated);
								return true;
							} else {
								return false;
							}
						});
					}, setGuaranteePrice(newPriceUpdated)),
				);
			} else {
				// Otherwise, initialize the selected details as an array of false values (not selected)
				setSelectedCheckboxsDetails(
					new Array(guarantee.details.length).fill(false),
				);
			}
		}
	}, []);

	const calculatePriceAfterTaxe = (ceilingValue: number) => {
		ceilings.forEach((ceiling) => {
			if (ceiling.ceilingValue === ceilingValue) {
				const newPrice: number =
					(ceiling.priceBeforeTax + ceiling.taxValue + ceiling.terrorismFunds) /
					12;
				setGuaranteePrice(newPrice);
				const currentOption = optionalCeilings.find(
					(element) => element.label === guarantee.label,
				);

				const updatedCeilingValue = ceiling.ceilingValue;
				if (currentOption) {
					addOptionalCeiling({
						label: guarantee.label,
						type: guarantee.type,
						ceilingOptions: optionsCeilingCheckboxChecked,
						ceilingValue: updatedCeilingValue,
						franchise: guarantee.franchiseValue,
						guaranteeIcon: guarantee.activeIconUrl,
					});
					if (guarantee.label !== "Cyber") {
						updateOptionalPrice({ label: guarantee.label, price: newPrice });
					}
				}
			}
		});
	};

	const handleSliderChange = (value: number[] | undefined) => {
		if (value && value[0] !== undefined && allowedValues.includes(value[0])) {
			setSliderValue(value);

			calculatePriceAfterTaxe(value[0]);
			setValue(selectInputName, value[0]?.toString(), { shouldDirty: false });
		}
	};

	const handleCheckBoxClick = useCallback(
		(index: number, price: number, optionId: string) => {
			setSelectedCheckboxsDetails((prevSelectedDetails) => {
				const newSelectedDetails = [...prevSelectedDetails];
				newSelectedDetails[index] = !newSelectedDetails[index];
				return newSelectedDetails;
			});

			let updatedCeillingOptions: CeillingOptionsType[];
			let updatedPrice: number;
			let updatedCeilingValue: number = 0;

			if (!selectedCheckboxsDetails[index]) {
				updatedCeillingOptions = [
					...optionsCeilingCheckboxChecked,
					{ optionId },
				];
				updatedPrice = guaranteePrice + price;
				const selectedCeiling = guarantee.ceilings.find((ceiling) =>
					ceiling.options?.some((option) => option.id === optionId),
				);
				updatedCeilingValue =
					selectedCeiling?.ceilingValue || updatedCeilingValue;
			} else {
				updatedCeillingOptions = optionsCeilingCheckboxChecked.filter(
					(item) => item.optionId !== optionId,
				);
				updatedPrice = guaranteePrice - price;
			}
			const updatedGuarantee: GuaranteeInfoDto = {
				label: guarantee.label,
				type: guarantee.type,
				ceilingOptions: updatedCeillingOptions,
				ceilingValue: updatedCeilingValue,
				franchise: guarantee.franchiseValue,
				guaranteeIcon: guarantee.activeIconUrl,
			};

			addOptionalCeiling(updatedGuarantee);
			setGuaranteePrice(updatedPrice);
			updateOptionalPrice({ label: guarantee.label, price: updatedPrice });
			setOptionsCeillingCheckboxChecked(updatedCeillingOptions);
		},
		[
			selectedCheckboxsDetails,
			optionsCeilingCheckboxChecked,
			addOptionalCeiling,
			guarantee.label,
			guarantee.type,
			guaranteePrice,
		],
	);

	const addGuaranteeToFinalCard = () => {
		if (showAddButtons[guarantee.label] ?? true) {
			// Existing code to set up the new guarantee
			const updatedPrice = guaranteePrice;
			const updatedCeilingValue = sliderValue[0];
			const updatedCeillingOptions: CeillingOptionsType[] = [
				...optionsCeilingCheckboxChecked,
			];

			if (updatedCeilingValue) {
				const newGuarantee: GuaranteeInfoDto = {
					label: guarantee.label,
					type: guarantee.type,
					ceilingOptions: updatedCeillingOptions,
					ceilingValue: updatedCeilingValue,
					franchise: guarantee.franchiseValue,
					guaranteeIcon: guarantee.activeIconUrl,
				};

				// Update the optional price
				updateOptionalPrice({ label: guarantee.label, price: updatedPrice });

				// Add the new guarantee
				addOptionalCeiling(newGuarantee);
			}
			setShowAddButton(guarantee.label, false);
		} else {
			removeOptionalPriceCeiling(guarantee.label);
			removeOptionalCeiling(guarantee.label);
			setShowAddButton(guarantee.label, true);
		}
	};

	const handleCheckboxToggle = (isChecked: boolean) => {
		if (isChecked) {
			const updatedPrice = guaranteePrice;
			const updatedCeilingValue = sliderValue[0];
			const updatedCeillingOptions: CeillingOptionsType[] = [
				...optionsCeilingCheckboxChecked,
			];

			if (updatedCeilingValue) {
				const newGuarantee: GuaranteeInfoDto = {
					label: guarantee.label,
					// price: updatedPrice,
					type: guarantee.type,
					ceilingOptions: updatedCeillingOptions,
					ceilingValue: updatedCeilingValue,
					franchise: guarantee.franchiseValue,
					guaranteeIcon: guarantee.activeIconUrl,
				};
				updateOptionalPrice({ price: updatedPrice, label: guarantee.label });
				addOptionalCeiling(newGuarantee);
			}
			setShowAddButton(guarantee.label, false);
		} else {
			removeOptionalPriceCeiling(guarantee.label);
			setShowAddButton(guarantee.label, true);
		}
	};

	const optionsValues = ceilings.map((ceiling) => ({
		value: ceiling.ceilingValue.toString(),
		label: `${formatNumber(ceiling.ceilingValue)} €`,
	}));

	const { setValue } = useFormContext<FormValues>();

	const selectInputName = `optionsValuesSelect_${guarantee.label}`;

	const isSelectDisabled = optionsValues.length > 1;

	useEffect(() => {
		if (sliderValue.length) {
			setValue(selectInputName, sliderValue[0]?.toString());
		}
	}, [sliderValue, selectInputName, setValue]);

	const selectedValue: string = useWatch({ name: selectInputName });

	useEffect(() => {
		if (selectedValue) {
			const numericValue = parseInt(selectedValue, 10);
			if (sliderValue[0] !== numericValue) {
				handleSliderChange([numericValue]);
			}
			setValue(selectInputName, selectedValue);
		}
	}, [selectedValue, sliderValue]);

	useEffect(() => {
		if (showAddButtons[guarantee.label] === undefined) {
			setShowAddButton(guarantee.label, true);
		}
	}, [guarantee.label, showAddButtons, setShowAddButton]);

	const basicGuarantees = guarantee.ceilings[0]?.label?.split(",");

	return (
		<>
			<DesktopOptionnalGuarantee
				guarantee={guarantee}
				priceGuarantee={guaranteePrice}
				addGuaranteeToFinalCard={addGuaranteeToFinalCard}
				showAddButtons={showAddButtons}
				sliderValue={sliderValue}
				handleSliderChange={handleSliderChange}
				colorValueNoTselected={colorValueNoTselected}
				colorValueSelected={colorValueSelected}
				maxCeilingValue={maxCeilingValue}
				stepValue={stepValue}
				basicGuarantees={basicGuarantees}
				handleCheckBoxClick={handleCheckBoxClick}
				optionsCeilingCheckboxChecked={optionsCeilingCheckboxChecked}
			/>
			<MobileOptionnalGuarantee
				guarantee={guarantee}
				priceGuarantee={guaranteePrice}
				showAddButtons={showAddButtons}
				colorValueNoTselected={colorValueNoTselected}
				colorValueSelected={colorValueSelected}
				basicGuarantees={basicGuarantees}
				handleCheckBoxClick={handleCheckBoxClick}
				optionsCeilingCheckboxChecked={optionsCeilingCheckboxChecked}
				handleCheckboxToggle={handleCheckboxToggle}
				optionsValues={optionsValues}
				selectedValue={selectedValue}
				selectInputName={selectInputName}
				isSelectDisabled={isSelectDisabled}
			/>
		</>
	);
}
