import type { FieldValues, UseControllerProps } from "react-hook-form";
import React from "react";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/utils";
import { ChevronDown, CircleAlert } from "lucide-react";
import { useFormContext } from "react-hook-form";

interface CalendarDatePickerProps<T extends FieldValues>
	extends UseControllerProps<T> {
	label: string;
	placeHolder: string;
	labelClassName?: string;
	defaultDate?: Date;
	type?: "effective" | "birth";
	toYear?: number;
	minSelectableDate?: Date;
	maxSelectableDate?: Date;
	inputClassName?: string;
	selectedYear: number;
	selectedMonth: number;
	tooltipLabel?: string;
	isDisplayedPopoverContent?: boolean;
	setIsDisplayedPopoverContent?: React.Dispatch<React.SetStateAction<boolean>>;
	onYearChange: (year: number) => void;
	onMonthChange: (month: number) => void;
}

export function CalendarDatePicker<T extends FieldValues>({
	label,
	placeHolder,
	name,
	labelClassName,
	type = "birth",
	toYear,
	minSelectableDate,
	maxSelectableDate,
	inputClassName,
	selectedYear,
	selectedMonth,
	onYearChange,
	onMonthChange,
	isDisplayedPopoverContent,
	setIsDisplayedPopoverContent,
	tooltipLabel,
}: CalendarDatePickerProps<T>) {
	const { control } = useFormContext<T>();

	const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onYearChange(parseInt(event.target.value, 10));
	};

	const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onMonthChange(parseInt(event.target.value, 10));
	};

	const defaultMonthDate = new Date(selectedYear, selectedMonth);

	return (
		<FormField
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<FormItem className="relative">
					<FormLabel
						className={cn(
							"absolute -top-2 left-6 z-20 truncate rounded-full bg-white pl-2 pr-2 text-primary",
							labelClassName,
						)}
					>
						{label} <span className="text-red-800">*</span>
					</FormLabel>
					<Popover
						open={isDisplayedPopoverContent}
						onOpenChange={(open) => setIsDisplayedPopoverContent?.(open)}
					>
						<div className="relative">
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger asChild>
										<PopoverTrigger asChild>
											<Button
												onClick={() => setIsDisplayedPopoverContent?.(true)}
												className={cn(
													"text-primary-foreground flex h-14 w-full flex-row items-center justify-around rounded-full border-2 border-gray-700 bg-transparent px-3 py-1 text-center text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-placeholder focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
													!field.value && "text-muted-foreground",
													error && "border-red-500",
													inputClassName,
												)}
											>
												{field.value ? (
													field.value.toLocaleDateString()
												) : (
													<span className="text-sm text-gray-400">
														{placeHolder}
													</span>
												)}
												<ChevronDown className="ml-3 text-gray-400" />
											</Button>
										</PopoverTrigger>
									</TooltipTrigger>
									<TooltipContent className="bg-primary">
										<p>{tooltipLabel}</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
							{error && (
								<div className="absolute inset-y-0 right-1 flex items-center pr-3">
									<CircleAlert className="size-5" color="red" />
								</div>
							)}
						</div>
						<PopoverContent
							className="bg-white p-0 will-change-auto"
							align="end"
							side="bottom"
						>
							<div className="flex justify-center space-x-2 p-2">
								<select
									value={selectedYear}
									onChange={handleYearChange}
									className="rounded border border-gray-400 p-1"
								>
									{toYear &&
										Array.from(
											{ length: toYear - 1900 + 1 },
											(_, i) => toYear - i,
										).map((year, index) => (
											<option key={index} value={year}>
												{year}
											</option>
										))}
								</select>
								<select
									value={selectedMonth}
									onChange={handleMonthChange}
									className="rounded border border-gray-400 p-1"
								>
									{Array.from({ length: 12 }, (_, i) =>
										new Date(0, i).toLocaleString("default", { month: "long" }),
									).map((month, index) => (
										<option key={index} value={index}>
											{month}
										</option>
									))}
								</select>
							</div>
							<Calendar
								mode="single"
								selected={field.value || undefined}
								onSelect={(selectedDate) => {
									if (selectedDate) {
										field.onChange(selectedDate);
										setIsDisplayedPopoverContent?.(false);
									}
								}}
								initialFocus
								disabled={(date) =>
									date < (minSelectableDate ?? 0) ||
									date > (maxSelectableDate ?? 0)
								}
								captionLayout="buttons"
								key={`${selectedYear}-${selectedMonth}`}
								classNames={{
									day_selected:
										type === "effective"
											? "bg-prime text-black"
											: "bg-blue-500 text-white",
								}}
								defaultMonth={defaultMonthDate}
							/>
						</PopoverContent>
					</Popover>
					{error && (
						<div className="flex h-3 flex-row items-center">
							<FormMessage className="ml-1 text-xs font-normal" />
						</div>
					)}
				</FormItem>
			)}
		/>
	);
}
