import { GuaranteeCeilingType } from "@prisma/client";
import { z } from "zod";

import { FinalGuaranteeResponseSchema } from "./guarantee.dto";

export const PricingEstimationRequestSchema = z.object({
	turnoverValue: z.number(),
});

export const guaranteeCeilingSchema = z.object({
	type: z.nativeEnum(GuaranteeCeilingType),
	ceilingValue: z.number(),
	ceilingOptions: z.array(
		z.object({
			optionId: z.string(),
		}),
	),
});

export type GuaranteeCeilingDto = z.infer<typeof guaranteeCeilingSchema>;

export const guaranteeInfoSchema = guaranteeCeilingSchema.extend({
	label: z.string(),
	guaranteeIcon: z.string(),
	franchise: z.number(),
});

export type GuaranteeInfoDto = z.infer<typeof guaranteeInfoSchema>;

export const SubscriptionPricingRequestSchema = z.object({
	turnoverValue: z.number(),
	details: z.object({
		ceilings: z.array(guaranteeCeilingSchema),
	}),
});

export type SubscriptionPricingRequestDto = z.infer<
	typeof SubscriptionPricingRequestSchema
>;

export const PricingEstimationResponseSchema = z.object({
	estimatedAnnualPrimePET: z.number(),
	estimatedAnnualPrimeTax: z.number(),
	estimatedMonthlyPrimePIT: z.number(),
});

export type PricingEstimationResponseDto = z.infer<
	typeof PricingEstimationResponseSchema
>;

export const SubscriptionPricingResponseSchema = z.object({
	subscriptionAnnualPrimePET: z.string(),
	subscriptionAnnualPrimePIT: z.string(),
	subscriptionAnnualPrimeTax: z.string(),
	subscriptionTerrorismFunds: z.string(),
	subscriptionMonthlyPrimePIT: z.string(),
	chosenGuaranteesDetails: z.array(FinalGuaranteeResponseSchema),
	subscriptionAnnualManagementFee: z.string(),
	subscriptionFileFee: z.string(),
});

export type SubscriptionPricingResponseDto = z.infer<
	typeof SubscriptionPricingResponseSchema
>;

export const SubscriptionRelatedFeesSchema = z.object({
	managementFee: z.number(),
	fileFee: z.number(),
});

export type SubscriptionRelatedFeesDto = z.infer<
	typeof SubscriptionRelatedFeesSchema
>;
