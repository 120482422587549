import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { cn } from "@/utils";
import { SelectValue } from "@radix-ui/react-select";
import { CircleAlert } from "lucide-react";
import { useFormContext } from "react-hook-form";

import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select";

interface SelectInputProps<T extends FieldValues>
	extends UseControllerProps<T> {
	label: string;
	placeholder: string;
	enableSelect?: boolean;
	labelClassName?: string;
	selectClassName?: string;
	options?: { value: string; label: string }[];
}

export default function SelectInput<T extends FieldValues>({
	label,
	placeholder,
	name,
	enableSelect,
	labelClassName,
	selectClassName,
	options = [],
}: SelectInputProps<T>) {
	const { control } = useFormContext<T>();

	return (
		<FormField
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<>
					<FormItem className="relative w-full">
						<FormLabel
							className={cn(
								`absolute -top-2 left-4 z-20 truncate rounded-full bg-white pl-2 pr-2 text-xs text-blackMobile md:left-6 md:text-sm md:text-primary`,
								labelClassName,
							)}
						>
							{label} <span className="text-red-800">*</span>
						</FormLabel>
						<div className="relative">
							<Select
								{...field}
								disabled={!enableSelect}
								onValueChange={field.onChange}
							>
								<SelectTrigger
									className={cn(selectClassName, error && "border-red-500 ")}
								>
									<SelectValue placeholder={placeholder} />
								</SelectTrigger>
								<SelectContent className="bg-white">
									{options.map((option) => (
										<SelectItem key={option.value} value={option.value}>
											{option.label}
										</SelectItem>
									))}
								</SelectContent>
							</Select>

							{error && (
								<div className="absolute inset-y-0 right-1 flex items-center pr-3">
									<CircleAlert className="size-5" color="red" />
								</div>
							)}
						</div>

						{error && (
							<div className="flex h-3 flex-row items-center">
								<FormMessage className="ml-1 text-xs font-normal" />
							</div>
						)}
					</FormItem>
				</>
			)}
		/>
	);
}
