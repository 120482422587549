import type { ClassValue } from "clsx";
import { cn } from "@/utils";

interface RecapInfoCardProps {
	children: React.ReactNode;
	className?: ClassValue;
}

export const RecapInfoCard = ({ children, className }: RecapInfoCardProps) => {
	return (
		<div
			className={cn(
				"mb-6 w-full rounded-xl px-8 pb-10 pt-6 lg:mb-0 lg:bg-white lg:px-14",
				className,
			)}
		>
			{children}
		</div>
	);
};
