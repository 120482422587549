// import type { CeillingOptionsType } from "@/stores/usePricingStore";
import { cn } from "@/utils";
import { calculateMonthlyPriceIncludingTax } from "@/utils/calculateMonthlyPriceIncludingTax";
import { formatNumber } from "@/utils/utilsDigital";
import { CircleAlert } from "lucide-react";

import type {
	CeillingOptionsType,
	FullGuaranteeResponseDto,
} from "@repos/ecom-dtos";

import { CheckBoxWithLabel } from "../../../components/shared/CheckBoxWithLabel";
import { Button } from "../../../components/ui/button";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../../../components/ui/card";
import { Label } from "../../../components/ui/label";
import {
	SliderRange,
	SliderRoot,
	SliderThumb,
	SliderTrack,
} from "../../../components/ui/slider";

interface DesktopOptionnalGuaranteeProps {
	guarantee: FullGuaranteeResponseDto;
	priceGuarantee: number;
	addGuaranteeToFinalCard: () => void;
	showAddButtons: Record<string, boolean | undefined>;
	sliderValue: number[];
	handleSliderChange: (value: number[] | undefined) => void;
	colorValueNoTselected: string;
	colorValueSelected: string;
	maxCeilingValue: number;
	stepValue: number;
	basicGuarantees: string[] | undefined;
	handleCheckBoxClick: (index: number, price: number, optionId: string) => void;
	optionsCeilingCheckboxChecked: CeillingOptionsType[];
}

const DesktopOptionnalGuarantee = ({
	guarantee,
	priceGuarantee,
	addGuaranteeToFinalCard,
	showAddButtons,
	sliderValue,
	maxCeilingValue,
	stepValue,
	handleSliderChange,
	colorValueNoTselected,
	colorValueSelected,
	basicGuarantees,
	handleCheckBoxClick,
	optionsCeilingCheckboxChecked,
}: DesktopOptionnalGuaranteeProps) => {
	const { ceilings } = guarantee;

	return (
		<Card className="relative m-2 mb-5 hidden w-full border-none bg-white px-7 lg:block">
			<CardHeader className="flex flex-row items-center justify-between border-b-[0.05rem] border-gray-400">
				<div className="flex flex-row items-center">
					<img src={guarantee.inactiveIconUrl} className="mr-2" />
					<CardTitle className="w-5/6 text-xs font-bold text-black xl:text-sm">
						{guarantee.label}
					</CardTitle>
				</div>
				<div className="flex w-96 flex-row items-center justify-end">
					<div className="m-2 text-sm font-bold text-primary xl:text-md">
						+{priceGuarantee.toFixed(2)} €/mois
					</div>
					<Button
						type="button"
						className={cn("h-14 w-28 rounded-xl")}
						onClick={addGuaranteeToFinalCard}
						variant={"suivant"}
					>
						{showAddButtons[guarantee.label] ? "Ajouter" : "Supprimer"}
					</Button>
				</div>
			</CardHeader>
			<CardContent className="relative flex w-full flex-row items-center justify-end gap-x-4 overflow-hidden py-16 md:flex-row">
				<SliderRoot
					className={cn(
						"mt-5 min-w-32 flex-[0.7]",
						guarantee.ceilings.length > 1 && "cursor-pointer",
					)}
					defaultValue={sliderValue}
					max={
						ceilings.length > 1 ? maxCeilingValue : ceilings[0]?.ceilingValue
					}
					step={stepValue ? stepValue : undefined}
					min={0}
					onValueChange={handleSliderChange}
					value={sliderValue}
				>
					{/* 
						Use inline styles for Border color as it's dynamic based on `colorValueSelected` which might not be available at build time for Tailwind CSS.
					*/}
					<SliderTrack
						style={{
							backgroundColor: `hsl(var(--${colorValueNoTselected}))`,
						}}
					>
						<SliderRange
							style={{
								backgroundColor: `hsl(var(--${colorValueSelected}))`,
							}}
						/>
					</SliderTrack>
					<SliderThumb
						className={`relative block h-4 w-4 cursor-pointer rounded-full border-2 border-blue-500 bg-[#ffffff] shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50`}
					>
						<Label
							className={cn(
								"absolute left-1/2 top-6 line-clamp-1 w-[125px] -translate-x-1/2 transform bg-transparent text-center text-[18px] text-[#878787]",
								guarantee.ceilings.length > 1 && "cursor-pointer",
							)}
						>
							{sliderValue[0] && formatNumber(sliderValue[0])} €
						</Label>
						{guarantee.ceilings.length > 1 ? (
							<div className="absolute bottom-6 left-5 flex w-[9rem]  -translate-x-1/2 transform flex-row items-center bg-transparent">
								<Label className="mr-1 line-clamp-1  text-center text-xs text-gray-600">
									Limite de garantie
								</Label>
								<CircleAlert className="size-3" color="gray" />
							</div>
						) : (
							<div
								className={cn(
									"absolute -left-[2rem] bottom-8 flex w-[13rem]  -translate-x-1/2 transform flex-row items-center rounded-2xl bg-transparent p-2 ",
								)}
								style={{
									backgroundColor: `hsl(var(--${colorValueSelected}))`,
								}}
							>
								<Label className="mr-1 line-clamp-2  text-center text-xs text-gray-600">
									La limite pour cette garantie est fixée à{" "}
									{` ${guarantee.ceilings[0] && formatNumber(guarantee.ceilings[0].ceilingValue)}`}{" "}
									€
								</Label>
								{/* 
									Use inline styles for Border color as it's dynamic based on `colorValueSelected` which might not be available at build time for Tailwind CSS.
								*/}
								<div
									className={cn(
										"absolute -bottom-[20%] right-[27%]  border-l-[10px] border-r-[10px] border-t-[15px] border-l-transparent border-r-transparent",
									)}
									style={{
										borderTopColor: `hsl(var(--${colorValueSelected}))`,
									}}
								/>
							</div>
						)}
					</SliderThumb>
				</SliderRoot>
				<div
					className={cn(
						"relative ml-10 flex min-w-20 flex-[0.2] justify-center rounded-full px-3 py-3",
					)}
					style={{
						backgroundColor: `hsl(var(--${colorValueSelected}))`,
					}}
				>
					<Label className="absolute -top-2 left-3 rounded-xl bg-white px-1 text-xs">
						Franchise
					</Label>
					<div className="text-sm font-bold text-black">
						{guarantee.franchiseValue} €
					</div>
				</div>
			</CardContent>
			{guarantee.details.length > 0 && (
				<CardFooter className="mt-3 flex flex-col">
					<>
						{basicGuarantees &&
							basicGuarantees.map((option, indx) => (
								<CheckBoxWithLabel
									key={indx}
									label={option}
									inputRadioGroup="size-4 cursor-not-allowed"
									onClickCheckBox={() => {}}
									isChecked={true}
								/>
							))}
						{guarantee.ceilings.map((ceilling) =>
							ceilling.options?.map((option, index) => {
								const priceMonthly = calculateMonthlyPriceIncludingTax(
									option.priceBeforeTax,
									option.taxValue,
								);
								const optionsId = option.id;
								return (
									<div
										key={index}
										className="flex w-full flex-row items-center justify-between "
									>
										<CheckBoxWithLabel
											label={option.label}
											inputRadioGroup="size-4"
											checkedBgClass="bg-blue-500"
											onClickCheckBox={() =>
												handleCheckBoxClick(index, priceMonthly, optionsId)
											}
											isChecked={optionsCeilingCheckboxChecked.some(
												(opt) => opt.optionId === option.id,
											)}
											disabledOptions={showAddButtons[guarantee.label]}
										/>
										<div className="w-[10rem] text-end text-xs">
											{priceMonthly} €/mois
										</div>
									</div>
								);
							}),
						)}
					</>
				</CardFooter>
			)}
		</Card>
	);
};

export default DesktopOptionnalGuarantee;
