import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import {
	SliderRange,
	SliderRoot,
	SliderThumb,
	SliderTrack,
} from "@/components/ui/slider";
import { cn } from "@/utils";
import { formatNumber } from "@/utils/utilsDigital";
import { CircleAlert } from "lucide-react";

import type { FullGuaranteeResponseDto } from "@repos/ecom-dtos";

interface CardBasicGuaranteeServiceProps {
	displayValue: number[];
	handleValueOnSlide?: (value: number[]) => void;
	colorValueSelected: string;
	colorValueNoTselected: string;
	guarantee: FullGuaranteeResponseDto;
}

export default function CardBasicGuaranteeService({
	displayValue,
	handleValueOnSlide,
	colorValueNoTselected,
	colorValueSelected,
	guarantee,
}: CardBasicGuaranteeServiceProps) {
	return (
		<Card className="relative m-2 hidden w-full overflow-hidden border-none bg-white px-9 lg:!block">
			<CardHeader className="flex flex-row items-center gap-2 border-b-[0.05rem] border-gray-400">
				<img src={guarantee.activeIconUrl} className="mr-2" />
				<CardTitle className="w-full text-sm font-bold text-primary xl:text-md">
					{guarantee.label}
				</CardTitle>
			</CardHeader>
			<CardContent className="relative flex w-full flex-row items-center justify-end gap-x-4 overflow-hidden py-14 md:flex-row">
				<SliderRoot
					className="min-w-32 flex-[0.7] cursor-pointer"
					defaultValue={displayValue}
					max={300000}
					step={100000}
					min={100000}
					onValueChange={handleValueOnSlide}
					value={displayValue}
				>
					{/* 
						Use inline styles for Border color as it's dynamic based on `colorValueSelected` which might not be available at build time for Tailwind CSS.
					*/}
					<SliderTrack
						style={{
							backgroundColor: `hsl(var(--${colorValueNoTselected}))`,
						}}
					>
						<SliderRange
							style={{
								backgroundColor: `hsl(var(--${colorValueSelected}))`,
							}}
						/>
					</SliderTrack>
					<SliderThumb
						className={`relative block h-4 w-4 rounded-full border-2 border-blue-500 bg-[#ffffff] shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50`}
					>
						<Label className="absolute left-1/2 top-6 line-clamp-1 w-[100px] -translate-x-1/2 transform cursor-pointer bg-transparent text-center text-[18px] text-[#878787]">
							{displayValue && formatNumber(displayValue[0]!)} €
						</Label>
						<div className="absolute bottom-6 left-5 flex w-[9rem]  -translate-x-1/2 transform flex-row items-center bg-transparent">
							<Label className="mr-1 line-clamp-1  text-center text-xs text-gray-600">
								Limite de garantie
							</Label>
							<CircleAlert className="size-3" color="gray" />
						</div>
					</SliderThumb>
				</SliderRoot>
				<div
					className={cn(
						"relative ml-10 flex min-w-20 flex-[0.2] justify-center rounded-full px-3 py-3",
					)}
					style={{
						backgroundColor: `hsl(var(--${colorValueSelected}))`,
					}}
				>
					<Label className="absolute -top-2 left-3 rounded-xl bg-white px-1 text-xs">
						Franchise
					</Label>
					<div className="text-sm font-bold text-black">
						{guarantee.franchiseValue} €
					</div>
				</div>
			</CardContent>
		</Card>
	);
}
