import { z } from "zod";

export const ActivitiesResponseSchema = z.object({
	activities: z.array(
		z.object({
			name: z.string(),
			label: z.string(),
			inactiveIconUrl: z.string(),
			activeIconUrl: z.string(),
			order: z.number(),
		}),
	),
});

export type ActiviesResponseDto = z.infer<typeof ActivitiesResponseSchema>;

export const AcitivitySchema = z.object({
	name: z.string(),
	label: z.string(),
	inactiveIconUrl: z.string(),
	activeIconUrl: z.string(),
	order: z.number(),
});

export type AcitivityType = z.infer<typeof AcitivitySchema>;
