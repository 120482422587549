import type { Route } from "@/routes/constants";
import { routes } from "@/routes/constants";
import { useNavigate } from "react-router-dom";

type StateType = {
	[key: string]: string | number | boolean;
};
export const useCustomNavigate = () => {
	const navigate = useNavigate();

	return (route: keyof typeof Route, state?: StateType) => {
		navigate(routes[route].path, { state: { ...state, isSafe: true } });
		window.scroll(0, 0);
	};
};
