import warningIcon from "@/assets/warningIcon.svg";
import PhoneNumberInput from "@/components/shared/PhoneNumberInput";
import { Alert, AlertTitle } from "@/components/ui/alert";
import useCustomSubscriptionStore, {
	SubscriberTypeEnum,
} from "@/stores/useCustomSubscriptionStore";
import useTurnoverStore from "@/stores/useTurnoverStore";
import { formatNumber } from "@/utils/utilsDigital";

import type { ProspectDto } from "@repos/ecom-dtos";

import TextInput from "../../../components/shared/TextInput";
import { CardContent, CardDescription } from "../../../components/ui/card";

export default function CustomSubscriptionContentCard() {
	const { turnover } = useTurnoverStore();
	const { subscriberType, alertMessage, alertTitle } =
		useCustomSubscriptionStore((state) => ({
			subscriberType: state.subscriberType,
			alertTitle: state.alertTitle,
			alertMessage: state.alertMessage,
		}));

	return (
		<>
			<CardContent className="mt-0 flex flex-col items-start justify-center pt-6 lg:mt-6">
				{subscriberType === SubscriberTypeEnum.HighTurnover && (
					<CardDescription className="mb-4 hidden self-center text-sm text-black lg:flex xl:text-base">
						Votre chiffre d&apos;affaires est :{" "}
						<span className="font-bold text-primary">
							{turnover && formatNumber(turnover)} €
						</span>
					</CardDescription>
				)}

				<Alert className="flex h-auto flex-col items-center gap-2 border-0 bg-alert px-6 text-base font-bold  lg:h-16 lg:flex-row lg:gap-0 lg:px-8 xl:pr-7">
					<img src={warningIcon} className="mr-2" />
					<AlertTitle className="text-center text-sm font-bold leading-6 lg:text-left lg:text-base ">
						{alertTitle}
					</AlertTitle>
				</Alert>
				<CardDescription className="mb-8 mt-8 text-center text-xs text-black md:text-sm lg:mb-4 lg:mt-2 lg:text-left xl:mb-16">
					{alertMessage}
				</CardDescription>
				<div className="flex w-full flex-col items-center justify-center">
					{subscriberType === SubscriberTypeEnum.LowTurnover && (
						<div className="relative m-3 w-full">
							<TextInput<ProspectDto>
								label="Activité"
								placeholder=""
								name="activity"
								enableInput={true}
								labelClassName="bg-white text-mobileBlack lg:text-blue-500 text-xs lg:text-sm"
								inputClassName="h-16 lg:h-11 xl:h-16"
							/>
						</div>
					)}
					<div className="relative m-3 w-full">
						<PhoneNumberInput<ProspectDto>
							name="phoneNumber"
							label="Numéro de téléphone"
						/>
					</div>
					<div className="relative m-3 w-full">
						<TextInput<ProspectDto>
							label="Adresse e-mail"
							placeholder=""
							name="email"
							enableInput={true}
							labelClassName="bg-white text-mobileBlack lg:text-blue-500 text-xs lg:text-sm"
							inputClassName="h-16 lg:h-11 xl:h-16"
						/>
					</div>
				</div>
			</CardContent>
		</>
	);
}
