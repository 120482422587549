import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { ProspectDto } from "@repos/ecom-dtos";

// Type of store
interface UserState {
	prospect: ProspectDto | null;
	setProspect: (prospect: ProspectDto | null) => void;
	clearProspect: () => void;
}

// Create store
const useProspectStore = create<UserState>()(
	persist(
		(set) => ({
			prospect: null,
			setProspect: (prospect: ProspectDto | null) => set({ prospect }),
			clearProspect: () => set({ prospect: null }),
		}),
		{
			name: "prospect-storage",
			getStorage: () => localStorage,
		},
	),
);

export default useProspectStore;
