import type { ClassValue } from "clsx";
import { CardHeader } from "@/components/ui/card";
import { cn } from "@/utils";

interface CardHeaderComponentProps {
	className?: ClassValue;
	headerCardLabel: string;
	headerCardImage: string;
}

export default function CustomCardHeader({
	className,
	headerCardLabel,
	headerCardImage,
}: CardHeaderComponentProps) {
	return (
		<CardHeader
			className={cn(
				"relative z-10 flex-row place-items-start items-center gap-5 border-b border-black border-opacity-10 p-0 pb-4 lg:flex",
				className,
			)}
		>
			<img src={headerCardImage} className="size-16" />
			<h2 className="text-md font-bold text-gray-400 lg:text-xl">
				{headerCardLabel}
			</h2>
		</CardHeader>
	);
}
