import { create } from "zustand";
import { persist } from "zustand/middleware";

// Type of store
interface ProgressState {
	currentStep: number;
	numberOfTotalStep: number;
	firstStepOfFirstProgressBar: number;
	lastStepOfFirstProgreesBar: number;
	lastSubmittedStep: number | null;
	setStep: (step: number) => void;
	incrementStep: () => void;
	decrementStep: () => void;
	resetStep: () => void;
	setLastSubmittedStep: (step: number) => void;
	resetLastSubmittedStep: () => void;
}

// Create store
const useProgressStore = create<ProgressState>()(
	persist(
		(set) => ({
			currentStep: 1,
			numberOfTotalStep: 12, // all pages
			firstStepOfFirstProgressBar: 1,
			lastStepOfFirstProgreesBar: 7,
			lastSubmittedStep: null,
			setStep: (step: number) => set({ currentStep: step }),
			incrementStep: () =>
				set((state) => ({
					currentStep: Math.min(state.currentStep + 1, state.numberOfTotalStep),
				})),
			decrementStep: () =>
				set((state) => ({
					currentStep: Math.min(state.currentStep - 1, state.numberOfTotalStep),
				})),
			resetStep: () => set({ currentStep: 1 }),
			setLastSubmittedStep: (step: number) => set({ lastSubmittedStep: step }),
			resetLastSubmittedStep: () => set({ lastSubmittedStep: null }),
		}),
		{
			name: "progress-storage",
			getStorage: () => localStorage,
		},
	),
);

export default useProgressStore;
