import testimonialWomenImage from "@/assets/testimonialWomenImage.svg";
import { TestimonialCard } from "@/components/shared/TestimonialCard";
import coordsIcon from "@/pages/6-SubscriberCoordsPage/assets/coords.svg";

export const LeftPageCoordsSubscriber = () => {
	return (
		<div className="mt-3 flex w-full flex-col items-start pl-8 pr-8 lg:w-5/6 ">
			<div className="flex flex-row place-items-start items-center">
				<img src={coordsIcon} className="md:w-18 lg:24 w-14" />
				<p className="ml-3 w-full text-lg font-bold text-gray-800 md:leading-6 lg:w-[70%] lg:text-2xl lg:leading-9">
					Nous aimerions faire votre connaissance
				</p>
			</div>
			<p className="mb-5 mt-2 hidden text-sm lg:flex lg:w-[70%]">
				Partagez quelques informations pour que nous puissions mieux répondre à
				vos besoins.
			</p>
			<TestimonialCard picture={testimonialWomenImage} name="Emilie S." />
		</div>
	);
};
