import { MobileFooter } from "@/components/MobileComponents/MobileFooter";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useCustomNavigate } from "@/hooks/customNavigate";
import useActivityStore from "@/stores/useActivityStore";
import useCompanyStore from "@/stores/useCompanyStore";
import useProgressStore from "@/stores/useProgressStore";
import useStatementStore from "@/stores/useStatementStore";
import useSubscriberStore from "@/stores/useSubscriberCoordsStore";
import useTurnoverStore from "@/stores/useTurnoverStore";
import { trpc } from "@/utils";

export default function FooterStatementButton() {
	const { toast } = useToast();
	const navigate = useCustomNavigate();

	const { setLastSubmittedStep, currentStep } = useProgressStore((state) => ({
		setLastSubmittedStep: state.setLastSubmittedStep,
		currentStep: state.currentStep,
	}));

	const { acceptAccuracyInformation, confirmAcceptUseCases } =
		useStatementStore((state) => ({
			acceptAccuracyInformation: state.acceptAccuracyInformation,
			confirmAcceptUseCases: state.confirmAcceptUseCases,
		}));

	const { subscriber } = useSubscriberStore((state) => ({
		subscriber: state.subscriber,
	}));

	const { activity } = useActivityStore((state) => ({
		activity: state.activity,
	}));
	const { turnover } = useTurnoverStore((state) => ({
		turnover: state.turnover,
	}));
	const { company } = useCompanyStore((state) => ({
		company: state.company,
	}));
	if (!turnover) {
		navigate("TURNOVER");
		return;
	}

	const handleAddNewSubscription =
		trpc.subscription.addNewSubscription.useMutation({
			onSuccess: () => {
				navigate("PAYMENT_METHOD");
				setLastSubmittedStep(currentStep);
			},
			onError: () => {
				toast({
					description:
						"Une erreur interne est survenue, veuillez réessayer plus tard.",
					duration: 2000,
					variant: "destructive",
					style: {
						color: "white",
					},
				});
			},
		});

	const handleSubscribe = async () => {
		// const selectedActivity = company.activityField?.selectedActivity;
		if (acceptAccuracyInformation && confirmAcceptUseCases) {
			if (activity?.selectedActivity && subscriber && company) {
				await handleAddNewSubscription.mutateAsync({
					company: {
						activityField: activity.selectedActivity,
						commune: company.commune,
						legalStatusCode: company.legalStatusCode,
						legalStatusLabel: company.legalStatusLabel,
						mainActivityCode: company.mainActivityCode,
						mainActivityLabel: company.mainActivityLabel,
						name: company.name,
						sirenNumber: company.sirenNumber,
						siret: company.siret,
						turnover: turnover,
					},
					subscriber: {
						birthDate: subscriber.birthDate.toString(),
						cityOfBirth: subscriber.cityOfBirth,
						email: subscriber.email,
						firstName: subscriber.firstName,
						gendre: subscriber.gendre,
						lastName: subscriber.lastName,
						phoneNumber: subscriber.phoneNumber,
					},
				});
			} else {
				toast({
					description:
						"Une erreur interne est survenue, veuillez réessayer plus tard.",
					duration: 2000,
					variant: "destructive",
					style: {
						color: "white",
					},
				});
			}
		} else {
			toast({
				description: "Veuillez accepter les déclarations avant de continuer.",
				duration: 2000,
				variant: "destructive",
				style: {
					color: "white",
				},
			});
		}
	};

	const handleBack = () => {
		navigate("SUMMARIZE_OFFER");
	};

	return (
		<>
			{/* Desktop Footer Section */}
			<div className="mx-auto mt-8 hidden w-full flex-row items-center justify-end gap-5 px-11 lg:flex lg:w-5/6">
				<Button onClick={handleBack} variant={"retour"}>
					Retour
				</Button>
				<Button onClick={handleSubscribe} variant={"suivant"}>
					Souscrire
				</Button>
			</div>

			{/* Mobile Footer Section */}
			<MobileFooter onButtonClick={handleSubscribe} label="Confirmer" />
		</>
	);
}
