import { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useCustomNavigate } from "@/hooks/customNavigate";
import useProgressStore from "@/stores/useProgressStore";

interface PaymentMethodPageProps {
	stepProps: number;
}

export const PaymentMethodPage = ({ stepProps }: PaymentMethodPageProps) => {
	const navigate = useCustomNavigate();
	const { incrementStep, setStep } = useProgressStore((state) => ({
		incrementStep: state.incrementStep,
		setStep: state.setStep,
	}));
	const handleNext = () => {
		incrementStep();
		navigate("SIGNATURE");
	};

	useEffect(() => {
		setStep(stepProps);
	}, []);
	return (
		<div className="flex flex-col items-center justify-center">
			<div className="flex flex-col items-center justify-center gap-4">
				<p className="text-center text-2xl font-bold">
					Choisissez votre méthode de paiement
				</p>
				<Button size={"lg"} onClick={handleNext}>
					suivant
				</Button>
			</div>
		</div>
	);
};
