import { useMemo } from "react";
import usePricingStore from "@/stores/usePricingStore";

export const useMonthlyPrime = () => {
	const { optionalCeilings, basicCeilings, optionalPrice, basicPrice } =
		usePricingStore((state) => ({
			optionalCeilings: state.optionalCeilings,
			basicCeilings: state.basicCeilings,
			optionalPrice: state.optionalPrice,
			basicPrice: state.basicPrice,
		}));

	const { totalOptionalPrice, totalBasicPrice, monthlyPrimeTotal } =
		useMemo(() => {
			// Calculate the sum of numbers inside optionalPrice
			const sumOptionalPrice = Array.isArray(optionalPrice)
				? optionalPrice.reduce(
						(sum, value) =>
							sum + (typeof value.price === "number" ? value.price : 0),
						0,
					)
				: 0;

			const totalOptionalPrice = sumOptionalPrice
				? Math.round(sumOptionalPrice * 100) / 100
				: 0;

			const totalBasicPrice = basicPrice
				? Math.round(basicPrice * 100) / 100
				: 0;
			const monthlyPrimeTotal = totalBasicPrice + totalOptionalPrice;

			return {
				totalOptionalPrice,
				totalBasicPrice,
				monthlyPrimeTotal,
			};
		}, [optionalCeilings, basicCeilings, optionalPrice, basicPrice]);

	return {
		totalOptionalGuaranteesPrice: totalOptionalPrice,
		totalBasicGuaranteePrice: totalBasicPrice,
		monthlyPrimeTotal,
	};
};
