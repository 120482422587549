import { Badge } from "@/components/ui/badge";
import { cn } from "@/utils";

import type { AcitivityType } from "@repos/ecom-dtos";

export const ActivityItem = ({
	onSelect,
	selectedActivity,
	activity,
}: {
	onSelect: () => void;
	selectedActivity: string;
	activity: AcitivityType;
}) => {
	return (
		<Badge
			onClick={onSelect}
			variant={"outline"}
			className={cn(
				`h-full min-w-20 flex-shrink-0 cursor-pointer flex-col text-ellipsis rounded-xl border-[1px] border-gray-300 px-5 py-4 text-center font-semibold lg:h-16 lg:w-[15vw] lg:flex-row lg:rounded-full lg:border-gray-400 lg:py-2 lg:text-left xl:h-20`,
				selectedActivity === activity.name ? "bg-yellow" : "bg-white",
			)}
		>
			<img
				className="mb-3 mr-0 h-full w-6 md:w-6 lg:mb-0 lg:mr-4 lg:w-6"
				src={
					selectedActivity === activity.name
						? activity.activeIconUrl
						: activity.inactiveIconUrl
				}
			/>
			{activity.label}
		</Badge>
	);
};
