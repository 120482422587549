import LabelStep from "./LabelStep";
import NumberStep from "./NumberStep";

interface Step {
	value: number;
	classNameBg: string;
	classNameTxt: string;
	label: string;
}

interface ItemStepperProps {
	step: Step;
}

export default function ItemStepper({ step }: ItemStepperProps) {
	return (
		<div className="hidden flex-row items-center lg:flex ">
			<NumberStep step={step} />
			<LabelStep step={step} />
		</div>
	);
}
