import { CheckBoxWithLabel } from "@/components/shared/CheckBoxWithLabel";
import { Card } from "@/components/ui/card";
import useStatementStore from "@/stores/useStatementStore";

export default function CardCheckboxStatement() {
	const {
		acceptAccuracyInformation,
		setAcceptAccuracyInformation,
		confirmAcceptUseCases,
		setConfirmAcceptUseCases,
	} = useStatementStore((state) => ({
		acceptAccuracyInformation: state.acceptAccuracyInformation,
		setAcceptAccuracyInformation: state.setAcceptAccuracyInformation,
		confirmAcceptUseCases: state.confirmAcceptUseCases,
		setConfirmAcceptUseCases: state.setConfirmAcceptUseCases,
	}));
	const toggleAcceptAccuracyInformation = () => {
		setAcceptAccuracyInformation(!acceptAccuracyInformation);
	};

	const toggleConfirmAcceptUseCases = () => {
		setConfirmAcceptUseCases(!confirmAcceptUseCases);
	};
	return (
		<Card className="my-3 w-full border-2 border-none border-gray-300 bg-transparent px-0 py-0 shadow-none lg:px-8 lg:py-5 lg:shadow-sm">
			<CheckBoxWithLabel
				isChecked={acceptAccuracyInformation}
				onClickCheckBox={toggleAcceptAccuracyInformation}
				label="Je certifie l'exactitude des informations fournies ci-dessus dans mes déclarations sur l'honneur."
				inputRadioGroup="h-5 rounded-md lg:rounded-full border-[#A5A5A5] lg:border-blue-500"
				id="declaration"
			/>
			<CheckBoxWithLabel
				isChecked={confirmAcceptUseCases}
				onClickCheckBox={toggleConfirmAcceptUseCases}
				label="Je confirme avoir pris connaissance et accepté les conditions Générales (CG) ainsi que les informations sur le Produit d'Assurance Distribué (IPID)"
				inputRadioGroup="h-5 rounded-md lg:rounded-full border-[#A5A5A5] lg:border-blue-500"
				id="confirmation"
			/>
		</Card>
	);
}
