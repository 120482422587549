import { ContactArrowIndicator } from "@/components/shared/ContactArrowIndicator";
import { Button } from "@/components/ui/button";
import { useCustomNavigate } from "@/hooks/customNavigate";
import { useResetStores } from "@/hooks/useResetStore";
import useProgressStore from "@/stores/useProgressStore";
import { Navigate } from "react-router-dom";

export const BackupSessionPage = () => {
	const navigate = useCustomNavigate();

	const resetStores = useResetStores();

	const { lastSubmittedStep } = useProgressStore();

	const onResumeProgress = () => {
		const nextStep = lastSubmittedStep ? lastSubmittedStep + 1 : 1;

		if (!nextStep) {
			return <>{navigate("TURNOVER")}</>;
		}

		if (!lastSubmittedStep) {
			return <>{navigate("TURNOVER")}</>;
		}

		switch (nextStep) {
			case 1:
				return navigate("TURNOVER");
			case 2:
				return navigate("SIMULATION_GUARANTEE");
			case 3:
				return navigate("COMPANY_SIREN");
			case 4:
				return navigate("COMPANY_ACTIVITY");
			case 5:
				return navigate("SUBSCRIBER_COORDS");
			case 6:
				return navigate("SUBSCRIBER_INFOS");
			case 7:
				return navigate("EFFECTIVE_CONTRACT_DATE");
			case 8:
				return navigate("SUMMARIZE_OFFER");
			case 9:
				return navigate("OFFER_REQUIREMENTS");
			default:
				return navigate("TURNOVER");
		}
	};

	const hasResumeData = lastSubmittedStep !== null;
	if (!hasResumeData) {
		return <Navigate to={"/"} />;
	} else
		return (
			<>
				<div className="flex h-[60svh] flex-col items-center justify-center lg:h-full">
					<div className="flex max-w-screen-lg flex-col gap-16 px-4 md:px-0">
						<h1 className="text-center text-lg font-bold leading-6 text-black md:text-3xl md:leading-10">
							Souhaitez-vous poursuivre votre <br /> souscription ou bien
							souscrire à nouveau ?
						</h1>
						<div className="flex flex-row justify-center gap-2 md:flex-row md:gap-7">
							<Button
								variant="retour"
								className="mb-4 w-40 md:mb-0 md:w-52"
								onClick={(e) => {
									e.preventDefault();
									resetStores();
									navigate("TURNOVER");
								}}
							>
								Souscrire à nouveau
							</Button>
							<Button
								variant="suivant"
								className="w-40 md:w-52"
								onClick={onResumeProgress}
							>
								Poursuivre
							</Button>
						</div>
					</div>
				</div>

				<ContactArrowIndicator />
			</>
		);
};
