import type { FieldValues, UseControllerProps } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";
import errorIcon from "@/assets/error.svg";
import {
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import femaleIcon from "@/pages/6-SubscriberCoordsPage/assets/female.svg";
import maleIcon from "@/pages/6-SubscriberCoordsPage/assets/male.svg";
import { cn } from "@/utils";
import { SubscriberGender } from "@prisma/client";
import { useFormContext } from "react-hook-form";

import type { GenderDto } from "@repos/ecom-dtos";

type GenderOptionsType = {
	title: string;
	icon: string;
	value: GenderDto;
}[];

interface GenderSubscriberItemProps<T extends FieldValues>
	extends UseControllerProps<T> {}
const genderOptions: GenderOptionsType = [
	{ title: "Homme", icon: maleIcon, value: SubscriberGender.Male },
	{ title: "Femme", icon: femaleIcon, value: SubscriberGender.Female },
];

export default function GenderSubscriberItem<T extends FieldValues>({
	name,
}: GenderSubscriberItemProps<T>) {
	const { control } = useFormContext<T>();
	return (
		<FormField
			control={control}
			name={name}
			render={({ field, fieldState: { error } }) => (
				<FormItem className="flex w-full flex-col items-center justify-around lg:w-3/4">
					<div className="flex  w-full items-center  justify-evenly">
						<FormControl>
							<RadioGroup
								onValueChange={field.onChange}
								{...field}
								className="flex w-full items-center justify-center lg:gap-2"
							>
								{genderOptions.map((item, index) => (
									<Fragment key={index}>
										{/* Desktop version */}
										<div className="hidden items-center gap-2 lg:flex">
											<RadioGroupItem
												id={item.value}
												className={cn(
													`border border-blue-500`,
													field.value === item.value
														? "bg-blue-500 text-white"
														: "bg-white text-white",
												)}
												value={item.value}
											/>
											<img src={item.icon} className="h-8 w-8" />
											<Label
												htmlFor={item.value}
												className="mr-4 bg-transparent text-black"
											>
												{item.title}
											</Label>
										</div>

										{/* Mobile version */}
										<div
											className={cn(
												`flex w-full items-center justify-center gap-2 rounded-full border border-[#C8C8C8] px-4 py-4 lg:hidden`,
												field.value === item.value
													? "border-blue-500 text-white"
													: "border-[#C8C8C8] text-white",
											)}
										>
											<img src={item.icon} className="h-6 w-5" />
											<Label
												htmlFor={item.value}
												className="mr-4 bg-transparent text-black"
											>
												{item.title}
											</Label>
										</div>
									</Fragment>
								))}
							</RadioGroup>
						</FormControl>
					</div>
					{error && (
						<div className="flex h-3 flex-row items-center">
							<img src={errorIcon} />
							<FormMessage className="ml-1 text-xs font-normal" />
						</div>
					)}
				</FormItem>
			)}
		/>
	);
}
