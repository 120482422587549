import { Button } from "@/components/ui/button";

export const LeftNotFoundContent = () => {
	return (
		<div className="flex w-full flex-col justify-start md:w-6/12">
			<span className="mb-2 text-3xl font-bold text-[#FFDD85]">OOPS !</span>
			<h1 className="text-4xl font-bold leading-10 text-black">
				Une erreur inattendue s&rsquo;est produite.
			</h1>
			<p className="mt-4 text-gray-900">
				Nous nous excusons pour le désagrément causé. Veuillez réessayer plus
				tard ou contacter notre équipe.
			</p>
			<div className="mt-6 flex justify-start gap-6 md:mt-14">
				<Button asChild variant="suivant">
					<a href="/">Accueil</a>
				</Button>
				<Button variant="retour">
					<a href="/">Retourner</a>
				</Button>
			</div>
		</div>
	);
};
