import { ContactArrowIndicator } from "@/components/shared/ContactArrowIndicator";

import notFoundIcon from "./assets/notFoundIcon.svg";
import { LeftNotFoundContent } from "./components/LeftNotFoundContent";

export const NotFoundPage = () => {
	return (
		<>
			<div className="my-8 flex size-full flex-col-reverse items-center justify-center gap-8 px-10 md:my-0 md:flex-row md:px-16">
				<LeftNotFoundContent />
				<div className="hidden w-1/12 lg:flex" />
				<div className="hidden w-full text-right md:flex md:w-5/12">
					<img
						src={notFoundIcon}
						className="mx-auto max-w-60 md:ml-auto md:mr-0"
					/>
				</div>
			</div>
			<ContactArrowIndicator />
		</>
	);
};
