import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { EffectiveDateDto, GuaranteeInfoDto } from "@repos/ecom-dtos";

interface OptionalGuaranteeInfo {
	label: string;
	price: number;
}

interface PricingStore {
	optionalCeilings: GuaranteeInfoDto[];
	optionalPrice: OptionalGuaranteeInfo[] | null;
	basicCeilings: GuaranteeInfoDto[];
	basicPrice: number | null;
	monthlyPrime: number | null;
	setMonthlyPrime: (monthlyPrime: number | null) => void;
	addOptionalCeiling: (newGuarantee: GuaranteeInfoDto) => void;
	updateOptionalPrice: (optionalPrice: OptionalGuaranteeInfo | null) => void;
	addBasicCeiling: (newGuarantee: GuaranteeInfoDto) => void;
	updateBasicPrice: (basicCeilings: number | null) => void;
	removeOptionalPriceCeiling: (name: string) => void;
	removeOptionalCeiling: (name: string) => void;
	showAddButtons: { [key: string]: boolean | undefined };
	setShowAddButton: (name: string, showAddButton: boolean) => void;
	effectiveDate: EffectiveDateDto;
	setEffectiveDate: (date: EffectiveDateDto) => void;
	reset: () => void;
}

const usePricingStore = create<PricingStore>()(
	persist(
		(set) => ({
			optionalCeilings: [],
			optionalPrice: [],
			basicCeilings: [],
			basicPrice: null,
			monthlyPrime: null,
			showAddButtons: { undefined },
			effectiveDate: { effectiveDate: new Date() },
			addOptionalCeiling: (newGuarantee: GuaranteeInfoDto) =>
				set((state) => {
					const existingGuarantee = state.optionalCeilings.some(
						(guarantee) => guarantee.label === newGuarantee.label,
					);
					let updatedOptionalCeiling: GuaranteeInfoDto[];
					if (existingGuarantee) {
						updatedOptionalCeiling = state.optionalCeilings.map((guarantee) =>
							guarantee.label === newGuarantee.label ? newGuarantee : guarantee,
						);
					} else {
						updatedOptionalCeiling = [...state.optionalCeilings, newGuarantee];
					}
					return { optionalCeilings: updatedOptionalCeiling };
				}),
			removeOptionalCeiling: (label: string) =>
				set((state) => {
					// Filter out the item that matches the provided label
					const updatedOptionalCeilings = state.optionalCeilings.filter(
						(guarantee) => guarantee.label !== label,
					);

					// Return the updated state with the new optionalCeilings array
					return { ...state, optionalCeilings: updatedOptionalCeilings };
				}),

			updateOptionalPrice: (newPriceInfo: OptionalGuaranteeInfo | null) =>
				set((state) => {
					if (newPriceInfo === null) {
						return state;
					}

					// Initialize the optionalPrice array if it's null
					const optionalPrices = state.optionalPrice ?? [];

					// Check if the label already exists in the optionalPrice array
					const existingPriceIndex = optionalPrices.findIndex(
						(priceInfo) => priceInfo.label === newPriceInfo.label,
					);

					let updatedOptionalPrices: OptionalGuaranteeInfo[];

					if (existingPriceIndex !== -1) {
						// If found, update the existing price
						updatedOptionalPrices = optionalPrices.map((priceInfo, index) =>
							index === existingPriceIndex
								? { ...priceInfo, price: newPriceInfo.price }
								: priceInfo,
						);
					} else {
						// If not found, add the new price info
						updatedOptionalPrices = [...optionalPrices, newPriceInfo];
					}

					// Return the updated state with the ensured optionalPrice array
					return { ...state, optionalPrice: updatedOptionalPrices };
				}),

			addBasicCeiling: (newGuarantee: GuaranteeInfoDto) =>
				set((state) => {
					const existingGuarantee = state.basicCeilings.some(
						(guarantee) => guarantee.label === newGuarantee.label,
					);
					let updatedBasicCeiling: GuaranteeInfoDto[];
					if (existingGuarantee) {
						updatedBasicCeiling = state.basicCeilings.map((guarantee) =>
							guarantee.label === newGuarantee.label ? newGuarantee : guarantee,
						);
					} else {
						updatedBasicCeiling = [...state.basicCeilings, newGuarantee];
					}
					return { basicCeilings: updatedBasicCeiling };
				}),
			updateBasicPrice: (basicPrice: number | null) => set({ basicPrice }),
			setMonthlyPrime: (monthlyPrime: number | null) => set({ monthlyPrime }),
			removeOptionalPriceCeiling: (label: string) =>
				set((state) => {
					if (!state.optionalPrice) {
						return state;
					}

					// Filter out the item that matches the provided label
					const updatedOptionalPrices = state.optionalPrice.filter(
						(priceInfo) => priceInfo.label !== label,
					);

					// Return the updated state with the new optionalPrice array
					return { ...state, optionalPrice: updatedOptionalPrices };
				}),
			setShowAddButton: (name: string, showAddButton: boolean) =>
				set((state) => {
					return {
						showAddButtons: { ...state.showAddButtons, [name]: showAddButton },
					};
				}),
			setEffectiveDate: (date: EffectiveDateDto) =>
				set({ effectiveDate: date }),
			reset: () =>
				set({
					optionalCeilings: [],
					basicCeilings: [],
					effectiveDate: undefined,
					showAddButtons: { undefined },
				}),
		}),
		{
			name: "pricing-storage",
		},
	),
);

export default usePricingStore;
