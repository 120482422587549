import type { FieldValues, UseControllerProps } from "react-hook-form";
import { PhoneInput } from "@/components/shared/PhoneInput";
import { cn } from "@/utils";
import { CircleAlert } from "lucide-react";
import { useFormContext } from "react-hook-form";

import { FormField, FormItem, FormLabel, FormMessage } from "../ui/form";

interface PhoneNumberInputProps<T extends FieldValues>
	extends UseControllerProps<T> {
	label: string;
}

export default function PhoneNumberInput<T extends FieldValues>({
	label,
	name,
}: PhoneNumberInputProps<T>) {
	const { control } = useFormContext<T>();
	return (
		<FormField
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<FormItem>
					<FormLabel className="text-mobileBlack absolute left-6 top-0 z-20 bg-white pl-2 pr-2 text-xs md:text-sm lg:text-primary">
						{label} <span className="text-red-800">*</span>
					</FormLabel>
					<div className="relative">
						<PhoneInput
							{...field}
							defaultCountry="FR"
							placeholder="0612345678"
							className={cn(error && "border-red-500")}
						/>
						{error && (
							<div className="absolute inset-y-0 right-1 flex items-center pr-3">
								<CircleAlert className="size-5" color="red" />
							</div>
						)}
					</div>
					{error && (
						<div className="flex h-3 flex-row items-center">
							<FormMessage className="ml-1 text-xs font-normal" />
						</div>
					)}
				</FormItem>
			)}
		/>
	);
}
