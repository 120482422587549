import {
	ActivityField,
	GuaranteeCeilingType,
	SubscriberGender,
} from "@prisma/client";
import { addDays, isAfter, isBefore } from "date-fns";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import { z } from "zod";

export const turnoverSchema = z.object({
	turnover: z.coerce
		.number({
			invalid_type_error: "Veuillez saisir votre chiffre d’affaires.",
			required_error: "Le chiffre d'affaires doit être renseigné.",
		})
		.min(0, "Veuillez saisir votre chiffre d’affaires."),
});
export type TurnoverDto = z.infer<typeof turnoverSchema>;

export const emailSchema = z
	.string({
		required_error: "Veuillez saisir une adresse e-mail.",
		invalid_type_error: "L'adresse e-mail doit être valide.",
	})
	.nonempty("Veuillez saisir une adresse e-mail.")
	.email("L'adresse e-mail doit être valide.");

export type EmailDto = z.infer<typeof emailSchema>;

export const sirenSchema = z.coerce
	.string({
		required_error: "Veuillez saisir le numéro SIREN.",
		invalid_type_error: "Veuillez saisir le numéro SIREN.",
	})
	.nonempty("Veuillez saisir le numéro SIREN.")
	.regex(/^\d+$/, "Le numéro SIREN doit contenir uniquement des chiffres.")
	.length(9, "Le numéro SIREN doit contenir 9 chiffres.");

export type SirenDto = z.infer<typeof sirenSchema>;

export const PhoneNumberSchema = z
	.string({
		required_error: "Veuillez saisir le numéro de téléphone.",
		invalid_type_error: "Veuillez saisir le numéro de téléphone.",
	})
	.refine((value) => value && isValidPhoneNumber(value), {
		message: "Veuillez saisir un numéro de téléphone portable valide.",
	});
export type PhoneDto = z.infer<typeof PhoneNumberSchema>;

export const companyNameSchema = z
	.string()
	.min(1, "Veuillez saisir le nom de la société.");

export type CompanyNameDto = z.infer<typeof companyNameSchema>;

export const companyNafSchema = z
	.string()
	.nonempty("Veuillez saisir le code NAF.")
	.min(5, "Le code NAF doit contenir au moins 5 caractères.")
	.max(7, "Le code NAF ne doit pas dépasser 7 caractères.")
	.regex(/^\d{2}[-.]?\d{2}[A-Z]$/i)
	.transform((value) => value.replace(/[-.]/g, "").toUpperCase())
	.refine((value) => /^\d{4}[A-Z]$/.test(value), "Code NAF invalide.");
export type CompanyNafDto = z.infer<typeof companyNafSchema>;

export const legalStatusSchema = z
	.string()
	.min(1, "Veuillez saisir la status juridique.");

export type LegalStatusDto = z.infer<typeof legalStatusSchema>;

export const addressSchema = z.coerce
	.string({
		required_error: "Veuillez saisir l'addresse.",
		invalid_type_error: "Veuillez saisir l'addresse.",
	})
	.min(1, "Veuillez saisir l'adresse de la société.");

export type AdressDto = z.infer<typeof addressSchema>;

export const activitySchema = z.object({
	selectedActivity: z.nativeEnum(ActivityField, {
		required_error: "Veuillez sélectionner une activité.",
	}),
	// z
	// 	.string({
	// 		required_error: "Veuillez sélectionner une activité.",
	// 	})
	// 	.nonempty("Veuillez sélectionner une activité."),
});

export type ActivitiesDto = z.infer<typeof activitySchema>;

export const firstNameSchema = z.coerce
	.string({
		required_error: "Veuillez saisir votre nom.",
		invalid_type_error: "Veuillez saisir votre nom.",
	})
	.min(1, "Veuillez saisir votre nom.")
	.regex(/^[a-zA-ZÀ-ÖØ-öø-ÿ]+$/, "Le nom ne doit contenir que des lettres.");

export type SubscriberFirstNameDto = z.infer<typeof firstNameSchema>;

export const lastNameSchema = z.coerce
	.string({
		required_error: "Veuillez saisir votre prénom.",
		invalid_type_error: "Veuillez saisir votre prénom.",
	})
	.min(1, "Veuillez saisir votre prénom.")
	.regex(/^[a-zA-ZÀ-ÖØ-öø-ÿ]+$/, "Le nom ne doit contenir que des lettres.");

export type SubscriberLastNameDto = z.infer<typeof lastNameSchema>;

export const citySchema = z.coerce
	.string({
		required_error: "Veuillez saisir votre ville.",
		invalid_type_error: "Veuillez saisir votre ville.",
	})
	.min(1, "Veuillez saisir votre ville.");

export type CityDto = z.infer<typeof citySchema>;

export const countrySchema = z.coerce
	.string({
		required_error: "Veuillez saisir votre ville.",
		invalid_type_error: "Veuillez saisir votre ville.",
	})
	.min(1, "Veuillez saisir votre ville.");

export type CountryDto = z.infer<typeof countrySchema>;

export const birthdaySchema = z.coerce
	.date({
		required_error: "Veuillez saisir votre date de naissance.",
		invalid_type_error: "Veuillez saisir une date valide.",
	})
	.refine((date) => date <= new Date(), {
		message: "La date de naissance ne peut pas être dans le futur.",
	})
	.refine(
		(date) => {
			const today = new Date();
			const age = today.getFullYear() - date.getFullYear();
			return age >= 18;
		},
		{
			message: "Veuillez saisir votre date de naissance.",
		},
	);

export type BirthdayDto = z.infer<typeof birthdaySchema>;

export const postalCodeSchema = z.coerce
	.string({
		required_error: "Veuillez saisir un code postal.",
		invalid_type_error: "Veuillez saisir une date valide.",
	})
	.nonempty("Veuillez saisir un code postal.")
	.regex(/^\d+$/, "Le code postal doit contenir uniquement des chiffres.")
	.min(0, "Le code postal doit contenir au moins 3 caractères.")
	.max(5, "Le code postal doit contenir au plus de 5 caractères.");
export type PostalCodeDto = z.infer<typeof postalCodeSchema>;

export const genderSchema = z.nativeEnum(SubscriberGender, {
	required_error: "Veuillez sélectionner la civilité.",
	invalid_type_error: "Veuillez sélectionner la civilité.",
});
export type GenderDto = z.infer<typeof genderSchema>;

export const guaranteeSchema = z.nativeEnum(GuaranteeCeilingType);
export type GuaranteeDto = z.infer<typeof guaranteeSchema>;

export const ceillingOptionsSchema = z.object({
	optionId: z.string(),
});

export type CeillingOptionsType = z.infer<typeof ceillingOptionsSchema>;

export const effectiveDateSchema = z.object({
	effectiveDate: z.coerce
		.date({
			required_error: "Veuillez saisir votre date de naissance.",
			invalid_type_error: "Veuillez saisir une date valide.",
		})
		.refine((date) => isAfter(date, new Date()), {
			message: "Veuillez saisir une date valide.",
		})
		.refine(
			(date) => {
				const threeMonthsFromNow = addDays(new Date(), 90);
				return isBefore(date, threeMonthsFromNow);
			},
			{
				message:
					"La date d'effet ne peut pas dépasser 3 mois à partir d'aujourd'hui.",
			},
		),
});

export type EffectiveDateDto = z.infer<typeof effectiveDateSchema>;

export const optionalGuaranteesRelatedStatementsSchema = z.object({
	guaranteeType: z.nativeEnum(GuaranteeCeilingType),
	statements: z.array(z.string()),
	activeIconUrl: z.string(),
});

export type OptionalGuaranteesRelatedStatementsDto = z.infer<
	typeof optionalGuaranteesRelatedStatementsSchema
>;

export const suggestionActivitySchema = z
	.string({
		required_error: "Veuillez saisir votre activité.",
		invalid_type_error: "Veuillez saisir votre activité.",
	})
	.min(1, "Veuillez saisir votre activité.")
	.regex(
		/^[a-zA-ZÀ-ÖØ-öø-ÿ]+$/,
		"L'activité ne doit contenir que des lettres.",
	);

export type SuggestionActivityDto = z.infer<typeof suggestionActivitySchema>;
