import { GuaranteeCeilingType } from "@prisma/client";
import { z } from "zod";

export const StatementsResponseSchema = z.object({
	statements: z.array(z.string()),
	prohibitedProducts: z.array(z.string()),
	optionalGuaranteesRelatedStatements: z.array(
		z.object({
			guaranteeType: z.nativeEnum(GuaranteeCeilingType),
			statements: z.array(z.string()),
			activeIconUrl: z.string(),
		}),
	),
});

export type StatementsResponseDto = z.infer<typeof StatementsResponseSchema>;
