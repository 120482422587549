import testimonialMenImage from "@/assets/testimonialMenImage.svg";
import testimonialWomenImage from "@/assets/testimonialWomenImage.svg";
import { TestimonialCard } from "@/components/shared/TestimonialCard";

type LeftHalfCustomSubscriptionContentProps = {
	isFormSubmitted: boolean;
};

export const LeftHalfCustomSubscriptionContent = ({
	isFormSubmitted,
}: LeftHalfCustomSubscriptionContentProps) => {
	return (
		<>
			<div className="flex h-full w-5/6 flex-1 items-end justify-center pl-16 xl:items-end">
				{!isFormSubmitted && (
					<div className="w-full xl:mb-5 ">
						<div className="text-xl font-bold leading-10 text-gray-800 md:leading-7 lg:text-2xl xl:text-3xl">
							Nous aurons besoin de vos informations
						</div>
						<div className=" mt-8 text-sm text-black xl:text-base">
							Partagez quelques informations pour que nous puissions mieux
							répondre à vos besoins.
						</div>
					</div>
				)}
			</div>
			<div className="mt-8 flex h-full w-5/6 flex-1 items-end justify-center pl-16 xl:items-end">
				<TestimonialCard
					name="Emilie S."
					picture={
						!isFormSubmitted ? testimonialWomenImage : testimonialMenImage
					}
				/>
			</div>
		</>
	);
};
