import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { BirthdayDto, SubscriberCoordsDto } from "@repos/ecom-dtos";

export type Subscriber = Omit<SubscriberCoordsDto, "birthDate"> & {
	birthDate: BirthdayDto;
};

interface SubscriberStore {
	subscriber: Subscriber | null;
	setSubscriber: (subscriber: Subscriber | null) => void;
	reset: () => void;
}

const useSubscriberStore = create<SubscriberStore>()(
	persist(
		(set) => ({
			subscriber: null,
			setSubscriber: (subscriber: Subscriber | null) => set({ subscriber }),
			reset: () => set({ subscriber: null }),
		}),
		{
			name: "subscriber-storage",
			getStorage: () => localStorage,
		},
	),
);

export default useSubscriberStore;
