import {
	addDays as addDaysFn,
	addYears as addYearsFn,
	startOfToday,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";

const frenchTimeZone = "Europe/Paris";

export function convertToUtc(date: Date): Date {
	const zonedTime = toZonedTime(date, frenchTimeZone);
	return zonedTime;
}

export function getToday(): Date {
	return startOfToday();
}

export function addYears(date: Date, years: number): Date {
	return addYearsFn(date, years);
}

export function addDays(date: Date, days: number): Date {
	return addDaysFn(date, days);
}

export function getMaxSelectableDate(daysFromToday: number): Date {
	return addDays(getToday(), daysFromToday);
}

export function getYearsRange(startYear: number, endYear: number): number[] {
	return Array.from({ length: endYear - startYear + 1 }, (_, i) => endYear - i);
}

export function getMonths(): string[] {
	return Array.from({ length: 12 }, (_, i) =>
		new Date(0, i).toLocaleString("default", { month: "long" }),
	);
}
