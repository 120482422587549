import { useEffect, useState } from "react";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import { Skeleton } from "@/components/ui/skeleton";
import ProhibitedProductsNotice from "@/pages/10-StatementPage/components/ProhibitedProductsNotice";
import StatementsNotice from "@/pages/10-StatementPage/components/StatementsNotice";
import usePricingStore from "@/stores/usePricingStore";
import useProgressStore from "@/stores/useProgressStore";
import { trpc } from "@/utils";

import type { StatementsResponseDto } from "@repos/ecom-dtos";

import checkIcon from "./assets/check.svg";
import unCheckIcon from "./assets/unCkeck.svg";
import CardCheckboxStatement from "./components/CardCheckboxStatement";
import CardGuaranteeStatement from "./components/CardGuaranteeStatement";
import FooterStatementButton from "./components/FooterStatementButton";
import { StatementsButtonDownload } from "./components/StatementsButtonDownload";

interface StatementPageProps {
	stepProps: number;
}

export const StatementPage = ({ stepProps }: StatementPageProps) => {
	const { setStep } = useProgressStore((state) => ({
		setStep: state.setStep,
		setLastSubmittedStep: state.setLastSubmittedStep,
	}));

	const { basicPricing, optionnalPricing } = usePricingStore((state) => ({
		basicPricing: state.basicCeilings,
		optionnalPricing: state.optionalCeilings,
	}));

	const concatStorePricing = basicPricing.concat(optionnalPricing);

	const inputUseMutation = concatStorePricing.map((item) => item.type);

	const [statementGuarantees, setStatementGuarantees] =
		useState<StatementsResponseDto>({
			statements: [],
			prohibitedProducts: [],
			optionalGuaranteesRelatedStatements: [],
		});

	const { data, isLoading } =
		trpc.configuration.getSubscriptionStatements.useQuery({
			chosenGuaranteesTypes: inputUseMutation,
		});

	useEffect(() => {
		if (data) {
			setStatementGuarantees(data);
		}
	}, [data]);

	useEffect(() => {
		setStep(stepProps);
	}, [stepProps, setStep]);

	if (isLoading) {
		return (
			<div className="flex flex-1 flex-row">
				<div className="mx-auto flex size-full w-[80%] flex-col">
					<Skeleton className="m-3 ml-0 h-[2rem] w-[80%] rounded-lg bg-gray-300 lg:ml-3 lg:w-[50%]" />
					<Skeleton className="m-1 mx-auto h-[4rem] w-full rounded-lg bg-gray-300 lg:m-3 lg:h-[35rem]" />
					<Skeleton className="m-1 mx-auto h-[4rem] w-full rounded-lg bg-gray-300 lg:m-3 lg:h-[35rem]" />
					<Skeleton className="m-1 mx-auto h-[4rem] w-full rounded-lg bg-gray-300 lg:m-3 lg:h-[35rem]" />
					<Skeleton className="m-1 mx-auto h-[4rem] w-full rounded-lg bg-gray-300 lg:m-3 lg:h-[35rem]" />
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="flex w-full flex-col items-start justify-start">
				<div className="mx-auto flex w-full flex-col items-start justify-center px-11 lg:w-5/6 lg:min-w-[40rem]">
					<p className="mb-5 text-left text-lg font-bold text-gray-400  lg:text-xl lg:text-gray-800">
						Confirmation des déclarations
					</p>
					<div className="hidden lg:block">
						{/* Desktop version */}
						<StatementsNotice data={statementGuarantees} />
						<ProhibitedProductsNotice data={statementGuarantees} />
						{statementGuarantees.optionalGuaranteesRelatedStatements.map(
							(optionalGuarantee, index) => (
								<CardGuaranteeStatement
									key={`statOp-${index}`}
									data={optionalGuarantee}
									concatStorePricing={concatStorePricing}
								/>
							),
						)}
					</div>

					{/* Mobile version */}
					<Accordion
						type="single"
						collapsible
						className="mb-5 w-full space-y-4 lg:hidden"
					>
						<AccordionItem
							value="statements"
							className="rounded-xl border border-[#A5A5A5] border-opacity-30 bg-[#ffdd711c] px-4"
						>
							<AccordionTrigger className="hover:no-underline">
								<div className="flex items-center gap-2">
									<img
										src={checkIcon}
										className="mr-3 size-7"
										alt="Check Icon"
									/>
									<p className="text-left text-sm font-bold text-blackMobile">
										Je déclare
									</p>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<StatementsNotice data={statementGuarantees} />
							</AccordionContent>
						</AccordionItem>
						<AccordionItem
							value="prohibitedProducts"
							className="rounded-xl border border-[#A5A5A5] border-opacity-30 bg-[#ffdd711c] px-4"
						>
							<AccordionTrigger className="hover:no-underline">
								<div className="flex items-center gap-2">
									<img
										src={unCheckIcon}
										className="mr-3 size-7 grayscale"
										alt="Check Icon"
									/>
									<p className="text-left text-sm font-bold text-blackMobile">
										Ne pas commercialiser les produits suivants :
									</p>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<ProhibitedProductsNotice data={statementGuarantees} />
							</AccordionContent>
						</AccordionItem>
						{statementGuarantees.optionalGuaranteesRelatedStatements.map(
							(optionalGuarantee, index) => (
								<AccordionItem
									key={`statOp-${index}`}
									value={`guarantee-${index}`}
									className="rounded-xl border border-[#A5A5A5] border-opacity-30 bg-[#ffdd711c] px-4"
								>
									<AccordionTrigger className="hover:no-underline">
										<div className="flex items-center gap-2">
											<img
												src={optionalGuarantee.activeIconUrl}
												className="mr-3 size-7"
												alt="Check Icon"
											/>
											<p className="text-left text-sm font-bold text-blackMobile">
												Pour votre garantie{" "}
												{
													concatStorePricing.find(
														(item) =>
															item.type === optionalGuarantee.guaranteeType,
													)?.label
												}{" "}
												:
											</p>
										</div>
									</AccordionTrigger>
									<AccordionContent>
										<CardGuaranteeStatement
											data={optionalGuarantee}
											concatStorePricing={concatStorePricing}
										/>
									</AccordionContent>
								</AccordionItem>
							),
						)}
					</Accordion>

					<CardCheckboxStatement />
				</div>
				<FooterStatementButton />
			</div>
			<div className="hidden lg:block">
				<StatementsButtonDownload />
			</div>
		</>
	);
};
