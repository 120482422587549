import "./spinner.css";

export const SpinnerPage = () => {
	return (
		<div className="fixed bottom-0 left-0 right-0 top-0 z-40 flex h-screen w-screen flex-col items-center justify-center bg-[#FFFBF5]">
			<svg
				width="101"
				height="101"
				viewBox="0 0 101 101"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M9 95.5C7.067 95.5 5.5 93.933 5.5 92V36.7727C5.5 19.6989 19.9413 5.5 38.2171 5.5H92C93.933 5.5 95.5 7.067 95.5 9V92C95.5 93.933 93.933 95.5 92 95.5H50.5H9Z"
					stroke="#D9D9D9"
					strokeWidth="11"
					fill="none"
					className="fixed-path"
				/>
				<path
					d="M9 95.5C7.067 95.5 5.5 93.933 5.5 92V36.7727C5.5 19.6989 19.9413 5.5 38.2171 5.5H92C93.933 5.5 95.5 7.067 95.5 9V92C95.5 93.933 93.933 95.5 92 95.5H50.5H9Z"
					stroke="#0F6DD4"
					strokeWidth="11"
					className="animated-path"
				/>
			</svg>
		</div>
	);
};
