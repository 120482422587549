import * as React from "react";

interface Step {
	label: string;
}

interface LabelStepProps {
	step: Step;
}

export default function LabelStep({ step }: LabelStepProps) {
	return (
		<div className="line-clamp-2 hidden flex-shrink-0 text-sm font-medium lg:flex">
			{step.label.split(" ").map((line, i) => (
				<React.Fragment key={i}>
					{line} {i === 0 ? <br /> : null}
				</React.Fragment>
			))}
		</div>
	);
}
