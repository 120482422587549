import { Button } from "@/components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import { useCustomNavigate } from "@/hooks/customNavigate";
import { useMonthlyPrime } from "@/hooks/useMonthlyPrice";
import basicIcon from "@/pages/8-EffectiveContractDatePage/assets/basicIcon.svg";
import documentIcon from "@/pages/8-EffectiveContractDatePage/assets/document.svg";
import folderIcon from "@/pages/8-EffectiveContractDatePage/assets/folder.svg";
import optianalIcon from "@/pages/8-EffectiveContractDatePage/assets/optianalIcon.svg";
import usePricingStore from "@/stores/usePricingStore";

import type {
	EffectiveDateDto,
	FullGuaranteeResponseDto,
} from "@repos/ecom-dtos";

interface CardFinalGuaranteesProps {
	listLabelBasicGuarantees: FullGuaranteeResponseDto[];
	onClickSubscribe: (date: EffectiveDateDto) => void;
}

export default function CardFinalGuarantees({
	listLabelBasicGuarantees,
}: CardFinalGuaranteesProps) {
	const navigate = useCustomNavigate();

	const { optionalCeilings } = usePricingStore((state) => ({
		optionalCeilings: state.optionalCeilings,
	}));

	const {
		monthlyPrimeTotal,
		totalBasicGuaranteePrice,
		totalOptionalGuaranteesPrice,
	} = useMonthlyPrime();

	const annualPrime = monthlyPrimeTotal * 12;

	return (
		<Card className="m-0 w-full rounded-xl border-none bg-white lg:m-2 lg:w-5/6 lg:rounded-tl-custom">
			<CardContent className="mt-4 flex flex-col items-start lg:mt-16">
				<div className="w-full border-b-[0.05rem] border-gray-300">
					<CardHeader className="flex w-full flex-row items-start justify-between ">
						<div className="flex w-full flex-row items-start justify-start">
							<img className="mr-2 size-12 lg:size-9" src={basicIcon} />
							<div className="w-full">
								<div className="mt-1 flex w-full flex-row items-center justify-between ">
									<CardTitle className="ml-3 text-md lg:ml-0 lg:text-xs">
										Garanties de base
									</CardTitle>
									<CardTitle className="w-2/4 text-end text-md font-bold text-primary lg:text-xs ">
										+{Math.round(totalBasicGuaranteePrice * 100) / 100} €/mois
									</CardTitle>
								</div>
								<ul className="ml-3 mt-3 list-disc">
									{listLabelBasicGuarantees.map((item, index) => (
										<li className="ml-3 text-sm lg:ml-0" key={`gua-${index}`}>
											{item.label}
										</li>
									))}
								</ul>
							</div>
						</div>
					</CardHeader>
				</div>
				<div className="w-full border-b-[0.05rem] border-gray-300">
					<CardHeader className="flex w-full flex-row items-start justify-between ">
						<div className="flex w-full flex-row items-start justify-start">
							<img className="mr-2 size-12 lg:size-9" src={optianalIcon} />
							<div className="w-full">
								<div className="mt-1 flex w-full flex-row items-center justify-between ">
									<CardTitle className="ml-3 text-md lg:ml-0 lg:text-xs">
										Garanties optionnelles
									</CardTitle>
									<CardTitle className="w-2/4 text-end text-md font-bold text-primary lg:text-xs">
										+
										{optionalCeilings.length > 0
											? totalOptionalGuaranteesPrice.toFixed(2)
											: 0}
										€/mois
									</CardTitle>
								</div>
								<ul className="ml-3 mt-3 list-disc">
									{optionalCeilings.length > 0 &&
										optionalCeilings.map((label, index) => (
											<li className="ml-3 text-sm lg:ml-0" key={`gua-${index}`}>
												{label.label}
											</li>
										))}
								</ul>
							</div>
						</div>
					</CardHeader>
				</div>
				<div className="mt-4 w-full">
					<div className="mb-3 ml-3 flex flex-row items-center">
						<img src={documentIcon} className="mr-2" />
						<CardDescription>
							Frais de gestion <span className="text-primary">+1 €/mois</span>
						</CardDescription>
					</div>
					<div className="mb-3 ml-3 flex flex-row items-center">
						<img src={folderIcon} className="mr-2" />
						<CardDescription>
							Frais de dossier
							<span className="text-primary"> à la souscription +15 €</span>
						</CardDescription>
					</div>
				</div>
				<div className="flex w-full items-start justify-end">
					<div className="flex flex-col items-end">
						<div className="flex flex-col items-end rounded-full bg-prime px-5 py-2">
							<CardDescription className="self-center text-xs">
								Prime/mois
							</CardDescription>
							<CardTitle className="self-center text-md font-bold text-black">
								{monthlyPrimeTotal.toFixed(2)} €
							</CardTitle>
						</div>
						<CardDescription className="self-center text-xs">
							soit {annualPrime.toFixed(2)} €/an
						</CardDescription>
					</div>
				</div>
			</CardContent>
			<CardFooter className="hidden flex-row items-center justify-between md:flex">
				<Button
					className="w-28"
					variant={"retour"}
					onClick={() => {
						navigate("SUBSCRIBER_INFOS");
					}}
				>
					Retour
				</Button>
				<Button type="submit" className="w-28" variant={"suivant"}>
					Souscrire
				</Button>
			</CardFooter>
		</Card>
	);
}
