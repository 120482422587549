// import type { CeilingInfo } from "@/stores/usePricingStore";
import { useEffect, useState } from "react";
import CustomCard from "@/components/CardComponent/CustomCard";
import CustomCardHeader from "@/components/CardComponent/CustomCardHeader";
import { MobileFooter } from "@/components/MobileComponents/MobileFooter";
import { MobileGuaranteesChosen } from "@/components/MobileComponents/MobileGuaranteesChosen";
import { Button } from "@/components/ui/button";
import { CardContent, CardFooter } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { useCustomNavigate } from "@/hooks/customNavigate";
import usePricingStore from "@/stores/usePricingStore";
import useProgressStore from "@/stores/useProgressStore";
import useTurnoverStore from "@/stores/useTurnoverStore";
import { trpc } from "@/utils";
import { formatDate } from "@/utils/utilsDigital";
import { GuaranteeCeilingType } from "@prisma/client";

import type {
	GuaranteeCeilingDto,
	GuaranteeInfoDto,
	SubscriptionPricingResponseDto,
} from "@repos/ecom-dtos";

import { SpinnerPage } from "../SpinnerPage";
import yourGuaranteesIcon from "./assets/yourGuaranteesIcon.svg";
import { InfoChosenInsuranceCard } from "./components/InfoChosenInsuranceCard";
import { InfoCompanyAndUserCard } from "./components/InfoCompanyAndUserCard";
import { SingleInfoGuarantee } from "./components/SingleInfoGuarantee";

interface SummarizeOfferPageProps {
	stepProps: number;
}

export const SummarizeOfferPage = ({ stepProps }: SummarizeOfferPageProps) => {
	const navigate = useCustomNavigate();
	const { incrementStep, setStep, setLastSubmittedStep } = useProgressStore(
		(state) => ({
			incrementStep: state.incrementStep,
			setStep: state.setStep,
			setLastSubmittedStep: state.setLastSubmittedStep,
		}),
	);

	const [fullSubscriptionPricing, setFullSubscriptionPricing] = useState<
		SubscriptionPricingResponseDto | undefined
	>();

	const franchiseColorMap: Record<GuaranteeCeilingType, string> = {
		[GuaranteeCeilingType.RC]: "#FFFDD9",
		[GuaranteeCeilingType.AP]: "#D9FFF9",
		[GuaranteeCeilingType.RCE]: "#FFD9D9",
		[GuaranteeCeilingType.PJ]: "#E0E0FF",
		[GuaranteeCeilingType.TDMI]: "#FFDEDE",
		[GuaranteeCeilingType.CYBER]: "#D0E0FF",
	};

	const { turnover } = useTurnoverStore((state) => ({
		turnover: state.turnover,
	}));

	const { effectiveDate, basicCeilings, optionalCeilings } = usePricingStore(
		(state) => ({
			effectiveDate: state.effectiveDate,
			basicCeilings: state.basicCeilings,
			optionalCeilings: state.optionalCeilings,
		}),
	);

	const formattedDate = formatDate(effectiveDate.effectiveDate.toString());

	useEffect(() => {
		if (!turnover) {
			navigate("TURNOVER");
		}
	}, [turnover, navigate]);

	const { mutateAsync, isLoading } =
		trpc.estimation.getFullSubscriptionPricing.useMutation({
			onSuccess: (data) => {
				setFullSubscriptionPricing(data);
			},
			onError: () => {
				toast({
					description:
						"Une erreur interne est survenue, veuillez réessayer plus tard.",
					duration: 2000,
					variant: "destructive",
					style: {
						color: "white",
					},
				});
			},
		});

	const transformOptionnalPricing = (
		pricing: GuaranteeInfoDto[],
	): GuaranteeCeilingDto[] => {
		return pricing.map((item) => ({
			type: item.type,
			ceilingValue: item.ceilingValue,
			ceilingOptions: item.ceilingOptions,
		}));
	};

	const transformedDetailsBasic = transformOptionnalPricing(
		basicCeilings.filter((item) => item.label),
	);
	const transformedDetailsOptionnal = transformOptionnalPricing(
		optionalCeilings.filter((item) => item.label),
	);

	useEffect(() => {
		if (turnover) {
			mutateAsync({
				turnoverValue: turnover,
				details: {
					ceilings: transformedDetailsBasic.concat(transformedDetailsOptionnal),
				},
			});
		}
	}, [turnover, mutateAsync]);

	const handleNext = () => {
		incrementStep();
		navigate("OFFER_REQUIREMENTS");
		setLastSubmittedStep(stepProps);
	};

	useEffect(() => {
		setStep(stepProps);
	}, []);

	if (isLoading) {
		return <SpinnerPage />;
	}

	return (
		<>
			<div className="mb-5 flex w-full flex-col items-start space-y-8 px-6 lg:px-20">
				<h1 className="hidden text-center text-xl font-bold lg:flex">
					Résumé de votre offre
				</h1>
				<InfoCompanyAndUserCard />
				<InfoChosenInsuranceCard
					effectiveDateFormatted={formattedDate}
					monthlyPrime={fullSubscriptionPricing?.subscriptionMonthlyPrimePIT}
				/>

				<CustomCard
					displayFooter
					hideBackButton
					hideSubmitButton
					className={
						"h-full max-h-full w-full max-w-full space-y-0 bg-[#FFFFFA] px-0 pt-0 shadow-none lg:w-full lg:max-w-full lg:space-y-8 lg:px-24 lg:pt-8 lg:shadow-sm"
					}
				>
					<CustomCardHeader
						headerCardLabel="Vos garanties"
						headerCardImage={yourGuaranteesIcon}
						className="hidden lg:flex"
					/>
					<CardContent className="mt-7 p-0 pb-0 lg:pb-8">
						<h2 className="mb-5 text-lg font-bold text-gray-400 lg:hidden">
							Garanties choisis
						</h2>
						<div className="grid grid-cols-2 flex-col gap-4 lg:flex">
							{fullSubscriptionPricing &&
								fullSubscriptionPricing.chosenGuaranteesDetails.map(
									(chosenGuaranteeDetail, index, array) => {
										const priceBeforeTax =
											chosenGuaranteeDetail.chosenCeiling?.priceBeforeTax || 0;
										const taxValue =
											chosenGuaranteeDetail.chosenCeiling?.taxValue || 0;
										let price = (priceBeforeTax + taxValue) / 12;

										if (chosenGuaranteeDetail.type === "CYBER") {
											chosenGuaranteeDetail.chosenCeiling?.options?.forEach(
												(option) => {
													const optionPriceBeforeTax =
														option.priceBeforeTax || 0;
													const optionTaxValue = option.taxValue || 0;
													const optionPrice =
														(optionPriceBeforeTax + optionTaxValue) / 12;

													price += optionPrice;
												},
											);
										}

										const isLastItem = index === array.length - 1;
										const customClassName = isLastItem
											? "border-none mb-0 pt-0 hidden lg:flex"
											: "hidden lg:flex";

										const franchiseColor =
											franchiseColorMap[
												chosenGuaranteeDetail.type as GuaranteeCeilingType
											] || "#FFFDD9";

										return (
											<>
												{/* Desktop version */}
												<SingleInfoGuarantee
													key={chosenGuaranteeDetail.order}
													icon={chosenGuaranteeDetail.activeIconUrl}
													title={chosenGuaranteeDetail.label}
													price={price}
													warrantyPrice={
														chosenGuaranteeDetail.chosenCeiling?.ceilingValue
													}
													optionsGuarantee={
														chosenGuaranteeDetail.chosenCeiling?.options || []
													}
													franchisePrice={chosenGuaranteeDetail.franchiseValue}
													franchiseColor={franchiseColor}
													className={customClassName}
												/>

												{/* Mobile version */}
												<MobileGuaranteesChosen
													key={chosenGuaranteeDetail.order}
													icon={chosenGuaranteeDetail.activeIconUrl}
													title={chosenGuaranteeDetail.label}
													price={price}
													guaranteeType={chosenGuaranteeDetail.type}
													optionsGuarantee={
														chosenGuaranteeDetail.chosenCeiling?.options || []
													}
												/>
											</>
										);
									},
								)}
						</div>
					</CardContent>
				</CustomCard>

				<CardFooter className="hidden w-full flex-row items-center justify-end gap-5 lg:flex">
					<Button
						className="w-28"
						variant={"retour"}
						onClick={() => {
							navigate("EFFECTIVE_CONTRACT_DATE");
						}}
					>
						Retour
					</Button>
					<Button onClick={handleNext} className="w-28" variant={"suivant"}>
						Valider
					</Button>
				</CardFooter>
			</div>
			<MobileFooter onButtonClick={handleNext} label="Valider" />
		</>
	);
};
