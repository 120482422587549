import CustomCard from "@/components/CardComponent/CustomCard";
import CustomCardHeader from "@/components/CardComponent/CustomCardHeader";
import { CardContent, CardDescription, CardTitle } from "@/components/ui/card";

import dueDateIcon from "../assets/dueDateIcon.svg";
import effectiveDateIcon from "../assets/effectiveDateIcon.svg";
import infoAssurance from "../assets/infoAssurance.svg";
import { InfoInsuranceSelected } from "./InfoInsuranceSelected";

interface InfoChosenInsuranceCardProps {
	effectiveDateFormatted: string;
	monthlyPrime: string | undefined;
}

export const InfoChosenInsuranceCard = ({
	effectiveDateFormatted,
	monthlyPrime,
}: InfoChosenInsuranceCardProps) => {
	return (
		<CustomCard
			displayFooter
			hideBackButton
			hideSubmitButton
			className={
				"h-full w-full max-w-full space-y-0 px-0 pt-0 shadow-none lg:w-full lg:max-w-full lg:space-x-8 lg:px-24 lg:pt-8 lg:shadow-sm "
			}
		>
			<CustomCardHeader
				headerCardLabel="Informations de l’assurance choisie"
				headerCardImage={infoAssurance}
				className="hidden lg:flex"
			/>
			<CardContent className="mt-7 p-0 pb-8">
				<h2 className="mb-5 text-lg font-bold text-gray-400 lg:hidden">
					Assurance
				</h2>
				<div className="flex flex-col space-y-7">
					<InfoInsuranceSelected icon={dueDateIcon} title="Échéance">
						{/* Desktop version */}
						<div className="hidden flex-col items-end rounded-full bg-prime px-6 py-2 lg:flex">
							<CardDescription className="self-center text-xs">
								Prime/mois
							</CardDescription>
							<CardTitle className="text-lg: self-center font-bold text-black">
								{monthlyPrime && (
									<span>{parseFloat(monthlyPrime).toFixed(2) + " €"}</span>
								)}
							</CardTitle>
						</div>
						{/* Mobile version */}
						<span className="text-sm font-semibold text-[#444B53] md:text-md lg:hidden">
							{monthlyPrime && (
								<span>{parseFloat(monthlyPrime).toFixed(2) + " €"}</span>
							)}
						</span>
					</InfoInsuranceSelected>
					<InfoInsuranceSelected
						icon={effectiveDateIcon}
						title="Date d’effet"
						className={"border-none"}
					>
						<span className="lg:text-lg: text-sm font-semibold text-[#444B53] md:text-md">
							{effectiveDateFormatted}
						</span>
					</InfoInsuranceSelected>
				</div>
			</CardContent>
		</CustomCard>
	);
};
