interface Step {
	value: number;
	classNameBg: string;
	classNameTxt: string;
}

interface NumberStepProps {
	step: Step;
}

export default function NumberStep({ step }: NumberStepProps) {
	return (
		<div
			className={`mr-2 flex h-10 w-10 items-center justify-center rounded-[20px_9px_9px_9px] text-base font-normal ${step.classNameBg} ${step.classNameTxt}`}
		>
			{step.value}
		</div>
	);
}
