import IconDevis from "@/assets/devis.svg";
import IconDownload from "@/assets/download.svg";
import IconUseCases from "@/assets/useCases.svg";
import { Button } from "@/components/ui/button";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "@/components/ui/hover-card";

export const StatementsButtonDownload = () => {
	const onButtonClick = () => {
		const pdfUrl =
			"https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
		const link = document.createElement("a");
		link.href = pdfUrl;
		link.download = "document.pdf";
		link.target = "_blank";
		link.rel = "noopener noreferrer"; // Add this line for security
		link.click();
	};

	return (
		<HoverCard>
			<HoverCardTrigger className="animate-none" asChild>
				<Button
					variant={"suivant"}
					className="fixed bottom-[22%] right-4 flex h-14 w-14 cursor-pointer items-center justify-center rounded-full bg-blue-500 text-white"
				>
					<img src={IconDownload} alt="Chatbot" />
				</Button>
			</HoverCardTrigger>
			<HoverCardContent className="flex size-52 flex-col items-center justify-center rounded-full border-none bg-prime p-4">
				<div className="flex flex-col items-center">
					<Button
						onClick={onButtonClick}
						variant={"retour"}
						className="m-2 flex h-14 w-14 cursor-pointer items-center justify-center rounded-full border-none bg-white text-white"
					>
						<img src={IconUseCases} alt="Chatbot" />
					</Button>
					<p className="w-full text-center text-xs font-semibold">Mon devis</p>
				</div>
				<div className="flex flex-col items-center">
					<Button
						onClick={onButtonClick}
						variant={"suivant"}
						className="m-2 flex h-14 w-14 cursor-pointer items-center justify-center rounded-full border-none bg-white text-white"
					>
						<img src={IconDevis} alt="Chatbot" />
					</Button>
					<p className="w-1/2 text-center text-xs font-semibold">
						Conditions Générales
					</p>
				</div>
			</HoverCardContent>
		</HoverCard>
	);
};
