import type { SubmitHandler } from "react-hook-form";
import { useEffect } from "react";
import animation from "@/assets/animation.json";
import CustomCard from "@/components/CardComponent/CustomCard";
import { MobileFooter } from "@/components/MobileComponents/MobileFooter";
import { useCustomNavigate } from "@/hooks/customNavigate";
import useCustomSubscriptionStore, {
	SubscriberTypeEnum,
} from "@/stores/useCustomSubscriptionStore";
import useProgressStore from "@/stores/useProgressStore";
import useTurnoverStore from "@/stores/useTurnoverStore";
import { zodResolver } from "@hookform/resolvers/zod";
import Lottie from "lottie-react";
import { FormProvider, useForm } from "react-hook-form";

import type { TurnoverDto } from "@repos/ecom-dtos";
import { turnoverSchema } from "@repos/ecom-dtos";

import RenderContentTurnover from "./RenderContentTurnover";

interface TurnoverPageProps {
	stepProps: number;
}

export const TurnoverPage = ({ stepProps }: TurnoverPageProps) => {
	const navigate = useCustomNavigate();
	const { incrementStep, setStep, setLastSubmittedStep } = useProgressStore(
		(state) => ({
			incrementStep: state.incrementStep,
			setStep: state.setStep,
			setLastSubmittedStep: state.setLastSubmittedStep,
		}),
	);
	const { setAlertMessage, setAlertTitle, setSubscriberType, reset } =
		useCustomSubscriptionStore((state) => ({
			setSubscriberType: state.setSubscriberType,
			setAlertMessage: state.setAlertMessage,
			setAlertTitle: state.setAlertTitle,
			reset: state.reset,
		}));

	const { setTurnover, turnover } = useTurnoverStore();

	const methods = useForm<TurnoverDto>({
		defaultValues: {
			turnover: turnover || undefined,
		},
		resolver: zodResolver(turnoverSchema),
		mode: "onChange",
	});

	const handleSubmit: SubmitHandler<TurnoverDto> = (data, e) => {
		if (e) e.preventDefault();
		setTurnover(data.turnover);
		if (data.turnover > 0 && data.turnover <= 250000) {
			incrementStep();
			navigate("SIMULATION_GUARANTEE");
			setLastSubmittedStep(stepProps);
		} else {
			//TODO: the 2 lines below must be defined in the parent component
			const alertTitle = `Ce contrat ne s'applique pas pour un chiffre d'affaires excédant les 250 000 €`;
			const alertMessage =
				"Partagez quelques informations pour que nous puissions mieux répondre à vos besoins.";
			setSubscriberType(SubscriberTypeEnum.HighTurnover);
			setAlertTitle(alertTitle);
			setAlertMessage(alertMessage);
			navigate("CUSTOM_SUBSCRIPTION");
		}
	};

	useEffect(() => {
		reset();
		setStep(stepProps);
	}, []);

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(handleSubmit)}>
				<div className="flex w-screen flex-1 flex-row items-center">
					<div className="hidden h-full w-full flex-1 flex-col justify-center lg:flex">
						<Lottie animationData={animation} className="h-[85vh] w-[43vw]" />
					</div>
					<div className="mr-0 flex h-full w-full flex-1 items-center justify-center md:mr-5">
						<CustomCard displayFooter={true} hideBackButton>
							<RenderContentTurnover />
						</CustomCard>
					</div>
				</div>
				<MobileFooter />
			</form>
		</FormProvider>
	);
};
