import { useCallback } from "react";
import useActivityStore from "@/stores/useActivityStore";
import useCompanyStore from "@/stores/useCompanyStore";
import useCustomSubscriptionStore from "@/stores/useCustomSubscriptionStore";
import usePricingStore from "@/stores/usePricingStore";
import useProgressStore from "@/stores/useProgressStore";
import useProspectStore from "@/stores/useProspectStore";
import useSubscriberStore from "@/stores/useSubscriberCoordsStore";
import useSubscriberInfoStore from "@/stores/useSubscriberInfoStore";
import useTurnoverStore from "@/stores/useTurnoverStore";

export const useResetStores = () => {
	const resetActivity = useActivityStore((state) => state.reset);
	const resetCompany = useCompanyStore((state) => state.clearCompany);
	const resetCustomTarget = useCustomSubscriptionStore((state) => state.reset);
	const resetPricing = usePricingStore((state) => state.reset);
	const resetCurrentStep = useProgressStore((state) => state.resetStep);
	const resetLastSubmittedStep = useProgressStore(
		(state) => state.resetLastSubmittedStep,
	);
	const resetProspect = useProspectStore((state) => state.clearProspect);
	const resetSubscriberCords = useSubscriberStore((state) => state.reset);
	const resetTurnover = useTurnoverStore((state) => state.reset);
	const resetSubscriberInfos = useSubscriberInfoStore((state) => state.reset);

	return useCallback(() => {
		useActivityStore.persist.clearStorage();
		useCompanyStore.persist.clearStorage();
		useCustomSubscriptionStore.persist.clearStorage();
		usePricingStore.persist.clearStorage();
		useProgressStore.persist.clearStorage();
		useProspectStore.persist.clearStorage();
		useSubscriberStore.persist.clearStorage();
		useTurnoverStore.persist.clearStorage();
		useSubscriberInfoStore.persist.clearStorage();
		resetActivity();
		resetCompany();
		resetCustomTarget();
		resetPricing();
		resetCurrentStep();
		resetLastSubmittedStep();
		resetProspect();
		resetSubscriberCords();
		resetTurnover();
		resetSubscriberInfos();
	}, [
		resetActivity,
		resetCompany,
		resetCustomTarget,
		resetCurrentStep,
		resetLastSubmittedStep,
		resetPricing,
		resetProspect,
		resetSubscriberCords,
		resetTurnover,
		resetSubscriberInfos,
	]);
};
