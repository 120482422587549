type TestimonialCardProps = {
	name: string;
	picture: string;
};

export const TestimonialCard = ({ name, picture }: TestimonialCardProps) => {
	return (
		<div className="relative hidden size-full items-end overflow-hidden rounded-5xl bg-white pr-5 pt-5 shadow-lg lg:flex">
			<img src={picture} className="object-contain lg:max-w-[33%] " />

			<div className="mb-14 flex h-full flex-col justify-center bg-white p-4">
				<h2 className="mb-2 text-md font-bold">{name}</h2>
				<p className="text-sm text-gray-600">
					Depuis que j&apos;ai choisi Assurtech pour assurer mon activité en
					ligne, ma tranquillité d&apos;esprit est totale. Leur équipe a su
					comprendre mes besoins et m&apos;a offert une couverture sur mesure.
				</p>
			</div>
		</div>
	);
};
