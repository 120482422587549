import type { Step } from "@/utils/mobileNavigation";
import LogoAssurThech from "@/assets/LogoAssurThec.svg";
import { useCustomNavigate } from "@/hooks/customNavigate";
import useCustomSubscriptionStore, {
	SubscriberTypeEnum,
} from "@/stores/useCustomSubscriptionStore";
import useProgressStore from "@/stores/useProgressStore";
import { getLastRouteForStep } from "@/utils/mobileNavigation";

import Stepper from "../StepperComponent/Stepper";

export const MobileHeader = () => {
	const navigate = useCustomNavigate();
	const { currentStep } = useProgressStore();
	const { subscriberType } = useCustomSubscriptionStore();

	const checkPageTurnover = subscriberType === SubscriberTypeEnum.HighTurnover;

	const handleBack = () => {
		const backStep = Math.max(currentStep - 1, 1) as Step;
		const route = getLastRouteForStep(backStep);
		navigate(route);
	};

	return (
		<div className="sticky left-0 right-0 top-0 z-50 flex w-full flex-shrink-0 flex-col items-center justify-evenly gap-4 bg-white p-0 lg:hidden">
			<div className="flex items-center pb-6 pt-9">
				{(currentStep > 1 || checkPageTurnover) && (
					<p
						className="absolute left-5 text-xs font-bold text-[#A5A5A5]"
						onClick={handleBack}
					>
						Précédent
					</p>
				)}
				<img src={LogoAssurThech} alt="Logo" className="h-auto w-32" />
			</div>
			<Stepper />
		</div>
	);
};
