import { create } from "zustand";
import { persist } from "zustand/middleware";

export enum SubscriberTypeEnum {
	HighTurnover = "Turnover",
	LowTurnover = "Activity",
}

// Type of store
interface CustomSubscriptionState {
	subscriberType: SubscriberTypeEnum | null;
	setSubscriberType: (subscriberType: SubscriberTypeEnum | null) => void;
	alertTitle: string | null;
	setAlertTitle: (alertTitle: string | null) => void;
	alertMessage: string | null;
	setAlertMessage: (alertMessage: string | null) => void;
	reset: () => void;
}

// Create store
const useCustomSubscriptionStore = create<CustomSubscriptionState>()(
	persist(
		(set) => ({
			subscriberType: null,
			setSubscriberType: (subscriberType: SubscriberTypeEnum | null) =>
				set({ subscriberType: subscriberType }),
			alertMessage: null,
			setAlertMessage: (alertMessage: string | null) => set({ alertMessage }),
			alertTitle: null,
			setAlertTitle: (alertTitle: string | null) => set({ alertTitle }),
			reset: () =>
				set({ subscriberType: null, alertMessage: null, alertTitle: null }),
		}),
		{
			name: "Custom-Subscription-storage",
		},
	),
);

export default useCustomSubscriptionStore;
