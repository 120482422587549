import useProgressStore from "@/stores/useProgressStore";

import CustomStepper from "./components/CustomStepper";

export default function Stepper() {
	const {
		currentStep,
		lastStepOfFirstProgreesBar,
		numberOfTotalStep,
		firstStepOfFirstProgressBar,
	} = useProgressStore((state) => ({
		currentStep: state.currentStep,
		setStep: state.setStep,
		firstStepOfFirstProgressBar: state.firstStepOfFirstProgressBar,
		lastStepOfFirstProgreesBar: state.lastStepOfFirstProgreesBar,
		numberOfTotalStep: state.numberOfTotalStep,
	}));

	const steps = [
		{
			label: "Informations Assuré",
			value: 1,
			classNameBg:
				currentStep >= firstStepOfFirstProgressBar
					? "bg-blue-500"
					: "bg-gray-500",
			classNameTxt:
				currentStep >= firstStepOfFirstProgressBar
					? "text-white"
					: "text-secondary",
		},
		{
			label: "Offre et Garanties",
			value: 2,
			classNameBg:
				currentStep >= lastStepOfFirstProgreesBar
					? "bg-blue-500"
					: "bg-gray-500",
			classNameTxt:
				currentStep >= lastStepOfFirstProgreesBar
					? "text-white"
					: "text-secondary",
		},
		{
			label: "Paiement et Signature",
			value: 3,
			classNameBg:
				currentStep >= numberOfTotalStep ? "bg-blue-500" : "bg-gray-500",
			classNameTxt:
				currentStep >= numberOfTotalStep ? "text-white" : "text-secondary",
		},
	];

	return (
		<div className="flex w-full flex-row items-center justify-around lg:w-4/5">
			{steps.map((step, index) => (
				<CustomStepper
					currentStep={currentStep}
					index={index}
					step={step}
					key={step.value}
				/>
			))}
		</div>
	);
}
