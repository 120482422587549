import { useEffect } from "react";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import usePricingStore from "@/stores/usePricingStore";
import useTurnoverStore from "@/stores/useTurnoverStore";
import { trpc } from "@/utils";

import type {
	BasicGuarenteeResponseDto,
	BasicGuarenteesResponseDto,
} from "@repos/ecom-dtos";

import ItemGuarantee from "./ItemGuarantee";

interface BasicGuaranteeSerivcesProps {
	guarantees: BasicGuarenteesResponseDto["basicGuarantees"];
	onGuaranteeSelect?: (guarantee: BasicGuarenteeResponseDto) => void;
}

export const BasicGuaranteeSerivces = ({
	guarantees,
	onGuaranteeSelect,
}: BasicGuaranteeSerivcesProps) => {
	const { turnover } = useTurnoverStore();
	const { data } = trpc.estimation.getMandatoryGuaranteesPricing.useQuery(
		{ turnoverValue: turnover! || 0 },
		{ enabled: turnover ? true : false },
	);

	const prime = data?.estimatedMonthlyPrimePIT
		? parseFloat(data.estimatedMonthlyPrimePIT.toFixed(2))
		: undefined;

	const { setMonthlyPrime, monthlyPrime } = usePricingStore();

	useEffect(() => {
		if (prime) {
			setMonthlyPrime(prime);
		}
	}, [prime]);

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<>
			<CardHeader className="max-h-full pb-3 pl-8 pr-8 pt-0 lg:pt-8">
				{/* Desktop */}
				<CardTitle className="mb-8 hidden text-md font-bold text-gray-800 lg:block xl:mb-16">
					Assurance E-commerçants
				</CardTitle>
				{/* Mobile */}
				<CardTitle className="!mb-5 text-center text-lg font-bold text-blackMobile lg:hidden">
					Nos garanties de base
				</CardTitle>
				{guarantees.map((item) => (
					<ItemGuarantee
						key={item.type}
						item={item}
						onClickItem={() => onGuaranteeSelect && onGuaranteeSelect(item)}
					/>
				))}
				<div className="hidden w-full items-start justify-end lg:flex">
					<div className="mt-5 flex flex-col items-end">
						<CardDescription className="self-start text-xs 2xl:text-md">
							À partir de
						</CardDescription>
						<div className="flex flex-row items-baseline">
							<CardTitle className="mb-2 text-2xl font-extrabold text-highlight xl:text-4xl">
								{monthlyPrime} €
							</CardTitle>
							<CardDescription className="text-xs">/mois</CardDescription>
						</div>
					</div>
				</div>
			</CardHeader>
		</>
	);
};
